import { IFButton } from 'Components'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import Styles from './AdjustBalanceForm.module.css'
import { Colors } from 'Theme'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '80%',
    marginLeft: '0.2em',
  },
  select: {
    width: '18%',
  },
}))
const AdjustBalanceForm = ({ onSubmit, className }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [sign, setSign] = useState('-')
  const [amount, setAmount] = useState('0.00')
  return (
    <div>
      <form onSubmit={(value) => onSubmit(sign + amount)}>
        <div className={Styles.InputContainer}>
          <Select
            size="small"
            variant="outlined"
            defaultValue="-"
            className={classes.select}
            onChange={(event) => {
              setSign(event.target.value)
            }}
          >
            <option value="-">-</option>
            <option value="+">+</option>
          </Select>
          <TextField
            label={t('UserPage.Amount')}
            variant="outlined"
            onChange={(event) => setAmount(event.target.value)}
            className={classes.textField}
          />
        </div>
        <IFButton
          className={className}
          isFill={true}
          color={Colors.primary}
          text={
            sign === '+'
              ? t('UserPage.AdjustAmountButtonTextAdd')
              : t('UserPage.AdjustAmountButtonTextSubtract')
          }
        />
      </form>
    </div>
  )
}
export default AdjustBalanceForm
