import { Route, Redirect } from 'react-router-dom'
import { LoginPage } from 'Containers'

const LoginRouter = () => (
  <div>
    <Route exact path="/" render={() => <Redirect to="/login" />} />
    <Route path="/login" component={LoginPage} />
  </div>
)

export default LoginRouter
