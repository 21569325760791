import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import Styles from './ChargingTokenListItem.module.css'
import styled from 'styled-components'
import IFText from 'Components/IFText/IFText'
import './React-toggle.css'
import Colors from 'Theme/Colors'
import moment from 'moment'
import PropTypes from 'prop-types'
import InfinityEnums from 'Enums/InfinityEnums'
import IFButton from 'Components/IFButton/IFButton'
import { useTranslation } from 'react-i18next'
import IFDialog from 'Components/IFDialog/IFDialog'
import { useRef } from 'react'
import { Visibility } from '@mui/icons-material'

const formatDate = (dateTimeString) => {
  return moment(dateTimeString).format('MMM DD, YYYY')
}

const Container = styled.div`
  color: ${Colors.ChargingTokenListItemShadow};
  height: 4em;
  padding-left: 1em;
  padding-right: 1em;
  box-shadow: 0px 0px 0px 0px ${Colors.ChargingTokenListItemShadow};
  &:hover ${this} {
    box-shadow: 0px 0px 2px 3px ${Colors.ChargingTokenListItemShadow};
  }
`

const ChargingTokenListItem = ({
  userToken,
  onActiveChange,
  isLoading,
  canEditToken,
  canEditUser,
}) => {
  const { t } = useTranslation()
  const showDialogHandler = useRef()

  return (
    <Container className={Styles.listItem}>
      {userToken.type === InfinityEnums.ChargingTokenType.RFID ? (
        <div className={Styles.iconCard}>
          <FontAwesomeIcon
            icon={faCreditCard}
            style={{ color: Colors.ChargingTokenListItemIcon }}
          />
        </div>
      ) : (
        <div className={Styles.iconPhone}>
          <FontAwesomeIcon
            icon={faMobileAlt}
            style={{
              color: Colors.ChargingTokenListItemIcon,
            }}
          />
        </div>
      )}

      {canEditUser && (
        <IFText
          className={Styles.tokenId}
          style={{ color: Colors.ChargingTokenListItemId }}
        >
          {`#${userToken.uid}`}
        </IFText>
      )}
      <div
        className={Styles.visualNumber}
        style={{ marginLeft: canEditUser ? '1rem' : 0 }}
      >
        {canEditUser && <Visibility style={{ color: Colors.text }} />}
        <IFText
          className={Styles.visualNumberText}
          style={{ color: Colors.ChargingTokenListItemId }}
        >{`${userToken.visualNumber ? userToken.visualNumber : '-'}`}</IFText>
      </div>

      <IFText
        className={Styles.tokenDate}
        style={{ color: Colors.ChargingTokenListItemDate }}
      >
        {formatDate(userToken.updatedAt)}
      </IFText>

      {canEditToken ? (
        <div className={Styles.deactivateWrapper}>
          <div className={Styles.deactivateContainer}>
            {!canEditUser &&
            userToken.status ===
              InfinityEnums.AppChargingTokenStatus.ACTIVE ? null : (
              <IFButton
                text={
                  !canEditUser
                    ? userToken.status ===
                      InfinityEnums.AppChargingTokenStatus.DEACTIVATED
                      ? t('UserPage.Deactivated')
                      : t('ChargingTokenList.Active')
                    : userToken.status ===
                      InfinityEnums.AppChargingTokenStatus.DEACTIVATED
                    ? t('UserPage.Deactivated')
                    : t('UserPage.DeactivateButton')
                }
                isDead={
                  userToken.status ===
                    InfinityEnums.AppChargingTokenStatus.DEACTIVATED ||
                  !canEditUser
                }
                isLoading={isLoading}
                color={Colors.red}
                onClick={() => {
                  showDialogHandler.current.show()
                }}
              />
            )}
          </div>
        </div>
      ) : null}
      <IFDialog
        ref={showDialogHandler}
        open={false}
        title={t('UserPage.DeactivateTitle')}
        bodyText={t('UserPage.DeactivateTokenText')}
        buttonAcceptonClick={onActiveChange}
        buttonCancelColor={Colors.UserPageCancel}
        buttonAcceptColor={Colors.UserPageDeactivateButton}
        buttonAcceptText={t('UserPage.DeactivateConfirm')}
      />
    </Container>
  )
}

ChargingTokenListItem.propTypes = {
  userToken: PropTypes.object,
  onActiveChange: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default ChargingTokenListItem
