const getVouchersList = (state) => {
  return state.main.voucherState.vouchers
}

const getFetchVouchersListRequestState = (state) => {
  return state.main.voucherState.fetchVouchersListRequestState
}

const getPaginationOffset = (state) => {
  return state.main.voucherState.paginationOffset
}

const getVouchersFilter = (state) => {
  return state.main.voucherState.vouchersFilter
}

const getIsAddVoucherLoading = (state) => {
  return state.main.voucherState.isAddVoucherLoading
}

const getUpdateVoucherRequestState = (state) => {
  return state.main.voucherState.updateVoucherRequestState
}

const VoucherSelectors = {
  getVouchersList,
  getFetchVouchersListRequestState,
  getPaginationOffset,
  getVouchersFilter,
  getIsAddVoucherLoading,
  getUpdateVoucherRequestState,
}

export default VoucherSelectors
