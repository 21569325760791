import { INITIAL_STATE } from './initialState'
import { VoucherTypes } from './Actions'
import { createReducer } from 'reduxsauce'
import RequestState from 'Enums/RequestState'

export const fetchVouchersListLoading = (state) => {
  return {
    ...state,
    fetchVouchersListRequestState: RequestState.LOADING,
  }
}

export const fetchVouchersListSuccess = (
  state,
  { offset, vouchers, nextOffset },
) => {
  let result = []
  for (let j = 0; j < vouchers.length; j++) {
    if (!state.vouchers.some((voucher) => voucher.id === vouchers[j].id)) {
      if (!state.vouchers.some((voucher) => voucher.id === vouchers[j].id)) {
        result.push(vouchers[j])
      }
    }

    if (offset === 0)
      return {
        ...state,
        vouchers: [...vouchers],
        paginationOffset: nextOffset,
        fetchVouchersListRequestState: RequestState.SUCCEEDED,
      }
    else {
      return {
        ...state,
        vouchers: [...state.vouchers, ...result],
        paginationOffset: nextOffset,
        fetchVouchersListRequestState: RequestState.SUCCEEDED,
      }
    }
  }
}

export const fetchVouchersListError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchVouchersListRequestState: requestState,
  }
}

export const setVouchersFilter = (state, { newFilter }) => {
  return {
    ...state,
    vouchersFilter: newFilter,
  }
}

export const clearVouchers = (state) => {
  return {
    ...state,
    vouchers: [],
    paginationOffset: 0,
  }
}

export const updateVoucherLoading = (state) => {
  return {
    ...state,
    updateVoucherRequestState: RequestState.LOADING,
  }
}

export const updateVoucherSuccess = (state, { index, newVoucher }) => {
  let newVouchers = state.vouchers
  newVouchers[index] = newVoucher

  return {
    ...state,
    vouchers: [...newVouchers],
    updateVoucherRequestState: RequestState.SUCCEEDED,
  }
}

export const addVoucherSuccess = (state, { voucher }) => {
  return {
    ...state,
    vouchers: [...state.vouchers, voucher],
    isAddVoucherLoading: false,
  }
}

export const addVoucherLoading = (state) => {
  return {
    ...state,
    isAddVoucherLoading: true,
  }
}

export const addVoucherFail = (state) => {
  return {
    ...state,
    isAddVoucherLoading: false,
  }
}

export const updateVoucherFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    updateVoucherRequestState: requestState,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [VoucherTypes.FETCH_VOUCHERS_LIST_LOADING]: fetchVouchersListLoading,
  [VoucherTypes.FETCH_VOUCHERS_LIST_SUCCESS]: fetchVouchersListSuccess,
  [VoucherTypes.FETCH_VOUCHERS_LIST_ERROR]: fetchVouchersListError,
  [VoucherTypes.SET_VOUCHERS_FILTER]: setVouchersFilter,
  [VoucherTypes.CLEAR_VOUCHERS]: clearVouchers,
  [VoucherTypes.ADD_VOUCHER_SUCCESS]: addVoucherSuccess,
  [VoucherTypes.ADD_VOUCHER_LOADING]: addVoucherLoading,
  [VoucherTypes.ADD_VOUCHER_FAIL]: addVoucherFail,
  [VoucherTypes.UPDATE_VOUCHER_FAIL]: updateVoucherFail,
  [VoucherTypes.UPDATE_VOUCHER_SUCCESS]: updateVoucherSuccess,
  [VoucherTypes.UPDATE_VOUCHER_LOADING]: updateVoucherLoading,
})
