import RequestState from 'Enums/RequestState'

/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  fetchAdminListRequestState: RequestState.UNINITIALIZED,
  fetchAdminRolesRequestState: RequestState.UNINITIALIZED,
  fetchUpdateAdminRoleRequestState: RequestState.UNINITIALIZED,
  filterText: '',
  admins: [],
  roles: [],
  isAddAdminLoading: false,
}
