import React from 'react'
import { connect } from 'react-redux'
import UserSelectors from 'Stores/User/Selectors'
import AuthSelectors from 'Stores/Auth/Selectors'
import UserActions from 'Stores/User/Actions'
import { Icons } from 'Theme'
import ChargingTokenListItem from 'Components/ChargingTokenListItem/ChargingTokenListItem'
import { IFText } from 'Components'
import Styles from './ChargingTokenList.module.css'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import InfinityEnums from 'Enums/InfinityEnums'

const ChargingTokenList = ({
  userId,
  currentUserChargingTokens,
  setCurrentUserChargingTokenActive,
  editingAdminRole,
}) => {
  const { t } = useTranslation()
  return (
    <div>
      {currentUserChargingTokens.length === 0 ? (
        <div className={Styles.emptySet}>
          <img src={Icons.emptySet.default} alt="" />
          <IFText>{t('ChargingTokenList.empty')}</IFText>
        </div>
      ) : (
        currentUserChargingTokens.map((token, index) => {
          return (
            <ChargingTokenListItem
              key={`chargingToken ${index}`}
              userToken={token}
              isLoading={token.isLoading}
              onActiveChange={() => {
                setCurrentUserChargingTokenActive(userId, token._id, index)
              }}
              canEditToken={editingAdminRole.includes(
                InfinityEnums.AdminPermissions.CAN_TOGGLE_CARD_ACTIVATION,
              )}
              canEditUser={editingAdminRole.includes(
                InfinityEnums.AdminPermissions.CAN_EDIT_USERS,
              )}
            />
          )
        })
      )}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentUserChargingTokenActive: (userId, chargingTokenId, index) =>
      dispatch(
        UserActions.setCurrentUserChargingTokenActive(
          userId,
          chargingTokenId,
          index,
        ),
      ),
  }
}

const mapStateToProps = (state) => ({
  currentUserChargingTokens: UserSelectors.getCurrentUserChargingTokens(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
})

ChargingTokenList.propTypes = {
  currentUserChargingTokens: PropTypes.arrayOf(PropTypes.object),
  setCurrentUserChargingTokenActive: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChargingTokenList)
