import React from 'react'
import { connect } from 'react-redux'
import TransactionSelectors from 'Stores/Transaction/Selectors'
import TransactionActions from 'Stores/Transaction/Actions'
import { TransactionListItem, IFText, IFLoadingIndicator } from 'Components'
import { Icons } from 'Theme'
import Styles from './TransactionsPageList.module.css'
import { useTranslation } from 'react-i18next'
import { Waypoint } from 'react-waypoint'
import RequestState from 'Enums/RequestState'
import styled from 'styled-components'
import Colors from 'Theme/Colors'

const HorizontalDivider = styled.div`
  border-top: 1px solid ${Colors.DividerColor};
`

const TransactionsPageList = ({
  transactions,
  transactionsFilter,
  paginationOffset,
  fetchTransactionListRequestState,
  fetchTransactionsList,
}) => {
  const { t } = useTranslation()

  const loadMoreData = () => {
    if (fetchTransactionListRequestState === RequestState.LOADING) return
    fetchTransactionsList(transactionsFilter, paginationOffset)
  }

  return (
    <div>
      {transactions.length === 0 ? (
        <div className={Styles.EmptySet}>
          <img src={Icons.emptySet.default} alt="" />
          <IFText>{t('TransactionsPage.NoTransactions')}</IFText>
        </div>
      ) : (
        transactions.map((transaction, index) => {
          return (
            <div>
              {index === 0 ? null : <HorizontalDivider />}
              <TransactionListItem key={index} transaction={transaction} />
            </div>
          )
        })
      )}
      {paginationOffset && transactions && transactions.length !== 0 ? (
        <div className={Styles.WaypointContainer}>
          <Waypoint onEnter={loadMoreData} />
          <IFLoadingIndicator
            style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
            size={'2em'}
            thickness={200}
          />
        </div>
      ) : null}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTransactionsList: (filter, offset) =>
      dispatch(TransactionActions.fetchTransactionsList(filter, offset)),
  }
}

const mapStateToProps = (state) => ({
  transactions: TransactionSelectors.getTransactionsList(state),
  paginationOffset: TransactionSelectors.getPaginationOffset(state),
  fetchTransactionsListRequestState:
    TransactionSelectors.getFetchTransactionsListRequestState(state),
  transactionsFilter: TransactionSelectors.getTransactionsFilter(state),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionsPageList)
