import { put, call } from 'redux-saga/effects'
import { adminsApiService, handleError } from 'Services/ApiService.js'
import AdminActions from 'Stores/Admin/Actions'

export function* fetchAdminList() {
  yield put(AdminActions.fetchAdminListLoading())
  try {
    const { data } = yield call(adminsApiService.get, '/')
    yield put(AdminActions.fetchAdminListSuccess(data))
  } catch (e) {
    yield put(AdminActions.fetchAdminListError(e))
    handleError(e)
  }
}

export function* fetchAdminRoles() {
  yield put(AdminActions.fetchAdminRolesLoading())
  try {
    const { data } = yield call(adminsApiService.get, '/roles')
    yield put(AdminActions.fetchAdminRolesSuccess(data))
  } catch (e) {
    yield put(AdminActions.fetchAdminRolesError(e))
    handleError(e)
  }
}

export function* updateAdminRole({ adminId, newRoleValue, adminItemRef }) {
  yield put(AdminActions.updateAdminRoleLoading(adminId))
  try {
    const { data } = yield call(adminsApiService.patch, `/${adminId}`, {
      newRoleId: newRoleValue,
    })
    yield put(AdminActions.updateAdminRoleSuccess(data))
    adminItemRef.current.collapse()
  } catch (e) {
    yield put(AdminActions.updateAdminRoleFail(adminId))
    handleError(e)
  }
}

export function* deleteAdmin({ adminId }) {
  yield put(AdminActions.deleteAdminLoading(adminId))

  try {
    yield call(adminsApiService.delete, `/${adminId}`)
    yield put(AdminActions.deleteAdminSuccess(adminId))
  } catch (e) {
    yield put(AdminActions.deleteAdminFail(adminId))
    handleError(e)
  }
}

export function* addAdmin({ admin }) {
  yield put(AdminActions.addAdminLoading(true))
  try {
    const { data } = yield call(adminsApiService.post, '/', {
      name: admin.name,
      email: admin.email,
      role: admin.role,
      password: admin.password,
    })

    yield put(AdminActions.addAdminSuccess(data))
  } catch (e) {
    yield put(AdminActions.addAdminFail())
    handleError(e)
  }
}
