import RequestState from 'Enums/RequestState'
import IFFIlterType from 'Enums/IFFilterType'

export const INITIAL_STATE = {
  fetchContactUsFormListRequestState: RequestState.UNINITIALIZED,
  fetchUpdateContactUsFormStatusRequestState: RequestState.UNINITIALIZED,
  paginationOffset: 0,
  contactUsForms: [],
  contactUsFormsFilter: [
    {
      type: IFFIlterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.CHECK,
      title: 'Status',
      data: ['Open', 'Closed'],
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.DATE_RANGE,
      title: 'Date',
      initialValue: [],
      value: [],
    },
  ],
}
