import { put, call } from 'redux-saga/effects'
import {
  notificationGroupsApiService,
  usersApiService,
  handleError,
} from 'Services/ApiService.js'
import NotificationsActions from 'Stores/Notification/Actions'

export function* fetchNotificationGroups({}) {
  yield put(NotificationsActions.fetchNotificationGroupsLoading())
  try {
    const { data } = yield call(notificationGroupsApiService.get, '/', {})
    yield put(NotificationsActions.fetchNotificationGroupsSuccess(data))
  } catch (e) {
    yield put(NotificationsActions.fetchNotificationGroupsError(e))
    handleError(e)
  }
}

export function* sendNotification({ notification }) {
  yield put(NotificationsActions.sendNotificationLoading())
  try {
    const { data } = yield call(usersApiService.post, '/sendNotifications', {
      ...notification,
    })
    yield put(NotificationsActions.sendNotificationSuccess())
  } catch (e) {
    yield put(NotificationsActions.sendNotificationError(e))
    handleError(e)
  }
}
