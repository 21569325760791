import SideNavBar from 'Components/SideNavBar/SideNavBar'
import HeaderBar from 'Components/HeaderBar/HeaderBar'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import Styles from './DefaultRouter.module.css'
import { Redirect, Route, Switch } from 'react-router-dom'
import {
  AdminPage,
  UserPage,
  ContactUsPage,
  PushNotificationsPage,
  TransactionsPage,
  VouchersPage,
} from 'Containers'
import { IFAvatar } from 'Components'
import { connect } from 'react-redux'
import AuthSelectors from 'Stores/Auth/Selectors'
import AuthActions from 'Stores/Auth/Actions'
import { useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'
import InfinityEnums from 'Enums/InfinityEnums'
import { changeRsuiteColors } from 'Theme'

const displayedPages = [
  { endpoint: '/user', role: InfinityEnums.AdminPermissions.CAN_VIEW_USERS },
  {
    endpoint: '/admin',
  },
  {
    endpoint: '/contactUs',
  },
  {
    endpoint: '/pushNotifications',
    role: InfinityEnums.AdminPermissions.CAN_SEND_NOTIFICATIONS,
  },
  {
    endpoint: '/transactions',
    role: InfinityEnums.AdminPermissions.CAN_VIEW_TRANSACTIONS,
  },
  {
    endpoint: '/vouchers',
    role: InfinityEnums.AdminPermissions.CAN_VIEW_VOUCHERS,
  },
]

const PageContainer = styled.div`
  background-color: ${Colors.grey};
`

const DefaultRouter = ({
  avatar,
  logout,
  user,
  fetchEditingAdminRole,
  editingAdminRole,
}) => {
  const history = useHistory()

  useEffect(() => {
    fetchEditingAdminRole()
  }, [])

  useEffect(() => {
    for (let page of displayedPages) {
      if (page.role && editingAdminRole?.some((role) => role === page.role)) {
        history.push({ pathname: page['endpoint'] })
        return
      }
    }
  }, [editingAdminRole])

  const [title, setTitle] = useState(
    window.location.pathname === '/admin'
      ? 'Administration Control Panel'
      : window.location.pathname === '/contactUs'
      ? 'Contact Us'
      : window.location.pathname === '/pushNotifications'
      ? 'Push Notifications Panel'
      : window.location.pathname === '/transactions'
      ? 'Transactions'
      : window.location.pathname === '/vouchers'
      ? 'Vouchers'
      : '',
  )
  history.listen((location) => {
    if (location.pathname === '/admin') {
      setTitle('Administration Control Panel')
    } else if (location.pathname === '/contactUs') {
      setTitle('Contact Us')
    } else if (location.pathname === '/pushNotifications') {
      setTitle('Push Notifications Panel')
    } else if (location.pathname === '/transactions') setTitle('Transactions')
    else if (location.pathname === '/vouchers') setTitle('Vouchers')
    else setTitle('Users Control Panel')
  })
  changeRsuiteColors(Colors.primary)
  return (
    <PageContainer className={Styles.PageContainer}>
      <SideNavBar />
      <div className={Styles.RightColumnContainer}>
        <HeaderBar
          avatar={<IFAvatar name={user.name} size={40} />}
          title={title}
          username={user.name}
          onLogoutClick={() => {
            logout()
          }}
        />
        <div className={Styles.ContentContainer}>
          <Switch>
            {editingAdminRole?.includes(
              InfinityEnums.AdminPermissions.CAN_VIEW_ADMINISTRATION,
            ) && <Route path="/admin" component={AdminPage} />}
            {editingAdminRole?.includes(
              InfinityEnums.AdminPermissions.CAN_VIEW_USERS,
            ) && <Route path="/user" component={UserPage} />}
            {editingAdminRole?.includes(
              InfinityEnums.AdminPermissions.CAN_VIEW_CONTACTUS,
            ) && <Route path="/contactUs" component={ContactUsPage} />}
            {editingAdminRole?.includes(
              InfinityEnums.AdminPermissions.CAN_VIEW_NOTIFICATIONS,
            ) && (
              <Route
                path="/pushNotifications"
                component={PushNotificationsPage}
              />
            )}
            {editingAdminRole?.includes(
              InfinityEnums.AdminPermissions.CAN_VIEW_TRANSACTIONS,
            ) && <Route path="/transactions" component={TransactionsPage} />}
            {editingAdminRole?.includes(
              InfinityEnums.AdminPermissions.CAN_VIEW_VOUCHERS,
            ) && <Route path="/vouchers" component={VouchersPage} />}
          </Switch>
        </div>
      </div>
    </PageContainer>
  )
}

const mapStateToProps = (state) => ({
  user: AuthSelectors.getUser(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
})

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(AuthActions.logout()),
    fetchEditingAdminRole: () => dispatch(AuthActions.fetchEditingAdminRole()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultRouter)
