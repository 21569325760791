import { Colors } from 'Theme'
const changeRsuiteColors = (primaryColor) => {
  document.documentElement.style.setProperty(
    '--rs-checkbox-checked-bg',
    primaryColor,
  )
  document.documentElement.style.setProperty(
    '--rs-checkbox-checked-bg',
    `${primaryColor + Colors.HexTransparent.T50}`,
  )
  document.documentElement.style.setProperty(
    '--rs-listbox-option-hover-bg',
    `${primaryColor + Colors.HexTransparent.T20}`,
  )
  document.documentElement.style.setProperty(
    '--rs-input-focus-border',
    `${primaryColor}`,
  )
  document.documentElement.style.setProperty(
    '--rs-state-focus-shadow',
    `0 0 4px ${primaryColor + Colors.HexTransparent.T25}`,
  )
  document.documentElement.style.setProperty(
    '--rs-btn-link-text',
    `${primaryColor}`,
  )
  document.documentElement.style.setProperty(
    '--rs-listbox-option-hover-text',
    `${primaryColor}`,
  )

  document.documentElement.style.setProperty(
    '--rs-bg-active',
    `${primaryColor}`,
  )
  document.documentElement.style.setProperty(
    '--rs-btn-primary-bg',
    `${primaryColor}`,
  )
  document.documentElement.style.setProperty(
    '--rs-btn-primary-hover-bg',
    `${primaryColor + Colors.HexTransparent.T90}`,
  )
  document.documentElement.style.setProperty(
    '--rs-btn-primary-active-bg',
    `${primaryColor + Colors.HexTransparent.T90}`,
  )
  document.documentElement.style.setProperty(
    '--rs-calendar-range-bg',
    `${primaryColor + Colors.HexTransparent.T50}`,
  )
  document.documentElement.style.setProperty(
    '--rs-picker-count-bg',
    `${primaryColor}`,
  )
  document.documentElement.style.setProperty(
    '--rs-picker-value',
    `${primaryColor}`,
  )
  document.documentElement.style.setProperty(
    '--rs-listbox-option-selected-bg',
    `${primaryColor + Colors.HexTransparent.T10}`,
  )
  document.documentElement.style.setProperty(
    '--rs-toggle-checked-bg',
    `${primaryColor + Colors.HexTransparent.T90}`,
  )
  document.documentElement.style.setProperty(
    '--rs-toggle-checked-hover-bg',
    `${primaryColor}`,
  )
  document.documentElement.style.setProperty(
    '--rs-color-focus-ring',
    `${primaryColor + Colors.HexTransparent.T30}`,
  )
}

export default changeRsuiteColors
