import { IFText } from 'Components'
import Styles from './PaymentSessionListItem.module.css'
import Colors from 'Theme/Colors'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ReactJson from 'react-json-view'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  Container: {
    color: Colors.PaymentSessionListItemShadow,
    width: '100%',
    marginTop: '0.5em',
    borderRadius: '5px',
  },
  Accordion: {
    boxShadow: '0px 0px 0px 0px' + Colors.PaymentSessionListItemShadow,
    '&:hover': {
      boxShadow: '0px 0px 2px 3px' + Colors.PaymentSessionListItemShadow,
    },
    marginBottom: '0.5rem',
  },
  AccordionDetails: {
    alignItems: 'center !important',
  },
  MuiAccordionRoot: {
    '&.MuiAccordion-root:before': {
      display: 'none !important',
    },
  },
}))

const formatDate = (dateTimeString) => {
  return moment(dateTimeString).format('MMM DD, YYYY HH:mm')
}

const PaymentSessionListItem = ({ paymentSession }) => {
  const [expanded, setExpanded] = useState(false)
  const classes = useStyles()
  const handleExpand = () => {
    setExpanded(!expanded)
  }
  const { t } = useTranslation()

  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpand}
      className={`${classes.Accordion} ${classes.MuiAccordionRoot}`}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={Styles.WrapperContainer}
      >
        <div className={Styles.IDContainer}>
          <IFText className={Styles.FieldText} style={{ color: Colors.text }}>
            {paymentSession.id}
          </IFText>
        </div>
        <div className={Styles.FieldContainer}>
          <IFText className={Styles.FieldText} style={{ color: Colors.text }}>
            {t('PaymentSession.Currency')} {paymentSession.amount}
          </IFText>
        </div>
        <div className={Styles.FieldContainer}>
          <IFText className={Styles.FieldText} style={{ color: Colors.text }}>
            {formatDate(paymentSession.createdAt)}
          </IFText>
        </div>
        <div className={Styles.FieldContainer}>
          <IFText className={Styles.FieldText} style={{ color: Colors.text }}>
            {paymentSession.status}
          </IFText>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.AccordionDetails}>
        <div>
          <IFText
            style={{ color: Colors.text }}
            className={Styles.DetailsContainer}
          >
            {t('PaymentSession.Reason')} {paymentSession.reason}
          </IFText>
          <IFText
            style={{ color: Colors.text }}
            className={Styles.DetailsContainer}
          >
            <ReactJson src={paymentSession.details} collapsed={0} />
          </IFText>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

PaymentSessionListItem.propTypes = {
  paymentSession: PropTypes.object,
}

export default PaymentSessionListItem
