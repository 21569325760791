import { put, call } from 'redux-saga/effects'
import { contactUsApiService, handleError } from 'Services/ApiService.js'
import ContactUsFormActions from 'Stores/ContactUsForm/Actions'
var base64 = require('base-64')

export function* fetchContactUsFormList({ filter, offset }) {
  yield put(ContactUsFormActions.fetchContactUsFormListLoading())
  try {
    if (filter != null) {
      filter = base64.encode(JSON.stringify(filter))
    }

    const { data } = yield call(contactUsApiService.get, '/', {
      params: {
        filter: filter,
        offset: offset,
      },
    })
    yield put(
      ContactUsFormActions.fetchContactUsFormListSuccess(
        offset,
        data.contactUsForms,
        data.nextOffset,
      ),
    )
  } catch (e) {
    yield put(ContactUsFormActions.fetchContactUsFormListError(e))
    handleError(e)
  }
}

export function* updateContactUsFormStatus({
  contactUsFormId,
  newStatusValue,
  contactUsItemRef,
}) {
  yield put(ContactUsFormActions.updateContactUsFormStatusLoading())
  try {
    const { data } = yield call(
      contactUsApiService.patch,
      `/${contactUsFormId}`,
      {
        status: newStatusValue,
      },
    )

    yield put(ContactUsFormActions.updateContactUsFormStatusSuccess(data))
    contactUsItemRef.current.collapse()
  } catch (e) {
    yield put(ContactUsFormActions.updateContactUsFormStatusFail(e))
    handleError(e)
  }
}
