import React, { createRef } from 'react'
import { connect } from 'react-redux'
import ContactUsFormSelectors from 'Stores/ContactUsForm/Selectors'
import ContactUsFormActions from 'Stores/ContactUsForm/Actions'
import { ContactUsFormListItem, IFText, IFLoadingIndicator } from 'Components'
import { Icons } from 'Theme'
import Styles from './ContactUsFormList.module.css'
import { useTranslation } from 'react-i18next'
import { Waypoint } from 'react-waypoint'
import RequestState from 'Enums/RequestState'

const ContactUsFormList = ({
  contactUsForms,
  updateContactUsFormStatus,
  contactUsFormsFilter,
  paginationOffset,
  fetchContactUsFormListRequestState,
  fetchUpdateContactUsFormStatusRequestState,
  fetchContactUsFormList,
}) => {
  const { t } = useTranslation()

  const loadMoreData = () => {
    if (fetchContactUsFormListRequestState === RequestState.LOADING) return
    fetchContactUsFormList(contactUsFormsFilter, paginationOffset)
  }

  const contactUsFormItemsRefs = React.useRef([])
  const contactUsFormsArrayLength = contactUsForms.length

  if (contactUsFormItemsRefs.current.length !== contactUsFormsArrayLength) {
    contactUsFormItemsRefs.current = Array(contactUsFormsArrayLength)
      .fill()
      .map((_, i) => contactUsFormItemsRefs.current[i] || createRef())
  }

  return (
    <div>
      {contactUsForms.length === 0 ? (
        <div className={Styles.EmptySet}>
          <img src={Icons.emptySet.default} alt="" />
          <IFText>{t('ContactUsFormList.EmptyList')}</IFText>
        </div>
      ) : (
        contactUsForms.map((contactUsForm, index) => {
          return (
            <ContactUsFormListItem
              ref={contactUsFormItemsRefs.current[index]}
              key={index}
              contactUsForm={contactUsForm}
              onUpdateClick={(contactUsFormId, status) =>
                updateContactUsFormStatus(
                  contactUsFormId,
                  status,
                  contactUsFormItemsRefs.current[index],
                )
              }
              isUpdateLoading={
                fetchUpdateContactUsFormStatusRequestState ===
                RequestState.LOADING
              }
            />
          )
        })
      )}
      {paginationOffset && contactUsForms && contactUsForms.length !== 0 ? (
        <div className={Styles.WaypointContainer}>
          <Waypoint onEnter={loadMoreData} />
          <IFLoadingIndicator
            style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
            size={'2em'}
            thickness={200}
          />
        </div>
      ) : null}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    updateContactUsFormStatus: (
      contactUsFormId,
      newStatusValue,
      contactUsFormItemRef,
    ) =>
      dispatch(
        ContactUsFormActions.updateContactUsFormStatus(
          contactUsFormId,
          newStatusValue,
          contactUsFormItemRef,
        ),
      ),
    fetchContactUsFormList: (filter, offset) =>
      dispatch(ContactUsFormActions.fetchContactUsFormList(filter, offset)),
  }
}

const mapStateToProps = (state) => ({
  contactUsForms: ContactUsFormSelectors.getContactUsFormList(state),
  paginationOffset: ContactUsFormSelectors.getpaginationOffset(state),
  fetchContactUsFormListRequestState:
    ContactUsFormSelectors.getfetchContactUsFormListRequestState(state),
  fetchUpdateContactUsFormStatusRequestState:
    ContactUsFormSelectors.getfetchUpdateContactUsFormStatusRequestState(state),
  contactUsFormsFilter: ContactUsFormSelectors.getContactUsFormsFilter(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsFormList)
