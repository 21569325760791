import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { IFFilter, IFText, IFButton, IFModal } from 'Components'
import { VoucherList, AddVoucherForm } from 'Containers'
import Colors from 'Theme/Colors'
import Styles from './VouchersPage.module.css'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import { Scrollbars } from 'react-custom-scrollbars'
import VoucherSelectors from 'Stores/Voucher/Selectors'
import VoucherActions from 'Stores/Voucher/Actions'

const VouchersPage = ({
  addVoucher,
  setVouchersFilter,
  clearVouchers,
  vouchersFilter,
  fetchVouchersListRequestState,
  fetchVouchers,
  paginationOffset,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    fetchVouchers(vouchersFilter, 0)
  }, [])

  useEffect(() => {
    fetchVouchers(vouchersFilter, 0)
  }, [vouchersFilter])

  const shouldShowLoadingState = () => {
    return fetchVouchersListRequestState === RequestState.LOADING
  }
  const onSubmitFormHandler = (voucher) => {
    addVoucher(voucher)
    showDialogHandler.current.dismiss()
  }
  const showDialogHandler = useRef()

  return (
    <div>
      <div className={Styles.WrapperContainer}>
        <div className={Styles.TopContainer}>
          <div className={Styles.Filter}>
            <IFFilter
              onFilterChange={(newFilter) => {
                setVouchersFilter(newFilter)
                clearVouchers()
              }}
              filters={vouchersFilter}
              textFieldPlaceholder={t('VouchersPage.VouchersFilterPlaceholder')}
            />
          </div>
          <div className={Styles.HeaderButtonContainer}>
            <IFButton
              color={Colors.primary}
              isFill={false}
              isLoading={false}
              text={`+ ${t('VouchersPage.Create')}`}
              className={Styles.CreateButton}
              isDead={shouldShowLoadingState()}
              onClick={() => showDialogHandler.current.show()}
            />
          </div>
        </div>
        <div className={Styles.Container2}>
          <div className={Styles.Container}>
            <div className={Styles.TitleHeadersContainer}>
              <IFText className={Styles.FieldText}>
                {t('VouchersPage.VouchersName')}
              </IFText>
            </div>
            <div className={Styles.TitleHeadersContainer}>
              <IFText className={Styles.FieldText}>
                {t('VouchersPage.VouchersCode')}
              </IFText>
            </div>
            <div className={Styles.TitleHeadersContainer}>
              <IFText className={Styles.FieldText}>
                {t('VouchersPage.VouchersPoints')}
              </IFText>
            </div>
            <div className={Styles.TitleHeadersContainer}>
              <IFText className={Styles.FieldText}>
                {t('VouchersPage.VouchersRedeemed')}
              </IFText>
            </div>
            <div className={Styles.TitleHeadersContainer}>
              <IFText className={Styles.FieldText}>
                {t('VouchersPage.VouchersValidity')}
              </IFText>
            </div>
            <div className={Styles.TitleHeadersContainer}>
              <IFText className={Styles.FieldText}>
                {t('VouchersPage.VouchersExpiry')}
              </IFText>
            </div>
            <div className={Styles.Extra}>
              <IFText className={Styles.FieldText}></IFText>
            </div>
          </div>
          <div className={Styles.VouchersContainer}>
            <Scrollbars
              autoHide
              style={{ width: '100%', height: '32rem', color: 'blue' }}
            >
              <VoucherList />
            </Scrollbars>
          </div>
        </div>
      </div>
      <IFModal
        title={t('VouchersPage.AddNewVoucher')}
        open={false}
        ref={showDialogHandler}
      >
        <AddVoucherForm
          onSubmitForm={(values) => onSubmitFormHandler(values)}
        />
      </IFModal>
    </div>
  )
}
function mapDispatchToProps(dispatch) {
  return {
    setVouchersFilter: (newFilter) =>
      dispatch(VoucherActions.setVouchersFilter(newFilter)),
    clearVouchers: () => dispatch(VoucherActions.clearVouchers()),
    fetchVouchers: (filter, offset) =>
      dispatch(VoucherActions.fetchVouchers(filter, offset)),
    addVoucher: (voucher) => dispatch(VoucherActions.addVoucher(voucher)),
  }
}
const mapStateToProps = (state) => ({
  vouchersFilter: VoucherSelectors.getVouchersFilter(state),
  fetchVouchersListRequestState:
    VoucherSelectors.getFetchVouchersListRequestState(state),
  paginationOffset: VoucherSelectors.getPaginationOffset(state),
})
export default connect(mapStateToProps, mapDispatchToProps)(VouchersPage)
