import PropTypes from 'prop-types'
import Styles from './IFToastMessage.module.css'
import { Message } from 'rsuite'
import { Cancel } from '@mui/icons-material'
import { IFText } from 'Components'
import { Colors } from 'Theme'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import InfoIcon from '@mui/icons-material/Info'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'

const IFToastMessage = ({ duration = 5000, type = 'info', text, ...props }) => {
  return (
    <Message
      {...props}
      style={{
        backgroundColor:
          type === 'info'
            ? Colors.ToastInfo
            : type === 'error'
            ? Colors.ToastError
            : type === 'success'
            ? Colors.ToastSuccess
            : type === 'warning'
            ? Colors.ToastWarning
            : Colors.ToastInfo,
      }}
      className={Styles.rsicon}
      duration={duration}
      type={type}
    >
      <div className={Styles.container}>
        {type === 'error' && (
          <Cancel className={Styles.icon} style={{ color: Colors.white }} />
        )}
        {type === 'success' && (
          <CheckCircleOutlineIcon
            className={Styles.icon}
            style={{ color: Colors.white }}
          />
        )}
        {type === 'info' && (
          <InfoIcon className={Styles.icon} style={{ color: Colors.white }} />
        )}
        {type === 'warning' && (
          <PriorityHighIcon
            className={Styles.icon}
            style={{ color: Colors.white }}
          />
        )}
        <IFText className={Styles.text} style={{ color: Colors.white }}>
          {text}
        </IFText>
      </div>
    </Message>
  )
}

IFToastMessage.propTypes = {
  duration: PropTypes.number,
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
}

export default IFToastMessage
