/**
 * Images should be stored in the `App/Images` directory and referenced using variables defined here.
 */

const images = {
  logo: require('Assets/Images/logo.png'),
  iphone: require('Assets/Images/iphone.png'),
  verticalLogo: require('Assets/Images/newLogo.png'),
}

export default images
