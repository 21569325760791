import { React, useEffect } from 'react'
import { connect } from 'react-redux'
import UserSelectors from 'Stores/User/Selectors'
import UserActions from 'Stores/User/Actions'
import styles from './PointsList.module.css'
import RequestState from 'Enums/RequestState'
import { Waypoint } from 'react-waypoint'
import { IFLoadingIndicator, IFText, PointsListItem } from 'Components'
import { useTranslation } from 'react-i18next'

const PointsList = ({
  userId,
  pointsListPaginationOffset,
  points,
  fetchUserPoints,
  fetchUsersPointsRequestState,
  clearPoints,
}) => {
  const loadMoreData = () => {
    if (fetchUsersPointsRequestState === RequestState.LOADING) return

    fetchUserPoints(userId, pointsListPaginationOffset)
  }
  useEffect(() => {
    fetchUserPoints(userId, pointsListPaginationOffset)
    return () => {
      clearPoints()
    }
  }, [])

  const { t } = useTranslation()

  return (
    <div className={styles.Container}>
      {points.length === 0 ? (
        <div className={styles.NoPointsFoundContainer}>
          <IFText>{t('PointsList.NoPointsText')}</IFText>
        </div>
      ) : (
        points.map((point, index) => {
          return (
            <div key={index} className={styles.PointsItem}>
              <PointsListItem
                name={point.name}
                expiryDateText={point.expiry}
                amountText={point.points}
                remainingText={point.remaining}
                dateCreated={point.createdAt}
              />
            </div>
          )
        })
      )}
      {pointsListPaginationOffset && points && points.length !== 0 ? (
        <div className={styles.WaypointContainer}>
          <Waypoint onEnter={loadMoreData} />
          <IFLoadingIndicator
            style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
            size={'2em'}
            thickness={200}
          />
        </div>
      ) : null}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUserPoints: (userId, offset) =>
      dispatch(UserActions.fetchUserPoints(userId, offset)),
    clearPoints: () => dispatch(UserActions.clearPoints()),
  }
}

const mapStateToProps = (state) => ({
  points: UserSelectors.getPointsList(state),
  pointsListPaginationOffset:
    UserSelectors.getPointsListPaginationOffset(state),
  fetchUsersPointsRequestState:
    UserSelectors.getFetchUserPointsRequestState(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(PointsList)