import PropTypes from 'prop-types'
import { Colors } from 'Theme'

const DoneBtn = ({
    className,
    height = 24,
    width = 24,
    fill = Colors.primary,
    onClick,
  }) => {
    return (
       
      <div className={className} style={{ height: height, width: width }}>
         <svg 
            xmlns="http://www.w3.org/2000/svg" 
            height= '100%'
            viewBox="0 -960 960 960" 
            width='100%'
            fill={fill}
            onClick={onClick}>
            <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/>
            </svg>
      </div>
    )
  }

  const CloseBtn = ({
    className,
    height = 24,
    width = 24,
    fill = Colors.red,
    onClick,
  }) => {
    return (
       
      <div className={className} style={{ height: height, width: width }}>
            <svg xmlns="http://www.w3.org/2000/svg" 
            height="24" 
            viewBox="0 -960 960 960" 
            width="24"
            fill={fill}
            onClick={onClick}>
            <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
            </svg>
      </div>
    )
  }

  const componentPropTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    fill: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
  }

  const components = [
    DoneBtn,
    CloseBtn,
  ]
  
  components.forEach((component) => {
    component.propTypes = componentPropTypes
  })

  export default {
    DoneBtn,
    CloseBtn,
  }