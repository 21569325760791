const InfinityEnums = {
  Locale: {
    EN: 'en',
    AR: 'ar',
  },
  AdminPermissions: {
    CAN_REFUND: 1,
    CAN_TOGGLE_USER_ACTIVATION: 2,
    CAN_ADJUST_BALANCE: 3,
    CAN_ADD_CHARGING_TOKEN: 4,
    CAN_TOGGLE_CARD_ACTIVATION: 5,
    CAN_EDIT_ADMINS: 6,
    CAN_EDIT_CARD_REQUEST_STATUS: 7,
    CAN_EDIT_CONTACT_US_FORM: 8,
    CAN_SEND_NOTIFICATIONS: 9,
    CAN_VIEW_TRANSACTIONS: 10,
    CAN_VIEW_USERS: 11,
    CAN_EDIT_CHARGING_TOKEN: 12,
    CAN_ADD_POINTS: 13,
    CAN_GENERATE_CODE: 14,
    CAN_VIEW_ADMINISTRATION: 15,
    CAN_VIEW_CONTACTUS: 16,
    CAN_VIEW_NOTIFICATIONS: 17,
    CAN_EDIT_USERS: 18,
    CAN_REVERSE_TRANSACTION: 19,
    CAN_VIEW_VOUCHERS: 20,
  },
  ChargingTokenType: {
    RFID: 'Rfid',
    VIRTUAL: 'Virtual',
  },

  AppChargingTokenStatus: {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    DEACTIVATED: 'Deactivated',
  },
  ChargingLevel: {
    LEVEL1: 'Level 1',
    LEVEL2: 'Level 2',
    LEVEL3: 'Level 3',
  },
  TransactionStatus: {
    REVERSED: 'Reversed',
    CORRECTED: 'Corrected',
    NORMAL: 'Normal',
    VOID: 'Void',
  },
  TransactionReverseType: {
    REVERSE: 'Reverse',
    CORRECT: 'Correct',
  },
}

export default InfinityEnums
