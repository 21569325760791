const getUserList = (state) => {
  return state.main.userState.users
}

const getFilteredUserList = (state) => {
  //TODO Apply filter here then return the result
  return state.main.userState.users
}

const getUserListOffset = (state) => {
  return state.main.userState.paginationOffset
}

const getFetchUserListRequestState = (state) => {
  return state.main.userState.fetchUsersListRequestState
}

const getCurrentUser = (state) => {
  return state.main.userState.currentUser
}

const getUserDetailsRequestState = (state) => {
  return state.main.userState.fetchUserDetailsRequestState
}

const getCurrentUserChargingTokens = (state) => {
  return state.main.userState.currentUser.chargingTokens
}

const getUserSortingAsc = (state) => {
  return state.main.userState.userSortingAsc
}

const getUserListLength = (state) => {
  return state.main.userState.users.length
}

const getSelectedUserIndex = (state) => {
  return state.main.userState.selectedUserIndex
}

const getTransactionList = (state) => {
  return state.main.userState.currentUser.transactions
}

const getUsersFilters = (state) => {
  return state.main.userState.usersFilters
}

const getTransactionsFilters = (state) => {
  return state.main.userState.transactionsFilters
}

const getTransactionListPaginationOffset = (state) => {
  return state.main.userState.transactionListPaginationOffset
}

const getFetchUserTransactionListRequestState = (state) => {
  return state.main.userState.fetchUserTransactionListRequestState
}

const getPaymentSessionList = (state) => {
  return state.main.userState.currentUser.paymentSessions
}

const getFetchUserPaymentSessionListRequestState = (state) => {
  return state.main.userState.fetchUserPaymentSessionListRequestState
}

const getPaymentSessionListPaginationOffset = (state) => {
  return state.main.userState.paymentSessionListPaginationOffset
}

const getSelectedUserId = (state) => {
  return state.main.userState.users[state.main.userState.selectedUserIndex]
    ? state.main.userState.users[state.main.userState.selectedUserIndex].id
    : null
}

const getReverseChargeTransactionRequestState = (state) => {
  return state.main.userState.reverseChargeTransactionRequestState
}

const getPointsList = (state) => {
  return state.main.userState.points
}

const getPointsListPaginationOffset = (state) => {
  return state.main.userState.pointsListPaginationOffset
}

const getFetchUserPointsRequestState = (state) => {
  return state.main.userState.fetchUserPointsListRequestState
}

const UserSelectors = {
  getUserList,
  getFilteredUserList,
  getUserListOffset,
  getFetchUserListRequestState,
  getCurrentUser,
  getCurrentUserChargingTokens,
  getUserSortingAsc,
  getUserListLength,
  getSelectedUserIndex,
  getTransactionList,
  getUsersFilters,
  getTransactionsFilters,
  getPaymentSessionList,
  getFetchUserPaymentSessionListRequestState,
  getPaymentSessionListPaginationOffset,
  getUserDetailsRequestState,
  getTransactionListPaginationOffset,
  getFetchUserTransactionListRequestState,
  getSelectedUserId,
  getReverseChargeTransactionRequestState,
  getPointsList,
  getPointsListPaginationOffset,
  getFetchUserPointsRequestState,
}

export default UserSelectors
