import { IFText, IFButton } from 'Components'
import styled from 'styled-components'
import Styles from './ContactUsFormListItem.module.css'
import Colors from 'Theme/Colors'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import React, { useState, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

const DetailsLeftContainer = styled.div`
  background-color: ${Colors.GreyBackground};
`

const ContactUsFormListItem = React.forwardRef(
  ({ contactUsForm, onUpdateClick, isUpdateLoading }, ref) => {
    const StatusContainer = styled.div`
      background-color: ${Colors.GreyBackground};
      color: ${contactUsForm.status === 'Open' ? Colors.green : Colors.red};
    `
    const { t } = useTranslation()
    const [accordExpanded, setAccordExpanded] = useState(false)

    const collapse = () => {
      setAccordExpanded(false)
    }

    useImperativeHandle(ref, () => ({
      collapse,
    }))

    const onClickAccordionHandler = () => {
      setAccordExpanded((expanded) => {
        return !expanded
      })
    }

    const useStyles = makeStyles({
      accordion: {
        boxShadow: '0px 0px 0px 0px' + Colors.ChargingTransactionListItemShadow,
        '&:hover': {
          boxShadow:
            '0px 0px 2px 3px' + Colors.ChargingTransactionListItemShadow,
        },
      },
    })
    const Classes = useStyles()
    return (
      <div className={Styles.OutsideContainer}>
        <Accordion
          onClick={onClickAccordionHandler}
          expanded={accordExpanded}
          className={Classes.accordion}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={Styles.SummaryLeftContainer}>
              <div className={Styles.NameContainer}>
                <IFText>{contactUsForm.name}</IFText>
              </div>
              <div className={Styles.emailContainer}>
                <IFText>{contactUsForm.email}</IFText>
              </div>
              <div>
                <IFText>{contactUsForm.phone}</IFText>
              </div>
            </div>
            <div className={Styles.SummaryRightContainer}>
              <StatusContainer className={Styles.StatusContainer}>
                <IFText>{contactUsForm.status}</IFText>
              </StatusContainer>
            </div>
          </AccordionSummary>
          <AccordionDetails
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <div className={Styles.DetailsContainer}>
              <DetailsLeftContainer className={Styles.DetailsLeftContainer}>
                <IFText>{contactUsForm.message}</IFText>
              </DetailsLeftContainer>
              <div className={Styles.DetailsRightContainer}>
                <IFButton
                  color={Colors.primary}
                  text={
                    contactUsForm.status === 'Open'
                      ? t('ContactUsFormListItem.CloseButton')
                      : t('ContactUsFormListItem.ReOpen')
                  }
                  isLoading={isUpdateLoading}
                  isFill={true}
                  className={Styles.StatusButton}
                  onClick={() =>
                    onUpdateClick(
                      contactUsForm.id,
                      contactUsForm.status === 'Open' ? 'Closed' : 'Open',
                    )
                  }
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    )
  },
)

export default ContactUsFormListItem
