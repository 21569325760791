import PropTypes from 'prop-types'
import React, { useState, useImperativeHandle } from 'react'
import { Colors } from 'Theme'
import styles from './IFDateRangePicker.module.css'
import { IFText } from 'Components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateRangePicker } from 'rsuite'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

const IFDateRangePicker = React.forwardRef(
  ({ title, onDateRangeSelected = () => {}, initialValue }, ref) => {
    const [value, setValue] = useState(initialValue)
    const [isOpen, setIsOpen] = useState(false)
    const clearValue = () => {
      setValue([])
    }

    useImperativeHandle(ref, () => ({
      clearValue,
      isOpen,
    }))

    return (
      <div className={styles.container}>
        <DateRangePicker
          onOpen={() => setIsOpen(true)}
          format="yyyy-MM-dd HH:mm:ss"
          onClose={() => setIsOpen(false)}
          value={value}
          onOk={(value) => {
            setValue(value)
            onDateRangeSelected(value)
          }}
          cleanable={false}
          size="lg"
          placeholder={title}
          renderValue={() => (
            <div>
              <IFText style={{ color: 'white' }}>{title}</IFText>
            </div>
          )}
        />

        <div
          className={styles.TextWrapper}
          style={{
            backgroundColor: Colors.filterBackground,
          }}
        >
          <IFText className={styles.Text}>
            {/* {Replacing all spaces with &nbsp; for styling} */}
            {title.replaceAll(' ', '\u00a0')}
          </IFText>
          <FontAwesomeIcon className={styles.Icon} icon={faAngleDown} />
        </div>
      </div>
    )
  },
)

IFDateRangePicker.propTypes = {
  title: PropTypes.string.isRequired,
  onDateRangeSelected: PropTypes.func.isRequired,
  initialValue: PropTypes.array,
}

export default IFDateRangePicker
