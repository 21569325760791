import RequestState from 'Enums/RequestState'
import IFFIlterType from 'Enums/IFFilterType'

export const INITIAL_STATE = {
  paginationOffset: 0,
  transactions: [],
  statistics: {},
  fetchTransactionsListRequestState: RequestState.UNINITIALIZED,
  transactionsFilter: [
    {
      type: IFFIlterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.DATE_RANGE,
      title: 'Date',
      initialValue: [],
      value: [],
    },
  ],
}
