import RequestState from 'Enums/RequestState'

export const INITIAL_STATE = {
  loginLoading: false,
  token: null,
  refreshToken: null,
  user: null,
  editingAdminRole: [],
  fetchEditingAdminRoleRequestState: RequestState.UNINITIALIZED,
}
