import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  fetchNotificationGroups: null,
  fetchNotificationGroupsLoading: null,
  fetchNotificationGroupsSuccess: ['notificationGroups'],
  fetchNotificationGroupsError: ['error'],

  sendNotification: ['notification'],
  sendNotificationLoading: null,
  sendNotificationSuccess: null,
  sendNotificationError: ['error'],
})

export const NotificationTypes = Types
export default Creators
