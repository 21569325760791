const getAdminList = (state) => {
  return state.main.adminState.admins
}

const getFilteredAdminList = (state) => {
  let result = []
  if (state.main.adminState.admins) {
    for (let i = 0; i < state.main.adminState.admins.length; i++) {
      if (
        state.main.adminState.admins[i].name
          .toLowerCase()
          .includes(state.main.adminState.filterText.toLowerCase())
      ) {
        result.push(state.main.adminState.admins[i])
      }
    }
  }
  return result
}

const getFilterText = (state) => {
  return state.main.adminState.filterText
}

const getfetchAdminListRequestState = (state) => {
  return state.main.adminState.fetchAdminListRequestState
}

const getfetchAdminRolesRequestState = (state) => {
  return state.main.adminState.fetchAdminRolesRequestState
}

const getfetchUpdateAdminRoleRequestState = (state) => {
  return state.main.adminState.fetchUpdateAdminRoleRequestState
}

const getAdminRoles = (state) => {
  return state.main.adminState.roles
}

const getIsAddAdminLoading = (state) => {
  return state.main.adminState.isAddAdminLoading
}

const AdminSelectors = {
  getFilterText,
  getAdminList,
  getFilteredAdminList,
  getfetchAdminListRequestState,
  getAdminRoles,
  getIsAddAdminLoading,
  getfetchAdminRolesRequestState,
  getfetchUpdateAdminRoleRequestState,
}

export default AdminSelectors
