import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import Styles from './FundTransactionListItem.module.css'
import styled from 'styled-components'
import { IFButton, IFText } from 'Components'
import Colors from 'Theme/Colors'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import IFDialog from 'Components/IFDialog/IFDialog'
import { useRef } from 'react'

const formatDate = (dateTimeString) => {
  return moment(dateTimeString).format('MMM DD, YYYY HH:MM')
}

const Container = styled.div`
  width: auto;
  height: auto;
  padding: 1em;
  color: ${Colors.FundTransactionListItemShadow};
  box-shadow: 0px 0px 0px 0px ${Colors.FundTransactionListItemShadow};
  &:hover ${this} {
    box-shadow: 0px 0px 2px 3px ${Colors.FundTransactionListItemShadow};
  }
`

const FundTransactionListItem = ({
  fundTransaction,
  onRefundClick,
  isRefundDisabled,
  canViewRefund,
}) => {
  const { t } = useTranslation()
  const showDialogHandler = useRef()
  const sign = fundTransaction.isCredit
    ? t('FundTransactionListItem.positiveCredit')
    : t('FundTransactionListItem.negativeCredit')
  const refundText = fundTransaction.isRefunded
    ? t('FundTransactionListItem.refunded')
    : t('FundTransactionListItem.notRefunded')
  return (
    <Container className={Styles.listItem}>
      <div className={Styles.MarginContainer}>
        <div className={Styles.iconCard}>
          <FontAwesomeIcon
            icon={faCreditCard}
            style={{ color: Colors.FundTransactionListItemIcon }}
          />
        </div>

        <IFText
          className={Styles.transactionId}
          style={{ color: Colors.FundTransactionListItemId }}
        >
          {fundTransaction.details && fundTransaction.details.adminId
            ? t('FundTransactionListItem.AdminTransaction')
            : fundTransaction.details.orderId
            ? `#${fundTransaction.details.orderId}`
            : `#${fundTransaction.details.transactionId}`}
        </IFText>
        <IFText
          className={Styles.transactionAmount}
          style={{ color: Colors.FundTransactionListItemAmount }}
        >
          {`${sign} ${fundTransaction.amount.toFixed(2)}`}
        </IFText>
        <IFText
          className={Styles.transactionDate}
          style={{ color: Colors.FundTransactionListItemDate }}
        >
          {formatDate(fundTransaction.createdAt)}
        </IFText>

        {fundTransaction.type === 'refund' ? (
          <div className={Styles.refundButtonEmptyContainer} />
        ) : (
          <div className={Styles.refundButtonWrapper}>
            {canViewRefund && (
              <IFButton
                color={Colors.red}
                isFill={false}
                isDead={isRefundDisabled}
                isLoading={fundTransaction.isLoading}
                text={refundText}
                onClick={() => {
                  showDialogHandler?.current?.show()
                }}
                className={Styles.refundButton}
              />
            )}
          </div>
        )}
      </div>
      <IFDialog
        ref={showDialogHandler}
        open={false}
        title={t('ChargingTransactionListItem.confirmRefundTitle')}
        bodyText={t('ChargingTransactionListItem.confirmRefund')}
        buttonAcceptonClick={onRefundClick}
        buttonCancelColor={Colors.UserPageCancel}
        buttonAcceptColor={Colors.UserPageDeactivateButton}
        buttonAcceptText={t('ChargingTransactionListItem.refundButton')}
      />
    </Container>
  )
}

FundTransactionListItem.propTypes = {
  fundsTransaction: PropTypes.object,
  onRefundClick: PropTypes.func,
}

export default FundTransactionListItem
