import Styles from './AdminPage.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import IFButton from 'Components/IFButton/IFButton'
import IFText from 'Components/IFText/IFText'
import AddAdminForm from 'Containers/AdminPage/AddAdminForm'
import { useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import AdminList from '../AdminList/AdminList'
import AdminActions from 'Stores/Admin/Actions'
import AdminSelectors from 'Stores/Admin/Selectors'
import { connect } from 'react-redux'
import IFModal from 'Components/IFModal/IFModal'
import RequestState from 'Enums/RequestState'
import { IFLoadingLogo } from 'Components'
import { Scrollbars } from 'react-custom-scrollbars'

const AdminsContainer = styled.div`
  background-color: ${Colors.white};
`

const AdminPage = ({
  addAdmin,
  fetchAdminList,
  fetchAdminRoles,
  fetchAdminListRequestState,
  fetchAdminRolesRequestState,
}) => {
  useEffect(() => {
    fetchAdminList()
    fetchAdminRoles()
  }, [fetchAdminList, fetchAdminRoles])

  const onSubmitFormHandler = (admin) => {
    addAdmin(admin)
    showDialogHandler.current.dismiss()
  }
  const showDialogHandler = useRef()

  const shouldShowLoadingState = () => {
    return (
      fetchAdminRolesRequestState === RequestState.LOADING ||
      fetchAdminListRequestState === RequestState.LOADING
    )
  }

  const { t } = useTranslation()
  return (
    <div className={Styles.Wrapper}>
      <AdminsContainer className={Styles.AdminsContainer}>
        <div className={Styles.HeaderContainer}>
          <div className={Styles.AdminTextContainer}>
            <IFText className={Styles.HeaderTitles}>
              {t('AdminPageContainer.Admins')}
            </IFText>
          </div>
          <div className={Styles.RoleTextContainer}>
            <IFText className={Styles.HeaderTitles}>
              {t('AdminPageContainer.Roles')}
            </IFText>
          </div>
          <div className={Styles.HeaderButtonContainer}>
            <IFButton
              color={Colors.primary}
              isFill={false}
              isDead={shouldShowLoadingState()}
              isLoading={false}
              text={`+ ${t('AdminPageContainer.Add')}`}
              onClick={() => showDialogHandler.current.show()}
              className={Styles.AddButton}
            />
          </div>
        </div>
        <Scrollbars autoHide style={{ width: '100%', height: '100%' }}>
          <div className={Styles.AdminList}>
            {shouldShowLoadingState() ? (
              <div className={Styles.LoadingContainer}>
                <IFLoadingLogo
                  style={{
                    width: '10em',
                    display: 'flex',
                    marginBottom: '10em',
                  }}
                />
              </div>
            ) : (
              <AdminList />
            )}
          </div>
        </Scrollbars>
        <IFModal
          title={t('AdminPageContainer.AddNewAdmin')}
          open={false}
          ref={showDialogHandler}
        >
          <AddAdminForm onSubmitForm={onSubmitFormHandler} />
        </IFModal>
      </AdminsContainer>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    addAdmin: (admin) => dispatch(AdminActions.addAdmin(admin)),
    fetchAdminList: () => dispatch(AdminActions.fetchAdminList()),
    fetchAdminRoles: () => dispatch(AdminActions.fetchAdminRoles()),
  }
}

const mapStateToProps = (state) => ({
  fetchAdminListRequestState: AdminSelectors.getfetchAdminListRequestState(
    state,
  ),
  fetchAdminRolesRequestState: AdminSelectors.getfetchAdminRolesRequestState(
    state,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage)
