import DefaultRouter from 'Navigators/DefaultRouter/DefaultRouter'
import LoginRouter from 'Navigators/LoginRouter/LoginRouter'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import AuthSelectors from 'Stores/Auth/Selectors'
import AuthActions from 'Stores/Auth/Actions'
import { useEffect } from 'react'

function AppBrowserRouter({ isLoggedIn }) {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/login"
          render={(props) => (isLoggedIn ? <DefaultRouter /> : <LoginRouter />)}
        />
        <Route
          path="/"
          render={(props) =>
            isLoggedIn ? <DefaultRouter /> : <Redirect to="/login" />
          }
        />
      </Switch>
    </BrowserRouter>
  )
}

const mapStateToProps = (state) => ({
  isLoggedIn: AuthSelectors.isLoggedIn(state),
})

export default connect(mapStateToProps)(AppBrowserRouter)
