import Styles from './AddAdminForm.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import { useTranslation } from 'react-i18next'
import IFButton from 'Components/IFButton/IFButton'
import { TextField, FormGroup, MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { object, string } from 'yup'
import IFLoadingIndicator from 'Components/IFLoadingIndicator/IFLoadingIndicator'
import AdminSelectors from 'Stores/Admin/Selectors'
import { connect } from 'react-redux'

const initialValues = {
  name: '',
  email: '',
  role: '',
  password: '',
  confirmPassword: '',
}

const FormContainer = styled.div`
  background-color: ${Colors.white};
`
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: Colors.text,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: Colors.primary,
      },
    },
  },
})(TextField)

const ErrorContainer = styled.div`
  color: ${Colors.red};
`

const AddAdminForm = ({ roles, onSubmitForm, isAddAdminLoading }) => {
  const { t } = useTranslation()
  return (
    <FormContainer className={Styles.FormWrappingDiv}>
      <div className={Styles.FieldsWrapper}>
        <Formik
          validationSchema={object({
            name: string().required(t('AdminPageContainer.Required')),
            email: string()
              .email(t('AdminPageContainer.ValidEmail'))
              .required(t('AdminPageContainer.Required')),
            role: string().required(t('AdminPageContainer.Required')),
            password: string()
              .required(t('AdminPageContainer.Required'))
              .min(8, t('AdminPageContainer.ShortPassword'))
              .test(
                'passwords-match',
                t('AdminPageContainer.MismatchedPasswords'),
                function (value) {
                  return this.parent.confirmPassword === value
                },
              ),
            confirmPassword: string()
              .required(t('AdminPageContainer.Required'))
              .min(8, t('AdminPageContainer.ShortPassword'))
              .test(
                'passwords-match',
                t('AdminPageContainer.MismatchedPasswords'),
                function (value) {
                  return this.parent.password === value
                },
              ),
          })}
          initialValues={initialValues}
          onSubmit={(values) => onSubmitForm(values)}
        >
          {({ values, errors }) => (
            <Form>
              <FormGroup className={Styles.InputWrappingDiv}>
                <Field
                  className={Styles.Input}
                  size="small"
                  name="name"
                  as={CssTextField}
                  variant="outlined"
                  label={t('AdminPageContainer.Name')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="name" />
                </ErrorContainer>
              </FormGroup>

              <FormGroup className={Styles.InputWrappingDiv}>
                <Field
                  as={CssTextField}
                  className={Styles.Input}
                  name="email"
                  type="email"
                  size="small"
                  variant="outlined"
                  label={t('AdminPageContainer.Email')}
                  InputLabelProps={{ shrink: true }}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="email" />
                </ErrorContainer>
              </FormGroup>

              <FormGroup className={Styles.InputWrappingDiv}>
                <Field
                  as={CssTextField}
                  className={Styles.Input}
                  name="password"
                  type="password"
                  size="small"
                  variant="outlined"
                  label={t('AdminPageContainer.Password')}
                  InputLabelProps={{ shrink: true }}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="password" />
                </ErrorContainer>
              </FormGroup>

              <FormGroup className={Styles.InputWrappingDiv}>
                <Field
                  as={CssTextField}
                  className={Styles.Input}
                  name="confirmPassword"
                  type="password"
                  size="small"
                  variant="outlined"
                  label={t('AdminPageContainer.ConfirmPassword')}
                  InputLabelProps={{ shrink: true }}
                />
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="confirmPassword" />
                </ErrorContainer>
              </FormGroup>

              <FormGroup className={Styles.InputWrappingDiv}>
                <Field
                  name="role"
                  variant="outlined"
                  size="small"
                  as={CssTextField}
                  InputLabelProps={{ shrink: true }}
                  select
                  label={t('AdminPageContainer.Role')}
                >
                  {roles.map((role) => (
                    <MenuItem key={`Role${role.id}`} value={`${role.id}`}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Field>
                <ErrorContainer className={Styles.ErrorStyling}>
                  <ErrorMessage name="role" />
                </ErrorContainer>
              </FormGroup>

              <IFButton
                className={Styles.ButtonWrappingDiv}
                type="submit"
                color={Colors.primary}
                isDead={
                  !(
                    values.name.length > 0 &&
                    values.email.length > 0 &&
                    values.role.length > 0 &&
                    values.password.length > 0 &&
                    values.confirmPassword.length > 0
                  ) ||
                  (errors.name && errors.name.length > 0) ||
                  (errors.email && errors.email.length > 0) ||
                  (errors.role && errors.role.length > 0) ||
                  (errors.password && errors.password.length > 0) ||
                  (errors.confirmPassword && errors.confirmPassword.length > 0)
                }
                isFill={true}
                isLoading={false}
                text={
                  isAddAdminLoading ? (
                    <div className={Styles.LoadingIndicator}>
                      <IFLoadingIndicator size={'2em'} />
                    </div>
                  ) : (
                    t('AdminPageContainer.Submit')
                  )
                }
              />
            </Form>
          )}
        </Formik>
      </div>
    </FormContainer>
  )
}

const mapStateToProps = (state) => ({
  isAddAdminLoading: AdminSelectors.getIsAddAdminLoading(state),
  roles: AdminSelectors.getAdminRoles(state),
})

export default connect(mapStateToProps)(AddAdminForm)
