import React, { useRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import UserSelectors from 'Stores/User/Selectors'
import AuthSelectors from 'Stores/Auth/Selectors'
import UserActions from 'Stores/User/Actions'
import TextField from '@mui/material/TextField'
import {
  IFFilter,
  IFButton,
  IFText,
  IFAvatar,
  IFDialog,
  IFLoadingLogo,
  IFModal,
} from 'Components'
import {
  UserList,
  UserListHeader,
  ChargingTokenList,
  TransactionList,
  PaymentSessionList,
  UserPageTabs,
  PointsList,
} from 'Containers'
import Colors from 'Theme/Colors'
import Styles from './UserPage.module.css'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import DateRangeIcon from '@material-ui/icons/DateRange'
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import EditIcon from '@material-ui/icons/Edit'
import InfoIcon from '@mui/icons-material/Info'
import {
  faCreditCard,
  faWallet,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import AdjustBalanceForm from './AdjustBalanceForm/AdjustBalanceForm'
import AddCardForm from './AddCardForm/AddCardForm'
import RequestState from 'Enums/RequestState'
import { Scrollbars } from 'react-custom-scrollbars'
import InfinityEnums from 'Enums/InfinityEnums'

const UserPage = ({
  admin,
  editingAdminRole,
  user,
  transactionsFilter,
  usersFilter,
  adjustWallet,
  toggleUserIsActive,
  addCard,
  userDetailsRequestState,
  fetchUserList,
  fetchUserTransactionList,
  clearUsers,
  setUsersFilter,
  fetchUserListRequestState,
  fetchUserDetails,
  clearTransactions,
  setUserTransactionsFilter,
  clearUserTransactionsFilter,
  selectedUserId,
  clearCurrentUser,
  setCurrentUserNewPhone,
  fetchUserPaymentSessionList,
  clearPaymentSessions,
}) => {
  const userPageTabsRef = useRef()
  const [selectedDetails, setSelectedDetails] = useState(0)

  useEffect(() => {
    if (user.changePhoneRequestState == RequestState.SUCCEEDED)
      showDialogHandlerPhone.current.dismiss()
  }, [user.changePhoneRequestState])

  useEffect(() => {
    if (selectedUserId) {
      clearUserTransactionsFilter()
      setActionState(0)
      fetchUserDetails(selectedUserId)
    }

    if (userPageTabsRef.current) {
      userPageTabsRef.current.resetIndex()
      setSelectedDetails(0)
    }
  }, [selectedUserId])

  useEffect(() => {
    if (selectedUserId) {
      fetchUserTransactionList(selectedUserId, transactionsFilter, 0)
    }
  }, [transactionsFilter])

  useEffect(() => {
    if (selectedUserId) {
      if (selectedDetails === 1) {
        fetchUserPaymentSessionList(selectedUserId, 0)
      }
    }
  }, [selectedUserId, selectedDetails])

  useEffect(() => {
    fetchUserList(usersFilter, 0)
  }, [usersFilter])

  useEffect(() => {
    if (selectedUserId !== null && selectedUserId !== undefined) {
      fetchUserPaymentSessionList(selectedUserId, 0)
    }
  }, [selectedUserId])

  useEffect(() => {
    clearUsers()
    fetchUserList(usersFilter, 0)
  }, [clearUsers])

  const { t } = useTranslation()
  const [actionState, setActionState] = React.useState(0)
  const userFilterRef = useRef(null)
  const transactionsFilterRef = useRef(null)
  const showDialogHandler = useRef(null)
  const showDialogHandlerPhone = useRef(null)
  const showDialogHandlerPoints = useRef(null)
  const [editPhoneVisible, setEditPhoneVisible] = React.useState(false)
  const [newUserPhone, setNewUserPhone] = React.useState(null)
  const handleSelectedTab = (index) => {
    setSelectedDetails(index)
  }

  return (
    <div className={Styles.Container}>
      <div className={Styles.Filter} ref={userFilterRef}>
        <IFFilter
          onFilterChange={(newFilter) => {
            setUsersFilter(newFilter)
            clearUsers()
            clearCurrentUser()
          }}
          filters={usersFilter}
          textFieldPlaceholder={t('UserPage.UsersFilterPlaceholder')}
        />
      </div>
      {fetchUserListRequestState === RequestState.LOADING ? (
        <div className={Styles.LoadingContainer}>
          <IFLoadingLogo
            style={{
              width: '30em',
            }}
            backgroundColor={Colors.grey}
          />
        </div>
      ) : (
        <div className={Styles.UsersWrapper}>
          <div className={Styles.UsersContainer}>
            <div className={Styles.UsersList}>
              <UserListHeader />
              <Scrollbars autoHide style={{ width: '100%', height: '100%' }}>
                <div
                  style={{
                    backgroundColor: Colors.UserPageBackground,
                    borderRadius: '5px',
                  }}
                  className={Styles.UsersListWrapper}
                >
                  <UserList />
                </div>
              </Scrollbars>
            </div>

            <div
              style={{ backgroundColor: Colors.UserPageBackground }}
              className={Styles.UserContainer}
            >
              {userDetailsRequestState === RequestState.LOADING ? (
                <div className={Styles.LoadingContainer}>
                  <IFLoadingLogo
                    style={{
                      width: '15em',
                      marginBottom: '10em',
                    }}
                    backgroundColor={Colors.white}
                  />
                </div>
              ) : (
                <>
                  <Scrollbars autoHide className={Styles.UserDataContainer}>
                    <div className={Styles.UserInfoContainer}>
                      <div className={Styles.UserAvatar}>
                        <IFAvatar size={115} name={user.name} />
                      </div>
                      <div className={Styles.UserInfo}>
                        <IFText
                          style={{
                            color: Colors.UserPageTextColor,
                            fontSize: '2rem',
                            fontWeight: 'bold',
                            marginBottom: '0.3em',
                          }}
                        >
                          {user.name}
                        </IFText>
                        <div className={Styles.InfoText}>
                          <EmailIcon style={{ marginRight: '0.3em' }} />
                          <IFText>&nbsp;{user.email}</IFText>
                        </div>
                        <div
                          className={Styles.InfoTextPhone}
                          onMouseEnter={() => {
                            if (
                              editingAdminRole?.includes(
                                InfinityEnums.AdminPermissions.CAN_EDIT_USERS,
                              )
                            )
                              setEditPhoneVisible(true)
                          }}
                          onMouseLeave={() => {
                            if (
                              editingAdminRole?.includes(
                                InfinityEnums.AdminPermissions.CAN_EDIT_USERS,
                              )
                            )
                              setEditPhoneVisible(false)
                          }}
                        >
                          <PhoneIcon style={{ marginRight: '0.3em' }} />
                          <IFText>&nbsp;{user.phone}</IFText>
                          {editPhoneVisible ? (
                            <EditIcon
                              className={Styles.PhoneIcon}
                              fontSize={'small'}
                              onClick={() =>
                                showDialogHandlerPhone.current.show()
                              }
                              style={{
                                paddingLeft: '0.2em',
                                color: Colors.PhoneEditIcon,
                              }}
                            ></EditIcon>
                          ) : null}
                        </div>
                      </div>
                      <div className={Styles.UserInfoRightContainer}>
                        <div className={Styles.CreatedAt}>
                          <DateRangeIcon />
                          <IFText>
                            &nbsp;
                            {moment(user.created_at).format('MMM DD, YYYY LTS')}
                          </IFText>
                        </div>
                        <div className={Styles.Wallet}>
                          <AccountBalanceWalletIcon
                            style={{
                              fontSize: '2.5rem',
                              color: Colors.UserPageTextColor,
                              marginRight: '0.2rem',
                            }}
                          />
                          <div className={Styles.WalletContainer}>
                            <IFText
                              style={{
                                color: Colors.UserPageTextColor,
                              }}
                              className={Styles.CurrencyText}
                            >
                              {t('UserPage.Currency')}&nbsp;
                              {user.balance.toFixed(2)}
                            </IFText>
                            <div className={Styles.PointsBox}>
                              <IFText
                                style={{
                                  color: Colors.UserPageTextColor,
                                }}
                                className={Styles.CurrencyText}
                              >
                                PTS{' '}
                                {Intl.NumberFormat('en-US', 2).format(
                                  user.points,
                                )}
                              </IFText>
                              <InfoIcon
                                onClick={() => {
                                  showDialogHandlerPoints.current.show()
                                }}
                                style={{
                                  paddingLeft: '0.2rem',
                                  fontSize: '1rem',
                                  color: Colors.UserPageTextColor,
                                  cursor: 'pointer',
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={Styles.ChargingTokensContainer}>
                      <IFText
                        className={Styles.HeaderText}
                        style={{ color: Colors.UserPageTextColor }}
                      >
                        {t('UserPage.ChargingTokensTitle')}
                      </IFText>
                      <ChargingTokenList userId={user.id} />
                    </div>
                    <div className={Styles.TransactionsContainer}>
                      <UserPageTabs
                        ref={userPageTabsRef}
                        onValueChange={(index) => handleSelectedTab(index)}
                        isLoading={
                          userDetailsRequestState === RequestState.LOADING ||
                          fetchUserListRequestState === RequestState.LOADING
                        }
                        initialIndex={0}
                      />
                      {selectedDetails == 0 && (
                        <div
                          className={Styles.TranactionsFilter}
                          ref={transactionsFilterRef}
                        >
                          <IFFilter
                            onFilterChange={(newFilter) => {
                              clearTransactions()
                              setUserTransactionsFilter(newFilter)
                            }}
                            textFieldPlaceholder={t(
                              'UserPage.TransactionsFilterPlaceholder',
                            )}
                            filters={transactionsFilter}
                          />
                        </div>
                      )}
                    </div>
                    <Scrollbars autoHide autoHeight autoHeightMax={'70vh'}>
                      <div className={Styles.ContentContainer}>
                        {selectedDetails === 0 && (
                          <div className={Styles.Transactions}>
                            <TransactionList userId={user.id} />
                          </div>
                        )}
                        {selectedDetails === 1 && (
                          <div className={Styles.PaymentSessions}>
                            <PaymentSessionList userId={user.id} />
                          </div>
                        )}
                      </div>
                    </Scrollbars>
                  </Scrollbars>
                  {(editingAdminRole?.includes(
                    InfinityEnums.AdminPermissions.CAN_ADJUST_BALANCE,
                  ) ||
                    editingAdminRole?.includes(
                      InfinityEnums.AdminPermissions.CAN_ADD_CHARGING_TOKEN,
                    ) ||
                    editingAdminRole?.includes(
                      InfinityEnums.AdminPermissions.CAN_TOGGLE_USER_ACTIVATION,
                    )) && (
                    <div
                      className={Styles.UserActionsContainer}
                      style={{
                        borderLeft: `1px solid ${Colors.ActionsBorder}`,
                        paddingLeft: '16px',
                        paddingRight: '16px',
                      }}
                    >
                      <div className={Styles.UserActions}>
                        <IFText
                          className={Styles.ActionsTitle}
                          style={{ color: Colors.UserPageTextColor }}
                        >
                          {t('UserPage.ActionsTitle')}
                        </IFText>
                        <div className={Styles.ActionButtons}>
                          {editingAdminRole?.includes(
                            InfinityEnums.AdminPermissions.CAN_ADJUST_BALANCE,
                          ) && (
                            <div className={Styles.ButtonWrapper}>
                              {actionState === 1 ? (
                                <div className={Styles.FormContainer}>
                                  <AdjustBalanceForm
                                    onClick={adjustWallet}
                                    onSubmit={(amount) => {
                                      setActionState(0)
                                      adjustWallet(user.id, amount)
                                    }}
                                    className={Styles.ButtonClass}
                                  />
                                </div>
                              ) : (
                                <IFButton
                                  className={Styles.ButtonClass}
                                  color={Colors.UserPagePrimary}
                                  icon={faWallet}
                                  onClick={() => setActionState(1)}
                                  text={t('UserPage.AdjustButton')}
                                  isLoading={
                                    user.isNewBalanceLoading ? true : false
                                  }
                                />
                              )}
                            </div>
                          )}
                          {editingAdminRole?.includes(
                            InfinityEnums.AdminPermissions
                              .CAN_ADD_CHARGING_TOKEN,
                          ) && (
                            <div className={Styles.ButtonWrapper}>
                              {actionState === 2 ? (
                                <div className={Styles.FormContainer}>
                                  <AddCardForm
                                    onSubmit={(value) => {
                                      setActionState(0)
                                      addCard(user.id, value)
                                    }}
                                    className={Styles.ButtonClass}
                                  />
                                </div>
                              ) : (
                                <IFButton
                                  className={Styles.ButtonClass}
                                  color={Colors.UserPagePrimary}
                                  onClick={() => setActionState(2)}
                                  isLoading={
                                    user.isNewCardLoading ? true : false
                                  }
                                  icon={faCreditCard}
                                  text={t('UserPage.AddCardButton')}
                                />
                              )}
                            </div>
                          )}
                        </div>
                        {editingAdminRole?.includes(
                          InfinityEnums.AdminPermissions
                            .CAN_TOGGLE_USER_ACTIVATION,
                        ) && (
                          <div className={Styles.DeactivateButton}>
                            <IFButton
                              className={Styles.ButtonClass}
                              isLoading={
                                user.isToggleIsActiveLoading ? true : false
                              }
                              color={
                                user.isActive
                                  ? Colors.red
                                  : Colors.UserPagePrimary
                              }
                              onClick={() => {
                                showDialogHandler.current.show()
                              }}
                              icon={user.isActive ? faTimes : null}
                              text={
                                user.isActive
                                  ? t('UserPage.DeactivateButton')
                                  : t('UserPage.ActivateButton')
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <IFDialog
            ref={showDialogHandler}
            open={false}
            title={
              user.isActive
                ? t('UserPage.DeactivateTitle')
                : t('UserPage.ActivateTitle')
            }
            bodyText={
              user.isActive
                ? t('UserPage.DeactivateBodyText')
                : t('UserPage.ActivateBodyText')
            }
            buttonAcceptonClick={() =>
              toggleUserIsActive(user.id, user.isActive ? false : true)
            }
            buttonCancelColor={Colors.UserPageCancel}
            buttonAcceptColor={
              user.isActive
                ? Colors.UserPageDeactivateButton
                : Colors.UserPageActivateButton
            }
            buttonAcceptText={
              user.isActive
                ? t('UserPage.DeactivateConfirm')
                : t('UserPage.ActivateConfirm')
            }
          />
          <IFDialog
            ref={showDialogHandlerPhone}
            maxWidth={'sm'}
            fullWidth={true}
            style={{ margin: '15em' }}
            open={false}
            dismissOnAccept={false}
            title={t('UserPage.EditPhoneNumberModalTitle')}
            buttonAcceptonClick={() => {
              setCurrentUserNewPhone(user.id, newUserPhone)
              setNewUserPhone(null)
            }}
            buttonCancelColor={Colors.UserPagePrimary}
            buttonAcceptColor={Colors.UserPagePrimary}
            buttonAcceptText={t('UserPage.EditPhoneNumberModalAcceptText')}
            buttonClassName={Styles.ButtonPhoneEditModal}
          >
            <TextField
              fullWidth={true}
              id="standard-basic"
              label={t('UserPage.EditPhoneNumberTextFieldTitle')}
              variant="outlined"
              onChange={(e) => setNewUserPhone(e.target.value)}
            />
          </IFDialog>
          <IFModal
            ref={showDialogHandlerPoints}
            dismissOnAccept={false}
            title={t('UserPage.PointsInfoModalTitle')}
            maxWidth="sm"
            fullWidth={true}
            open={false}
          >
            <Scrollbars
              autoHide
              style={{ width: '100%', height: '100%' }}
              autoHeight
            >
              <div className={Styles.PointsContainer}>
                <PointsList userId={user.id} />
              </div>
            </Scrollbars>
          </IFModal>
        </div>
      )}
    </div>
  )
}
function mapDispatchToProps(dispatch) {
  return {
    fetchUserList: (usersFilter, paginationOffset) =>
      dispatch(UserActions.fetchUserList(usersFilter, paginationOffset)),
    clearUsers: () => dispatch(UserActions.clearUsers()),
    setUsersFilter: (newFilter) =>
      dispatch(UserActions.setUsersFilter(newFilter)),
    adjustWallet: (id, amount) =>
      dispatch(UserActions.setCurrentUserNewBalance(id, amount)),
    addCard: (id, value) =>
      dispatch(UserActions.addCurrentUserNewCard(id, value)),
    toggleUserIsActive: (id, isActive) =>
      dispatch(UserActions.toggleCurrentUserIsActive(id, isActive)),
    fetchUserDetails: (userId) =>
      dispatch(UserActions.fetchUserDetails(userId)),
    fetchUserTransactionList: (userId, filter, offset) =>
      dispatch(UserActions.fetchUserTransactionList(userId, filter, offset)),
    clearTransactions: () => dispatch(UserActions.clearTransactions()),
    setUserTransactionsFilter: (newFilter) =>
      dispatch(UserActions.setUserTransactionsFilter(newFilter)),
    clearCurrentUser: () => dispatch(UserActions.clearCurrentUser()),
    clearUserTransactionsFilter: () =>
      dispatch(UserActions.clearUserTransactionsFilter()),
    setCurrentUserNewPhone: (id, newPhone) =>
      dispatch(UserActions.setCurrentUserNewPhone(id, newPhone)),
    fetchUserPaymentSessionList: (userId, offset) =>
      dispatch(UserActions.fetchUserPaymentSessionList(userId, offset)),
    clearPaymentSessions: () => dispatch(UserActions.clearPaymentSessions()),
    fetchUserPoints: (userId, offset) =>
      dispatch(UserActions.fetchUserPoints(userId, offset)),
    clearPoints: () => dispatch(UserActions.clearPoints()),
  }
}

const mapStateToProps = (state) => ({
  transactionsFilter: UserSelectors.getTransactionsFilters(state),
  usersFilter: UserSelectors.getUsersFilters(state),
  user: UserSelectors.getCurrentUser(state),
  fetchUserListRequestState: UserSelectors.getFetchUserListRequestState(state),
  userDetailsRequestState: UserSelectors.getUserDetailsRequestState(state),
  selectedUserId: UserSelectors.getSelectedUserId(state),
  admin: AuthSelectors.getUser(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  points: UserSelectors.getPointsList(state),
})
export default connect(mapStateToProps, mapDispatchToProps)(UserPage)
