import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  fetchContactUsFormList: ['filter', 'offset'],
  fetchContactUsFormListLoading: null,
  fetchContactUsFormListSuccess: ['offset', 'contactUsForms', 'nextOffset'],
  fetchContactUsFormListError: ['error'],
  clearContactUsForms: null,
  setContactUsFormsFilter: ['newFilter'],

  updateContactUsFormStatus: [
    'contactUsFormId',
    'newStatusValue',
    'contactUsItemRef',
  ],
  updateContactUsFormStatusLoading: null,
  updateContactUsFormStatusSuccess: ['contactUsForm'],
  updateContactUsFormStatusFail: ['error'],
})

export const ContactUsFormTypes = Types
export default Creators
