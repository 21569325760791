import React, { useState } from 'react'
import Styles from './TransactionsDownload.module.css'
import { Colors } from 'Theme'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { saveAs } from 'file-saver'
import { transactionsApiService } from 'Services/ApiService'
import CircularProgress from '@mui/material/CircularProgress'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import DoneIcon from '@mui/icons-material/Done'
import TransactionSelectors from 'Stores/Transaction/Selectors'
import { toaster } from 'rsuite'
import { IFToastMessage } from 'Components'
import { CREATED } from 'Constants/HttpStatus'
var base64 = require('base-64')

const TransactionsDownload = ({ transactionsFilter }) => {
  const [progress, setProgress] = useState(0)
  const [getReport, setGetReport] = useState(false)
  const [pdfSuccess, setpdfSuccess] = useState(false)
  const [circularVariant, setCircularVariant] = useState('indeterminate')
  const [circularWidth, setCircularWidth] = useState(2)

  const animate = () => {
    setGetReport(!getReport)
    setTimeout(() => {
      setGetReport(false)
      animateDone()
    }, 500)
  }
  const animateDone = () => {
    setpdfSuccess(!pdfSuccess)
    setTimeout(() => {
      setpdfSuccess(false)
      setProgress(0)
      setCircularWidth(2)
      setCircularVariant('indeterminate')
    }, 1500)
  }
  const { t } = useTranslation()

  const getTransactionsReport = () => {
    setGetReport(true)
    let filter
    if (transactionsFilter != null) {
      filter = base64.encode(JSON.stringify(transactionsFilter))
    }
    transactionsApiService({
      url: `/report`,
      method: 'POST',
      data: {
        filter: filter,
      },
      responseType: 'blob', // important
      onDownloadProgress: (progressEvent) => {
        setCircularWidth(22)
        setCircularVariant('determinate')
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        ) // you can use this to show user percentage of file downloaded
        setProgress(percentCompleted)
        if (
          percentCompleted === 100 &&
          progressEvent.currentTarget.status === CREATED
        ) {
          animate()
        }
      },
    })
      .then((res) => {
        const excelBlob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        saveAs(excelBlob, `Transactions.xlsx`)
      })
      .catch((err) => {
        setGetReport(false)
        setpdfSuccess(false)
        setProgress(0)
        setCircularWidth(2)
        setCircularVariant('indeterminate')
        toaster.push(
          <IFToastMessage
            type="error"
            text={err.message ? err.message : err.statusText}
          />,
        )
      })
  }
  return (
    <div className={Styles.wrapper}>
      <DoneIcon
        className={pdfSuccess ? Styles.loadingVisible : Styles.loadingHide}
        style={{ color: Colors.primary }}
      />
      <CircularProgress
        className={getReport ? Styles.loadingVisible : Styles.loadingHide}
        variant={circularVariant}
        value={progress}
        style={{ color: Colors.TransactionDownload }}
        size={30}
        thickness={circularWidth}
      />
      <div className={!getReport && !pdfSuccess ? Styles.visible : Styles.hide}>
        <div className={Styles.downloadIcon}>
          <DownloadRoundedIcon
            sx={{
              fontSize: '1.5rem',
              padding: 0,
              margin: 0,
              color: Colors.TransactionDownload,
            }}
            onClick={() => {
              getTransactionsReport('xlsx')
            }}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  transactionsFilter: TransactionSelectors.getTransactionsFilter(state),
})

TransactionsDownload.propTypes = {
  transactionsFilter: PropTypes.arrayOf(PropTypes.object),
}

export default connect(mapStateToProps)(TransactionsDownload)
