import styled from 'styled-components'
import Colors from 'Theme/Colors'
import styles from './IFFilter.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import {
  IFText,
  IFButton,
  IFSelectPicker,
  IFCheckPicker,
  IFDateRangePicker,
  IFDatePicker,
} from 'Components'
import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { createRef, useImperativeHandle, useRef } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@material-ui/icons/Close'
import { Accordion, AccordionDetails } from '@material-ui/core'
import IFFilterType from 'Enums/IFFilterType'
import PropTypes from 'prop-types'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { withStyles } from '@material-ui/core/styles'
import TransactionsDownload from 'Components/TransactionsDownload/TransactionsDownload'
const TextContainer = styled.div`
  color: ${Colors.FilterShadow};
`
const InputContainer = styled.div`
  background-color: ${Colors.filterBackground};
`
const TextField = styled.input`
  border: 0em;
  &:focus ${this} {
    outline: none;
  }
`

const AccordionSummary = withStyles({
  root: {
    padding: '0em',
    backgroundColor: 'white !important',
    height: 56,
    '&$expanded': {
      minHeight: 56,
    },
    borderRadius: '5px',
  },
  content: {
    '&$expanded': {
      margin: '0px',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: Colors.filterBackground,
    '&:hover': {
      backgroundColor: Colors.primary,
      color: 'white',
    },
    fontSize: '0.9rem',
  },
  accordionDetails: {
    padding: '0em',
  },
  Accordion: {
    boxShadow: '0px 0px 2px 1px' + Colors.ChargingTransactionListItemShadow,
  },
}))

const IFFilter = React.forwardRef(
  (
    {
      onFilterChange = () => {},
      filters,
      textFieldPlaceholder,
      downloadTransactions,
    },
    ref,
  ) => {
    const classes = useStyles()
    const filterRef = useRef(null)
    const { t } = useTranslation()
    const [filterText, setFilterText] = React.useState('')
    const [isAccordionExpanded, setIsAccordionExpanded] = React.useState(false)
    const [chipFilters, setChipFilters] = React.useState(() => {
      let result = []
      for (let i = 0; i < filters.length; i++) {
        if (
          filters[i].value &&
          !(filters[i].value === '' || filters[i].value.length === 0)
        ) {
          if (filters[i].type === IFFilterType.KEYWORD) {
            for (let j = 0; j < filters[i].value.length; j++) {
              result.push({
                ...filters[i],
                value: filters[i].value[j],
                id: i,
              })
            }
          } else {
            result.push({
              ...filters[i],
              value: filters[i].value,
              id: i,
            })
          }
        }
      }
      return result
    })

    const [currentFilters, setCurrentFilters] = React.useState(() => {
      let result = []
      for (let i = 0; i < filters.length; i++) {
        if (
          filters[i].type === IFFilterType.CHECK ||
          filters[i].type === IFFilterType.DATE_RANGE ||
          filters[i].type === IFFilterType.KEYWORD
        ) {
          result.push({ ...filters[i], value: [], ref: createRef() })
        } else {
          result.push({ ...filters[i], value: '', ref: createRef() })
        }
      }
      return result
    })

    useImperativeHandle(ref, () => ({
      setIsAccordionExpanded,
    }))

    React.useEffect(() => {
      function handleClickOutside(event) {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
          for (let i = 0; i < currentFilters.length; i++) {
            if (
              currentFilters[i].ref.current &&
              currentFilters[i].ref.current.isOpen
            ) {
              return
            }
          }
          setIsAccordionExpanded(false)
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [filterRef, currentFilters])
    const clearValues = () => {
      var newFilters = [...currentFilters]
      for (let i = 0; i < newFilters.length; i++) {
        newFilters[i].type === IFFilterType.CHECK ||
        newFilters[i].type === IFFilterType.DATE_RANGE ||
        newFilters[i].type === IFFilterType.KEYWORD
          ? (newFilters[i].value = [])
          : (newFilters[i].value = '')
      }
      setChipFilters([])
      setCurrentFilters(newFilters)
      onFilterChange(newFilters)
    }

    const deleteKeyword = (chipIndex) => {
      var newFilters = [...currentFilters]
      for (let i = 0; i < newFilters[0].value.length; i++) {
        if (newFilters[0].value[i] === chipFilters[chipIndex].value) {
          newFilters[0].value.splice(i, 1)
          break
        }
      }
      setCurrentFilters(newFilters)
      let newChips = [...chipFilters]
      newChips.splice(chipIndex, 1)
      setChipFilters(newChips)
      onFilterChange(newFilters)
    }

    const clearSelect = (chipIndex) => {
      var newFilters = [...currentFilters]
      newFilters[chipFilters[chipIndex].id].value = ''
      setCurrentFilters(newFilters)
      let newChips = [...chipFilters]
      newChips.splice(chipIndex, 1)
      setChipFilters(newChips)
      onFilterChange(newFilters)
      if (currentFilters[chipFilters[chipIndex].id].ref.current)
        currentFilters[chipFilters[chipIndex].id].ref.current.clearValue()
    }

    const deleteDateOrCheckItem = (chipIndex) => {
      var newFilters = [...currentFilters]
      newFilters[chipFilters[chipIndex].id].value = []
      setCurrentFilters(newFilters)
      let newChips = [...chipFilters]
      newChips.splice(chipIndex, 1)
      setChipFilters(newChips)
      onFilterChange(newFilters)

      currentFilters[chipFilters[chipIndex].id].ref.current.clearValue()
    }

    const addKeywordFilter = () => {
      var newFilters = [...currentFilters]
      newFilters[0].value.push(filterText.trim())
      setCurrentFilters(newFilters)
      setChipFilters([
        ...chipFilters,
        { type: IFFilterType.KEYWORD, value: filterText },
      ])
      setFilterText('')
      onFilterChange(newFilters)
    }

    const changeFilterValue = (value, index) => {
      if (value.length === 0) {
        for (let i = 0; i < chipFilters.length; i++) {
          if (chipFilters[i].id === index) {
            let newChips = [...chipFilters]
            newChips.splice(i, 1)
            setChipFilters(newChips)
            break
          }
        }
      }
      let found = false
      var newFilters = [...currentFilters]
      newFilters[index].value = value
      for (let i = 0; i < chipFilters.length; i++) {
        if (chipFilters[i].id === index) {
          chipFilters[i] = { ...newFilters[index], id: index }
          found = true
          break
        }
      }
      if (!found) {
        setChipFilters([...chipFilters, { ...newFilters[index], id: index }])
      }
      setCurrentFilters([...newFilters])
      onFilterChange(newFilters)
    }

    return (
      <div ref={filterRef}>
        <Accordion className={classes.Accordion} expanded={isAccordionExpanded}>
          <AccordionSummary>
            <TextContainer className={styles.TextContainer}>
              <FontAwesomeIcon
                className={styles.FilterIcon}
                style={
                  chipFilters.length === 0
                    ? { color: Colors.filterIconEmpty }
                    : { color: Colors.filterIconFilled }
                }
                icon={faFilter}
              />
              <div className={styles.TextInputWrapper}>
                {chipFilters.map((filter, index) => {
                  if (filter.type === IFFilterType.KEYWORD) {
                    return (
                      <Chip
                        key={`chip ${index}`}
                        deleteIcon={
                          <CloseIcon
                            style={{ color: 'inherit', fontSize: '1em' }}
                          />
                        }
                        label={
                          <IFText style={{ display: 'inline' }}>
                            Keyword | {filter.value}
                          </IFText>
                        }
                        size="small"
                        className={classes.chip}
                        onDelete={() => deleteKeyword(index)}
                      />
                    )
                  } else if (filter.type === IFFilterType.SELECT) {
                    return (
                      <Chip
                        deleteIcon={
                          <CloseIcon
                            style={{ color: 'inherit', fontSize: '1em' }}
                          />
                        }
                        className={classes.chip}
                        size="small"
                        label={
                          <IFText style={{ display: 'inline' }}>
                            {filter.title} | {filter.value}
                          </IFText>
                        }
                        onDelete={() => clearSelect(index)}
                      />
                    )
                  } else if (filter.type === IFFilterType.CHECK) {
                    return (
                      <Chip
                        deleteIcon={
                          <CloseIcon
                            style={{ color: 'inherit', fontSize: '1em' }}
                          />
                        }
                        className={classes.chip}
                        size="small"
                        label={
                          <IFText style={{ display: 'inline' }}>
                            {filter.title} |{' '}
                            {filter.value.map((value, index) =>
                              index === 0
                                ? value
                                : filter.value.length === index + 1
                                ? t('IFFilter.or') + value
                                : ', ' + value,
                            )}
                          </IFText>
                        }
                        onDelete={() => deleteDateOrCheckItem(index)}
                      />
                    )
                  } else if (filter.type === IFFilterType.DATE_RANGE) {
                    return (
                      <Chip
                        deleteIcon={
                          <CloseIcon
                            style={{ color: 'inherit', fontSize: '1em' }}
                          />
                        }
                        className={classes.chip}
                        label={
                          <IFText style={{ display: 'inline' }}>
                            {filter.title} |{' '}
                            {moment(filter.value[0]).format('YYYY-MM-DD')} to{' '}
                            {moment(filter.value[1]).format('YYYY-MM-DD')}
                          </IFText>
                        }
                        size="small"
                        onDelete={() => deleteDateOrCheckItem(index)}
                      />
                    )
                  } else if (filter.type === IFFilterType.DATE) {
                    return (
                      <Chip
                        deleteIcon={
                          <CloseIcon
                            style={{ color: 'inherit', fontSize: '1em' }}
                          />
                        }
                        className={classes.chip}
                        label={
                          <IFText style={{ display: 'inline' }}>
                            {filter.title} |{' '}
                            {moment(filter.value).format('YYYY-MM-DD')}
                          </IFText>
                        }
                        size="small"
                        onDelete={() => clearSelect(index)}
                      />
                    )
                   }
                  return null
                })}
                <TextField
                  type="search"
                  placeholder={textFieldPlaceholder}
                  value={filterText}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
                      if (filterText.trim()) {
                        addKeywordFilter()
                      }
                    }
                  }}
                  onClick={() => {
                    setIsAccordionExpanded(!isAccordionExpanded)
                  }}
                  className={styles.TextField}
                  onChange={(event) => setFilterText(event.target.value)}
                />
              </div>
              {isAccordionExpanded ? (
                <>
                  <IFButton
                    text={t('IFFilter.clearButton')}
                    color={Colors.FilterClearButton}
                    onClick={() => {
                      for (let i = 0; i < currentFilters.length; i++) {
                        if (
                          currentFilters[i].type === IFFilterType.CHECK ||
                          currentFilters[i].type === IFFilterType.DATE_RANGE ||
                          currentFilters[i].type === IFFilterType.SELECT 
                        ) {
                          currentFilters[i].ref.current.clearValue()
                        }
                      }
                      clearValues()
                      setFilterText('')
                    }}
                    className={styles.Button}
                  />
                  {/* <IFButton
                    text={t('IFFilter.saveButton')}
                    color={Colors.FuilterSaveButton}
                    onClick={() => {
                      if (filterText) {
                        addKeywordFilter()
                        setIsAccordionExpanded(false)
                      }
                    }}
                    isFill={true}
                    className={styles.Button}
                  /> */}
                </>
              ) : null}
            </TextContainer>
            {downloadTransactions && (
              <div
                className={styles.download}
                style={{
                  position: isAccordionExpanded ? 'absolute' : 'relative',
                  top: isAccordionExpanded ? '3.9rem' : 0,
                  right: 0,
                }}
              >
                <TransactionsDownload />
              </div>
            )}
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <InputContainer className={styles.InputContainer}>
              {currentFilters.map((filter, index) => {
                if (filter.type === IFFilterType.SELECT) {
                  return (
                    <IFSelectPicker
                      key={`IfSelectPicker ${index}`}
                      ref={filter.ref}
                      title={filter.title}
                      data={filter.data}
                      onValueChange={(value) => {
                        changeFilterValue(value, index)
                      }}
                      initialValue={filter.value}
                    />
                  )
                }
                if (filter.type === IFFilterType.CHECK) {
                  return (
                    <IFCheckPicker
                      key={`IFCheckPicker ${index}`}
                      ref={filter.ref}
                      title={filter.title}
                      data={filter.data}
                      onValueChange={(value) => {
                        changeFilterValue(value, index)
                      }}
                      initialValue={filter.value}
                    />
                  )
                }
                if (filter.type === IFFilterType.DATE_RANGE) {
                  return (
                    <IFDateRangePicker
                      key={`IFDateRangePicker ${index}`}
                      ref={filter.ref}
                      title={filter.title}
                      onDateRangeSelected={(value) => {
                        changeFilterValue(value, index)
                      }}
                      initialValue={filter.value}
                    />
                  )
                }
                if (filter.type === IFFilterType.DATE) {
                  return (
                    <IFDatePicker
                      key={`IFDatePicker ${index}`}
                      ref={filter.ref}
                      title={filter.title}
                      onDateSelected={(value) => {
                        changeFilterValue(value, index)
                      }}
                      initialValue={filter.value}
                    />
                  )
                }
                return null
              })}
            </InputContainer>
          </AccordionDetails>
        </Accordion>
      </div>
    )
  },
)
IFFilter.propTypes = {
  filters: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func,
  textFieldPlaceholder: PropTypes.string,
}

export default IFFilter
