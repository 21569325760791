import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { IFFilter, IFText, IFLoadingLogo } from 'Components'
import { TransactionsPageList } from 'Containers'
import Colors from 'Theme/Colors'
import Styles from './TransactionsPage.module.css'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import { Scrollbars } from 'react-custom-scrollbars'
import TransactionSelectors from 'Stores/Transaction/Selectors'
import TransactionActions from 'Stores/Transaction/Actions'
import styled from 'styled-components'

const BordersDiv = styled.div`
  border: 1px solid ${Colors.DividerColor};
`

const RightBordersDiv = styled.div`
  border-right: 1px solid ${Colors.DividerColor};
  border-top: 1px solid ${Colors.DividerColor};
  border-bottom: 1px solid ${Colors.DividerColor};
`

const WhiteBackgroundDiv = styled.div`
  background-color: ${Colors.white};
`

const TransactionsPage = ({
  setTransactionsFilter,
  clearTransactions,
  transactionsFilter,
  fetchTransactionsListRequestState,
  fetchTransactionsList,
  paginationOffset,
  statistics,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    fetchTransactionsList(transactionsFilter, 0)
  }, [])

  useEffect(() => {
    fetchTransactionsList(transactionsFilter, 0)
  }, [transactionsFilter])

  return (
    <div className={Styles.Container}>
      <div className={Styles.Filter}>
        <IFFilter
          onFilterChange={(newFilter) => {
            setTransactionsFilter(newFilter)
            clearTransactions()
          }}
          filters={transactionsFilter}
          textFieldPlaceholder={t(
            'TransactionsPage.TransactionsFilterPlaceholder',
          )}
          downloadTransactions={true}
        />
      </div>
      {fetchTransactionsListRequestState === RequestState.LOADING &&
      paginationOffset === 0 ? (
        <div className={Styles.LoadingContainer}>
          <IFLoadingLogo
            style={{
              width: '30em',
            }}
            backgroundColor={Colors.grey}
          />
        </div>
      ) : (
        <div className={Styles.Wrapper}>
          <div className={Styles.TotalsContainer}>
            <WhiteBackgroundDiv className={Styles.TotalHeaderContainer}>
              <IFText
                className={Styles.TotalText}
                style={{ color: Colors.text }}
              >
                {t('TransactionsPage.TotalMoneyCollected')}
              </IFText>
              <IFText
                className={Styles.TotalText}
                style={{ color: Colors.text }}
              >
                {`${parseFloat(
                  statistics.totalMoneyCollected?.toFixed(2),
                ).toLocaleString()} EGP`}
              </IFText>
            </WhiteBackgroundDiv>

            <WhiteBackgroundDiv className={Styles.TotalHeaderContainer}>
              <IFText
                className={Styles.TotalText}
                style={{ color: Colors.text }}
              >
                {t('TransactionsPage.TotalChargingBalance')}
              </IFText>
              <IFText
                className={Styles.TotalText}
                style={{ color: Colors.text }}
              >
                {`${parseFloat(
                  statistics.totalChargingBalance?.toFixed(2),
                ).toLocaleString()} EGP`}
              </IFText>
            </WhiteBackgroundDiv>

            <WhiteBackgroundDiv className={Styles.TotalHeaderContainer}>
              <IFText
                className={Styles.TotalText}
                style={{ color: Colors.text }}
              >
                {t('TransactionsPage.TotalEnergyConsumption')}
              </IFText>
              <IFText
                className={Styles.TotalText}
                style={{ color: Colors.text }}
              >
                {`${parseFloat(
                  statistics.totalEnergyConsumption?.toFixed(2),
                ).toLocaleString()} kWh`}
              </IFText>
            </WhiteBackgroundDiv>
          </div>
          <div
            className={Styles.BottomContainer}
            style={{ backgroundColor: Colors.white }}
          >
            <div className={Styles.TransactionsContainer}>
              <div className={Styles.TitleHeadersContainer}>
                <BordersDiv className={Styles.TitleContainer}>
                  <IFText
                    className={Styles.TitleText}
                    style={{ color: Colors.text }}
                  >
                    {t('TransactionsPage.PhoneNumber')}
                  </IFText>
                </BordersDiv>
                <RightBordersDiv className={Styles.TitleContainer}>
                  <IFText
                    className={Styles.TitleText}
                    style={{ color: Colors.text }}
                  >
                    {t('TransactionsPage.CustomerName')}
                  </IFText>
                </RightBordersDiv>
                <RightBordersDiv className={Styles.TitleContainer}>
                  <IFText
                    className={Styles.TitleText}
                    style={{ color: Colors.text }}
                  >
                    {t('TransactionsPage.TransactionId')}
                  </IFText>
                </RightBordersDiv>
                <RightBordersDiv className={Styles.TitleContainer}>
                  <IFText
                    className={Styles.TitleText}
                    style={{ color: Colors.text }}
                  >
                    {t('TransactionsPage.TransactionType')}
                  </IFText>
                </RightBordersDiv>
                <RightBordersDiv className={Styles.TitleContainer}>
                  <IFText
                    className={Styles.TitleText}
                    style={{ color: Colors.text }}
                  >
                    {t('TransactionsPage.Amount')}
                  </IFText>
                </RightBordersDiv>
                <RightBordersDiv className={Styles.TitleContainer}>
                  <IFText
                    className={Styles.TitleText}
                    style={{ color: Colors.text }}
                  >
                    {t('TransactionsPage.EnergyConsumption')}
                  </IFText>
                </RightBordersDiv>
                <RightBordersDiv className={Styles.TitleContainer}>
                  <IFText
                    className={Styles.TitleText}
                    style={{ color: Colors.text }}
                  >
                    {t('TransactionsPage.TransactionDate')}
                  </IFText>
                </RightBordersDiv>
              </div>

              <div className={Styles.TransactionListContainer}>
                <Scrollbars autoHide style={{ width: '100%', height: '100%' }}>
                  <TransactionsPageList />
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
function mapDispatchToProps(dispatch) {
  return {
    setTransactionsFilter: (newFilter) =>
      dispatch(TransactionActions.setTransactionsFilter(newFilter)),
    clearTransactions: () => dispatch(TransactionActions.clearTransactions()),
    fetchTransactionsList: (filter, offset) =>
      dispatch(TransactionActions.fetchTransactionsList(filter, offset)),
  }
}
const mapStateToProps = (state) => ({
  transactionsFilter: TransactionSelectors.getTransactionsFilter(state),
  fetchTransactionsListRequestState:
    TransactionSelectors.getFetchTransactionsListRequestState(state),
  paginationOffset: TransactionSelectors.getPaginationOffset(state),
  statistics: TransactionSelectors.getStatistics(state),
})
export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPage)
