import { SpinnerCircularFixed } from 'spinners-react'
import PropTypes, { oneOfType } from 'prop-types'
import Colors from 'Theme/Colors'

const IFLoadingIndicator = ({
  color = Colors.primary,
  secondaryColor = Colors.ifIndicatorSecondary,
  ...props
}) => {
  return (
    <SpinnerCircularFixed
      color={color}
      secondaryColor={secondaryColor}
      {...props}
    />
  )
}

IFLoadingIndicator.propTypes = {
  color: PropTypes.string,
  secondaryColor: PropTypes.string,
  size: oneOfType([PropTypes.number, PropTypes.string]),
  thickness: PropTypes.number,
  speed: PropTypes.number,
  enabled: PropTypes.bool,
  still: PropTypes.bool,
  style: PropTypes.object,
}

export default IFLoadingIndicator
