import PropTypes from 'prop-types'
import Styles from './IFText.module.css'

const IFText = ({ children, className, style }) => {
  return (
    <p style={style} className={`${Styles.text} ${className}`}>
      {children}
    </p>
  )
}

IFText.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default IFText
