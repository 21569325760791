import { put, call, select } from 'redux-saga/effects'
import {
  usersApiService,
  transactionsApiService,
  handleError,
  pointsApiService,
} from 'Services/ApiService.js'
import UserActions from 'Stores/User/Actions'
import history from 'history/browser'
import UserSelectors from 'Stores/User/Selectors'
import { IFToastMessage } from 'Components'
import { toaster } from 'rsuite'
import i18n from 'i18next'
var base64 = require('base-64')

export function* fetchUserList({ filter, offset }) {
  if (offset === 0) yield put(UserActions.fetchUserListLoading())
  try {
    if (filter != null) {
      filter = base64.encode(JSON.stringify(filter))
    }

    const { data } = yield call(usersApiService.get, '/', {
      params: {
        filter: filter,
        offset: offset,
      },
    })

    if (offset === 0) history.push({ pathname: `/user` })

    yield put(
      UserActions.fetchUserListSuccess(
        offset,
        data.users,
        data.nextOffset,
        null,
      ),
    )
    // const slashArray = history.location.pathname.split('/')
    // const userIdParam = slashArray[slashArray.length - 1]
    // if (userIdParam.match(/^[0-9a-fA-F]{24}$/) && offset === 0) {
    //   const index = data.users.findIndex((user) => user.id === userIdParam)
    //   if (index !== -1) {
    //     UserActions.setSelectedUserIndex(index)
    //     yield put(
    //       UserActions.fetchUserListSuccess(
    //         offset,
    //         data.users,
    //         data.nextOffset,
    //         null,
    //       ),
    //     )
    //   }
    //   else {
    //     const user = yield* fetchUserDetails({ userId: userIdParam })
    //     const newUser = {
    //       id: user.id,
    //       isActive: user.isActive,
    //       name: user.name,
    //       phone: user.phone,
    //       updatedAt: user.updatedAt,
    //     }
    //     yield put(
    //       UserActions.fetchUserListSuccess(
    //         offset,
    //         data.users,
    //         data.nextOffset,
    //         newUser,
    //       ),
    //     )
    //     UserActions.setSelectedUserIndex(0)
    //   }
    // } else {
    //   if (offset === 0) history.push({ pathname: `/user` })

    //   yield put(
    //     UserActions.fetchUserListSuccess(
    //       offset,
    //       data.users,
    //       data.nextOffset,
    //       null,
    //     ),
    //   )
    // }
  } catch (e) {
    yield put(UserActions.fetchUserListError(e))
    handleError(e)
  }
}

export function* fetchUserDetails({ userId }) {
  yield put(UserActions.fetchUserDetailsLoading())
  try {
    const { data } = yield call(usersApiService.get, `/${userId}`)
    yield put(UserActions.fetchUserDetailsSuccess(data))
    const transactionsFilter = yield select(
      UserSelectors.getTransactionsFilters,
    )
    yield* fetchUserTransactionList({
      userId: userId,
      filter: transactionsFilter,
      offset: 0,
    })
    yield* fetchUserPaymentSessionList({
      userId: userId,
      offset: 0,
    })
    return data
  } catch (e) {
    yield put(UserActions.fetchUserDetailsFail())
    handleError(e)
  }
}

export function* setCurrentUserChargingTokenActive({
  userId,
  chargingTokenId,
  index,
}) {
  yield put(UserActions.setCurrentUserChargingTokenDeactiveLoading(index))
  try {
    yield call(
      usersApiService.patch,
      `${userId}/chargingTokens/${chargingTokenId}/deactivate`,
    )
    yield put(UserActions.setCurrentUserChargingTokenDeactiveSuccess(index))
  } catch (e) {
    yield put(UserActions.setCurrentUserChargingTokenDeactiveFail(index))
    handleError(e)
  }
}

export function* setCurrentUserTransactionRefund({ id, index }) {
  yield put(UserActions.setCurrentUserTransactionRefundLoading(index))
  try {
    yield call(transactionsApiService.post, `/refund`, {
      id: id,
    })
    yield put(UserActions.setCurrentUserTransactionRefundSuccess(index))
  } catch (e) {
    yield put(UserActions.setCurrentUserTransactionRefundFail(index))
    handleError(e)
  }
}

export function* addCurrentUserNewCard({ id, serialNumber }) {
  yield put(UserActions.addCurrentUserNewCardLoading())
  try {
    // PHASE 2: Add charging token endpoint changed to post v1/users/:id/chargingTokens/activate
    const { data } = yield call(usersApiService.post, `/${id}/chargingTokens`, {
      chargingTokenVisualNumber: serialNumber,
    })
    yield put(UserActions.addCurrentUserNewCardSuccess(data))
  } catch (e) {
    yield put(UserActions.addCurrentUserNewCardFail())
    handleError(e)
  }
}

export function* toggleCurrentUserIsActive({ id, setIsActive }) {
  yield put(UserActions.toggleCurrentUserIsActiveLoading())
  try {
    yield call(
      usersApiService.patch,
      `/${id}/${setIsActive ? 'activate' : 'deactivate'}`,
    )
    yield put(UserActions.toggleCurrentUserIsActiveSuccess(id, setIsActive))
  } catch (e) {
    yield put(UserActions.toggleCurrentUserIsActiveFail())
    handleError(e)
  }
}

export function* setCurrentUserNewBalance({ id, amount }) {
  yield put(UserActions.setCurrentUserNewBalanceLoading())
  try {
    const { data } = yield call(transactionsApiService.post, '/', {
      userId: id,
      amount: amount,
    })
    yield put(UserActions.setCurrentUserNewBalanceSuccess(data))
  } catch (e) {
    yield put(UserActions.setCurrentUserNewBalanceFail())
    handleError(e)
  }
}

export function* fetchUserTransactionList({ userId, filter, offset }) {
  yield put(UserActions.fetchUserTransactionListLoading())
  try {
    if (filter != null) {
      filter = base64.encode(JSON.stringify(filter))
    }

    const { data } = yield call(
      usersApiService.get,
      `/${userId}/transactions`,
      {
        params: {
          filter: filter,
          offset: offset,
        },
      },
    )
    yield put(
      UserActions.fetchUserTransactionListSuccess(
        offset,
        data.transactions,
        data.nextOffset,
      ),
    )
  } catch (e) {
    yield put(UserActions.fetchUserTransactionListFail(e))
    handleError(e)
  }
}

export function* setCurrentUserNewPhone({ id, newPhone }) {
  yield put(UserActions.setCurrentUserNewPhoneLoading())
  try {
    const { data } = yield call(usersApiService.patch, `/${id}/changePhone`, {
      phone: newPhone,
    })
    yield put(UserActions.setCurrentUserNewPhoneSuccess(id, newPhone))
  } catch (e) {
    yield put(UserActions.setCurrentUserNewPhoneFail(id))
    handleError(e)
  }
}

export function* reverseUserChargeTransaction({
  values,
  transactionData,
  onResponse,
}) {
  yield put(
    UserActions.reverseUserChargeTransactionLoading(transactionData.index),
  )
  try {
    const { data } = yield call(
      transactionsApiService.post,
      `/${transactionData.id}/reverse`,
      {
        energyConsumption: values.energyConsumption * 1000, // convert to wh
        chargingLevel: values.chargingLevel,
        reverseType: values.reverseType,
      },
    )
    yield put(
      UserActions.reverseUserChargeTransactionSuccess(transactionData.index),
    )
    onResponse()
    toaster.push(
      <IFToastMessage
        type="success"
        text={i18n.t('ReverseTransactionModal.SuccessMessage')}
      />,
    )
  } catch (e) {
    yield put(
      UserActions.reverseUserChargeTransactionError(transactionData.index, e),
    )
    handleError(e)
    onResponse()
  }
}

export function* fetchUserPoints({ id, offset }) {
  yield put(UserActions.fetchUserPointsLoading())
  try {
    const { data } = yield call(pointsApiService.get, `/${id}`, {
      params: {
        offset: offset,
      },
    })
    yield put(
      UserActions.fetchUserPointsSuccess(offset, data.points, data.nextOffset),
    )
  } catch (e) {
    yield put(UserActions.fetchUserPointsError())
    handleError(e)
  }
}

export function* fetchUserPaymentSessionList({ userId, offset }) {
  yield put(UserActions.fetchUserPaymentSessionListLoading())
  try {
    const { data } = yield call(usersApiService.get, `/${userId}/sessions`, {
      params: {
        offset: offset,
      },
    })
    yield put(
      UserActions.fetchUserPaymentSessionListSuccess(
        offset,
        data.paymentSessions,
        data.nextOffset,
      ),
    )
  } catch (e) {
    yield put(UserActions.fetchUserPaymentSessionListError(e))
    handleError(e)
  }
}
