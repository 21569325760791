import { IFText, IFButton, IFLoadingIndicator } from 'Components'
import PropTypes from 'prop-types'
import Styles from './AdminListItem.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import React, { useState, useImperativeHandle } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const PenIcon = styled.div`
color: ${Colors.AdminListPenIcon};
  }
`

const DeleteIcon = styled.div`
color: ${Colors.AdminListDeleteIcon};
  }
`

const AdminListItem = React.forwardRef(
  (
    {
      admin,
      avatar,
      roles,
      isDeleteLoading,
      isUpdateLoading,
      onUpdateClick,
      onDeleteClick,
    },
    ref,
  ) => {
    const { t } = useTranslation()

    const [accordExpanded, setAccordExpanded] = useState(false)
    const [selectedDropDownValue, setSelectedDropDownValue] = useState(
      admin.role,
    )
    const collapse = () => {
      setAccordExpanded(false)
    }

    useImperativeHandle(ref, () => ({
      collapse,
    }))

    const getAdminRoleName = () => {
      let result = ''
      for (let i = 0; i < roles.length; i++) {
        if (admin.role === roles[i].id) {
          result = roles[i].name
          break
        }
      }
      return result
    }

    const onClickAccordionHandler = () => {
      setAccordExpanded((expanded) => {
        return !expanded
      })
      if (!accordExpanded) setSelectedDropDownValue(admin.role)
    }

    const useStyles = makeStyles({
      root: {
        height: '5rem',
        width: '11rem',
      },
      accordion: {
        boxShadow: '0px 0px 0px 0px' + Colors.ChargingTransactionListItemShadow,
        '&:hover': {
          boxShadow:
            '0px 0px 2px 3px' + Colors.ChargingTransactionListItemShadow,
        },
      },
    })

    const Classes = useStyles()

    return (
      <div className={Styles.Container}>
        <Accordion
          onClick={onClickAccordionHandler}
          expanded={accordExpanded}
          className={Classes.accordion}
        >
          <AccordionSummary>
            <div className={Styles.ListItemSummary}>
              <div className={Styles.AvatarNameEmail}>
                <div className={Styles.AvatarIcon}>{avatar}</div>
                <div className={Styles.NameEmail}>
                  <IFText className={Styles.Name}>{admin.name}</IFText>
                  <IFText
                    className={Styles.Email}
                    style={{ color: Colors.AdminListItemEmail }}
                  >
                    {admin.email}
                  </IFText>
                </div>
              </div>

              {!accordExpanded ? (
                <IFText className={Styles.Role}>{getAdminRoleName()}</IFText>
              ) : (
                <div className={Styles.DropDown}>
                  <FormControl
                    className={Classes.root}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <InputLabel>
                      {t('AdminListItem.roleSelectorHeader')}
                    </InputLabel>
                    <Select
                      value={selectedDropDownValue}
                      onChange={(e) => {
                        setSelectedDropDownValue(e.target.value)
                      }}
                    >
                      {roles.map((role) => {
                        return <MenuItem value={role.id}>{role.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </div>
              )}

              {!accordExpanded ? (
                <PenIcon className={Styles.PenIcon}>
                  <FontAwesomeIcon icon={faPen} />
                </PenIcon>
              ) : !isDeleteLoading ? (
                <DeleteIcon className={Styles.DeleteIcon}>
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={(e) => {
                      e.stopPropagation()
                      onDeleteClick()
                    }}
                  />
                </DeleteIcon>
              ) : (
                <div className={Styles.LoadingIndicator}>
                  <IFLoadingIndicator size={'50%'} />
                </div>
              )}
            </div>
          </AccordionSummary>

          <AccordionDetails
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <div className={Styles.ListItemDetails}>
              <IFButton
                color={Colors.primary}
                text={t('AdminListItem.cancelButton')}
                isLoading={false}
                isFill={false}
                className={Styles.CancelButton}
                onClick={onClickAccordionHandler}
              />

              <IFButton
                color={Colors.primary}
                text={t('AdminListItem.updateButton')}
                isLoading={isUpdateLoading}
                isFill={true}
                className={Styles.UpdateButton}
                onClick={() => onUpdateClick(selectedDropDownValue)}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    )
  },
)

AdminListItem.propTypes = {
  admin: PropTypes.object,
  avatar: PropTypes.object,
  roles: PropTypes.arrayOf(PropTypes.object),
  onUpdateClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
}

export default AdminListItem
