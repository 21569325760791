import { IFText, ProgressBar } from 'Components'
import Styles from './PointsListItem.module.css'
import Colors from 'Theme/Colors'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const PointsListItem = ({
  name,
  expiryDateText,
  amountText,
  remainingText,
  dateCreated,
}) => {
  const { t } = useTranslation()
  let expiryDateMoment = moment(expiryDateText).format('DD-MM-YYYY')
  let expired = moment().isSameOrAfter(moment(expiryDateText))? true : false;
  let expiryText= expired? t('PointsItem.ExpiredText') : t('PointsItem.ExpiresOnText') + expiryDateMoment;
  let percentageComplete =
    (parseInt(remainingText, 10) / parseInt(amountText, 10)) * 100

  return (
    <div className={Styles.WrapperContainer}>
      <div className={Styles.FieldContainer}>
        <IFText
          className={Styles.FieldText}
          style={!expired ? { color: Colors.text } : { color: Colors.darkGrey }}
        >
          {name}
        </IFText>
        <IFText
          className={Styles.DateFieldText}
          style={!expired ? { color: Colors.text } : { color: Colors.darkGrey }}
        >
          {t('PointsItem.CreatedOnText')}
          {moment(dateCreated).format('DD-MM-YYYY')}
        </IFText>
      </div>
      <div className={Styles.ProgressContainer}>
        <IFText
          className={Styles.FieldText}
          style={!expired ? { color: Colors.text } : { color: Colors.darkGrey }}
        >
          {Intl.NumberFormat("en-US", 2).format(remainingText)}/{Intl.NumberFormat("en-US", 2).format(amountText)}
        </IFText>
        <ProgressBar
          completed={percentageComplete}
          fillColor={!expired ? Colors.primary : Colors.darkGrey}
          height= "0.75rem"
          width= "70%"
          backgroundColor= {Colors.ProgressBar}
        />
      </div>
      <div className={Styles.ExpiryContainer}>
        <IFText
          className={Styles.FieldText}
          style={!expired ? { color: Colors.text } : { color: Colors.red }}
        >
          {expiryText}
        </IFText>
      </div>
    </div>
  )
}

PointsListItem.propTypes = {
  name: PropTypes.string,
  expiryDateText: PropTypes.string,
  amountText: PropTypes.string,
  remainingText: PropTypes.string,
  dateCreated: PropTypes.string,
}

export default PointsListItem
