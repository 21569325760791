import { INITIAL_STATE } from './initialState'
import { TransactionTypes } from './Actions'
import { createReducer } from 'reduxsauce'
import RequestState from 'Enums/RequestState'

export const fetchTransactionsListLoading = (state) => {
  return {
    ...state,
    fetchTransactionsListRequestState: RequestState.LOADING,
  }
}

export const fetchTransactionsListSuccess = (
  state,
  { offset, transactions, nextOffset, statistics },
) => {
  let result = []
  for (let j = 0; j < transactions.length; j++) {
    if (
      !state.transactions.some(
        (transaction) => transaction._id === transactions[j]._id,
      )
    ) {
      result.push(transactions[j])
    }
  }
  if (offset === 0)
    return {
      ...state,
      transactions: [...transactions],
      paginationOffset: nextOffset,
      fetchTransactionsListRequestState: RequestState.SUCCEEDED,
      statistics: statistics,
    }
  else
    return {
      ...state,
      transactions: [...state.transactions, ...result],
      paginationOffset: nextOffset,
      fetchTransactionsListRequestState: RequestState.SUCCEEDED,
    }
}

export const fetchTransactionsListError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchTransactionsListRequestState: requestState,
  }
}

export const setTransactionsFilter = (state, { newFilter }) => {
  return {
    ...state,
    transactionsFilter: newFilter,
  }
}

export const clearTransactions = (state) => {
  return {
    ...state,
    transactions: [],
    paginationOffset: 0,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [TransactionTypes.FETCH_TRANSACTIONS_LIST_LOADING]:
    fetchTransactionsListLoading,
  [TransactionTypes.FETCH_TRANSACTIONS_LIST_SUCCESS]:
    fetchTransactionsListSuccess,
  [TransactionTypes.FETCH_TRANSACTIONS_LIST_ERROR]: fetchTransactionsListError,
  [TransactionTypes.SET_TRANSACTIONS_FILTER]: setTransactionsFilter,
  [TransactionTypes.CLEAR_TRANSACTIONS]: clearTransactions,
})
