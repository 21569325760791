import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import VoucherSelectors from 'Stores/Voucher/Selectors'
import VoucherActions from 'Stores/Voucher/Actions'
import styles from './VoucherList.module.css'
import RequestState from 'Enums/RequestState'
import { Waypoint } from 'react-waypoint'
import { IFLoadingIndicator, IFText, VoucherItem } from 'Components'
import { useTranslation } from 'react-i18next'
import { set } from 'animejs'

const VoucherList = ({
  voucherListPaginationOffset,
  vouchers,
  fetchVouchers,
  fetchVouchersRequestState,
  clearVouchers,
  voucherFilters,
  updateVoucherRequestState,
  updateVoucher,
}) => {
  const { t } = useTranslation()

  const loadMoreData = () => {
    if (fetchVouchersRequestState === RequestState.LOADING) return

    fetchVouchers(voucherFilters, voucherListPaginationOffset)
  }

  const [index, setIndex] = useState(-1)
  const [loadingStates, setLoadingStates] = useState(
    Array(vouchers.length).fill(false),
  )

  const voucherListItemsRefs = useRef([])

  const onResponse = (index, status) => {
    setLoadingStates((prevState) => {
      prevState[index] = false
      return [...prevState]
    })
  }

  const handleDoneClick = (newVoucher, index) => {
    const updatedVouchers = [...vouchers]
    const voucherToUpdate = updatedVouchers[index]
    setLoadingStates((prevLoadingStates) => {
      const newLoadingStates = [...prevLoadingStates]
      newLoadingStates[index] = true
      return newLoadingStates
    })

    updateVoucher(voucherToUpdate.id, newVoucher, index, onResponse)
  }

  useEffect(() => {
    if (
      updateVoucherRequestState === RequestState.SUCCEEDED &&
      voucherListItemsRefs.current[index] &&
      voucherListItemsRefs.current[index].current
    ) {
      voucherListItemsRefs.current[index].current.closeAccordion()
    }
  }, [updateVoucherRequestState, index, voucherListItemsRefs])

  return (
    <div className={styles.Container}>
      {vouchers.length === 0 ? (
        <div className={styles.NoPointsFoundContainer}>
          <IFText>{t('VouchersPage.NoVouchers')}</IFText>
        </div>
      ) : (
        vouchers.map((voucher, i) => (
          <div key={i}>
            <VoucherItem
              voucher={voucher}
              onUpdateClick={(newVoucher) => handleDoneClick(newVoucher, i)}
              isUpdateLoading={loadingStates[i]}
              ref={voucherListItemsRefs.current[i]}
            />
          </div>
        ))
      )}
      {voucherListPaginationOffset && vouchers && vouchers.length !== 0 ? (
        <div className={styles.WaypointContainer}>
          <Waypoint onEnter={loadMoreData} />
          <IFLoadingIndicator
            style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
            size={'2em'}
            thickness={200}
          />
        </div>
      ) : null}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchVouchers: (filter, offset) =>
      dispatch(VoucherActions.fetchVouchers(filter, offset)),
    clearVouchers: () => dispatch(VoucherActions.clearVouchers()),
    updateVoucher: (voucherId, newVoucher, index, onResponse) =>
      dispatch(
        VoucherActions.updateVoucher(voucherId, newVoucher, index, onResponse),
      ),
  }
}

const mapStateToProps = (state) => ({
  vouchers: VoucherSelectors.getVouchersList(state),
  voucherListPaginationOffset: VoucherSelectors.getPaginationOffset(state),
  fetchVouchersRequestState:
    VoucherSelectors.getFetchVouchersListRequestState(state),
  voucherFilters: VoucherSelectors.getVouchersFilter(state),
  updateVoucherRequestState:
    VoucherSelectors.getUpdateVoucherRequestState(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(VoucherList)
