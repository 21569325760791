import { takeLatest, all, takeEvery } from 'redux-saga/effects'
import { AuthTypes } from '../Stores/Auth/Actions'
import { AdminTypes } from '../Stores/Admin/Actions'
import { UserTypes } from '../Stores/User/Actions'
import { TransactionTypes } from 'Stores/Transaction/Actions'
import { ContactUsFormTypes } from '../Stores/ContactUsForm/Actions'
import { NotificationTypes } from '../Stores/Notification/Actions'
import { VoucherTypes } from '../Stores/Voucher/Actions'

import { login, getUser, logout, fetchEditingAdminRole } from './AuthSaga'

import {
  fetchAdminList,
  updateAdminRole,
  deleteAdmin,
  addAdmin,
  fetchAdminRoles,
} from './AdminSaga'

import {
  fetchUserList,
  setCurrentUserTransactionRefund,
  setCurrentUserNewBalance,
  setCurrentUserChargingTokenActive,
  addCurrentUserNewCard,
  toggleCurrentUserIsActive,
  fetchUserDetails,
  fetchUserTransactionList,
  setCurrentUserNewPhone,
  reverseUserChargeTransaction,
  fetchUserPoints,
  fetchUserPaymentSessionList,
} from './UserSaga'

import {
  fetchContactUsFormList,
  updateContactUsFormStatus,
} from './ContactUsSaga'

import { fetchNotificationGroups, sendNotification } from './NotificationSaga'
import { fetchTransactionsList } from './TransactionSaga'
import { fetchVouchers, addVoucher, updateVoucher } from './VoucherSaga'

export default function* root() {
  yield all([takeLatest(AuthTypes.LOGIN, login)])
  yield all([takeLatest(AuthTypes.GET_USER, getUser)])
  yield all([takeLatest(AuthTypes.LOGOUT, logout)])
  yield all([
    takeLatest(AuthTypes.FETCH_EDITING_ADMIN_ROLE, fetchEditingAdminRole),
  ])

  yield all([
    takeLatest(
      ContactUsFormTypes.FETCH_CONTACT_US_FORM_LIST,
      fetchContactUsFormList,
    ),
  ])
  yield all([
    takeLatest(
      ContactUsFormTypes.UPDATE_CONTACT_US_FORM_STATUS,
      updateContactUsFormStatus,
    ),
  ])

  yield all([takeLatest(UserTypes.FETCH_USER_LIST, fetchUserList)])
  yield all([takeLatest(UserTypes.FETCH_USER_DETAILS, fetchUserDetails)])

  yield all([
    takeLatest(UserTypes.FETCH_USER_TRANSACTION_LIST, fetchUserTransactionList),
  ])
  yield all([
    takeLatest(
      UserTypes.SET_CURRENT_USER_CHARGING_TOKEN_ACTIVE,
      setCurrentUserChargingTokenActive,
    ),
  ])
  yield all([
    takeLatest(
      UserTypes.SET_CURRENT_USER_TRANSACTION_REFUND,
      setCurrentUserTransactionRefund,
    ),
  ])
  yield all([takeLatest(UserTypes.FETCH_USER_POINTS, fetchUserPoints)])

  yield all([takeLatest(AdminTypes.FETCH_ADMIN_LIST, fetchAdminList)])
  yield all([takeLatest(AdminTypes.FETCH_ADMIN_ROLES, fetchAdminRoles)])

  yield all([takeLatest(AdminTypes.UPDATE_ADMIN_ROLE, updateAdminRole)])

  yield all([takeLatest(AdminTypes.DELETE_ADMIN, deleteAdmin)])
  yield all([takeLatest(AdminTypes.ADD_ADMIN, addAdmin)])

  yield all([
    takeLatest(UserTypes.ADD_CURRENT_USER_NEW_CARD, addCurrentUserNewCard),
  ])

  yield all([
    takeLatest(
      UserTypes.TOGGLE_CURRENT_USER_IS_ACTIVE,
      toggleCurrentUserIsActive,
    ),
  ])

  yield all([
    takeLatest(
      UserTypes.SET_CURRENT_USER_NEW_BALANCE,
      setCurrentUserNewBalance,
    ),
  ])

  yield all([
    takeLatest(UserTypes.SET_CURRENT_USER_NEW_PHONE, setCurrentUserNewPhone),
  ])

  yield all([
    takeLatest(
      UserTypes.REVERSE_USER_CHARGE_TRANSACTION,
      reverseUserChargeTransaction,
    ),
  ])

  yield all([
    takeLatest(
      NotificationTypes.FETCH_NOTIFICATION_GROUPS,
      fetchNotificationGroups,
    ),
  ])
  yield all([takeLatest(NotificationTypes.SEND_NOTIFICATION, sendNotification)])

  yield all([
    takeLatest(TransactionTypes.FETCH_TRANSACTIONS_LIST, fetchTransactionsList),
  ])

  yield all([takeLatest(VoucherTypes.FETCH_VOUCHERS, fetchVouchers)])

  yield all([takeLatest(VoucherTypes.ADD_VOUCHER, addVoucher)])

  yield all([takeEvery(VoucherTypes.UPDATE_VOUCHER, updateVoucher)])

  yield all([
    takeLatest(
      UserTypes.FETCH_USER_PAYMENT_SESSION_LIST,
      fetchUserPaymentSessionList,
    ),
  ])
}
