import IFFIlterType from 'Enums/IFFilterType'
import RequestState from 'Enums/RequestState'

export const INITIAL_STATE = {
  filterText: '',
  fetchUsersListRequestState: RequestState.UNINITIALIZED,
  fetchUserTransactionListRequestState: RequestState.UNINITIALIZED,
  fetchUserPaymentSessionListRequestState: RequestState.UNINITIALIZED,
  paginationOffset: 0,
  transactionListPaginationOffset: 0,
  paymentSessionListPaginationOffset: 0,
  userSortingAsc: false,
  usersFilters: [
    {
      type: IFFIlterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.CHECK,
      title: 'Status',
      data: ['Active', 'Inactive'],
      initialValue: [],
      value: [],
    },
  ],
  transactionsFilters: [
    {
      type: IFFIlterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.CHECK,
      title: 'Type',
      data: ['addMoney', 'charge', 'refund'],
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.DATE_RANGE,
      title: 'Date',
      initialValue: [],
      value: [],
    },
  ],
  users: [],
  currentUser: {
    id: '',
    chargingTokens: [],
    balance: 0,
    isActive: true,
    name: '',
    phone: '',
    transactions: [],
    email: '',
    paymentSessions: [],
  },
  fetchUserDetailsRequestState: RequestState.UNINITIALIZED,
  selectedUserIndex: 0,
  reverseChargeTransactionRequestState: RequestState.UNINITIALIZED,
  points: [],
  fetchUserPointsListRequestState: RequestState.UNINITIALIZED,
  pointsListPaginationOffset: 0,
}
