import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  fetchTransactionsList: ['filter', 'offset'],
  fetchTransactionsListLoading: null,
  fetchTransactionsListSuccess: [
    'offset',
    'transactions',
    'nextOffset',
    'statistics',
  ],
  fetchTransactionsListError: ['error'],

  setTransactionsFilter: ['newFilter'],
  clearTransactions: null,
})

export const TransactionTypes = Types
export default Creators
