import { React, Fragment } from 'react'
import styles from './IFButton.module.css'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SpinnerCircularFixed } from 'spinners-react'
import PropTypes from 'prop-types'
import IFText from 'Components/IFText/IFText'
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  &:hover {
    color: whitesmoke;
  }
`
const AliveButton = styled.button`
  &:hover {
    color: whitesmoke;
  }
  &:before {
    background: ${(props) => props.color};
  }
  color: ${(props) =>
    props.isFill || props.isLoading ? 'whitesmoke' : props.color};
  border: 2px solid ${(props) => props.color};
  background: ${(props) =>
    props.isFill || props.isLoading ? props.color : ''};
`

const DeadButton = styled.button``

const IFButton = ({
  color,
  isFill,
  isDead,
  isLoading,
  icon,
  text,
  onClick,
  className,
}) => {
  const Button = isDead ? DeadButton : AliveButton
  return (
    <div className={className}>
      <Button
        color={color}
        isFill={isFill}
        isLoading={isLoading}
        onClick={onClick}
        disabled={isDead ? true : false}
        className={
          isDead
            ? styles.disabled
            : isFill && !isLoading
            ? styles.filled
            : isLoading
            ? null
            : styles.fill
        }
      >
        {isLoading ? (
          <SpinnerCircularFixed
            color={'rgba(256,256,256,1)'}
            secondaryColor={'rgba(256,256,256,0.3)'}
            thickness={200}
            size={25}
          />
        ) : icon ? (
          <Fragment>
            <IFText>
              <StyledFontAwesomeIcon icon={icon} />
              &nbsp;&nbsp;{text}
            </IFText>
          </Fragment>
        ) : (
          <IFText>{text}</IFText>
        )}
      </Button>
    </div>
  )
}

IFButton.propTypes = {
  color: PropTypes.string.isRequired,
  isFill: PropTypes.bool,
  isDead: PropTypes.bool,
  isLoading: PropTypes.bool,
  icon: PropTypes.object,
  onClick: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
}

export default IFButton
