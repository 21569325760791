import React, { useEffect } from 'react'
import Colors from 'Theme/Colors'
import anime from 'animejs'
import PropTypes from 'prop-types'

const IFLoadingLogo = ({
  primaryStrokeColor = Colors.primary,
  secondaryStrokeColor = Colors.primaryLight,
  backgroundColor = Colors.white,
  className,
  style,
}) => {
  useEffect(() => {
    anime({
      targets: '.logo path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 1485,
      delay: function (el, i) {
        return i * 200
      },
      loop: true,
      direction: 'alternate',
    })
    anime({
      targets: '.logo g',
      keyframes: [
        { fill: backgroundColor },
        { fill: backgroundColor },
        { fill: primaryStrokeColor },
        { fill: primaryStrokeColor },
      ],
      easing: 'easeInOutSine',
      fill: primaryStrokeColor,
      duration: 1685,
      loop: true,
      direction: 'alternate',
    })
  }, [backgroundColor, primaryStrokeColor])

  return (
    <div style={style} className={className}>
      <svg
        className="logo"
        viewBox="0 -100 2130 1000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0,786) scale(0.1,-0.1)" fill={backgroundColor}>
          <path
            stroke={secondaryStrokeColor}
            strokeWidth="150"
            d="M3565 7819 c-280 -21 -617 -84 -833 -155 -57 -19 -110 -34 -118 -34
-8 0 -22 -4 -32 -10 -24 -13 -83 -37 -110 -45 -72 -21 -402 -196 -559 -297
-76 -49 -143 -91 -173 -108 -14 -8 -61 -40 -104 -70 -43 -30 -88 -62 -100 -70
-11 -8 -35 -26 -52 -40 -17 -14 -48 -38 -70 -55 -137 -105 -420 -383 -554
-545 -34 -41 -71 -86 -83 -100 -13 -14 -30 -36 -39 -50 -10 -14 -40 -56 -67
-95 -28 -38 -58 -84 -66 -102 -9 -17 -25 -39 -36 -49 -10 -9 -19 -22 -19 -28
0 -6 -11 -27 -24 -47 -20 -30 -74 -125 -107 -189 -37 -74 -63 -130 -74 -160
-7 -19 -22 -55 -33 -80 -30 -65 -92 -245 -108 -315 -7 -33 -23 -91 -34 -130
-11 -38 -33 -149 -48 -245 -25 -161 -27 -198 -26 -460 0 -257 3 -302 27 -460
14 -96 35 -209 47 -250 11 -41 25 -97 31 -125 13 -58 78 -256 121 -365 63
-161 142 -328 223 -470 24 -41 51 -88 61 -105 26 -46 180 -274 219 -325 19
-25 43 -56 52 -70 10 -14 27 -36 39 -50 13 -14 60 -69 105 -124 157 -189 409
-432 633 -611 48 -38 102 -82 119 -96 18 -14 66 -49 107 -78 41 -28 86 -60
100 -70 64 -46 122 -84 209 -136 19 -11 57 -35 84 -53 28 -19 65 -40 82 -48
16 -7 66 -34 110 -58 85 -48 241 -127 365 -184 41 -19 98 -45 125 -58 28 -13
82 -35 120 -50 39 -14 99 -37 135 -51 36 -14 99 -36 140 -50 41 -14 109 -38
150 -53 41 -15 91 -31 110 -35 19 -4 68 -18 109 -31 41 -14 97 -30 125 -35 28
-6 96 -23 151 -39 55 -15 120 -31 145 -34 25 -4 97 -17 160 -31 208 -43 521
-86 785 -107 523 -42 1277 -22 1715 46 85 13 180 27 210 30 243 28 778 147
1084 242 60 19 114 34 121 34 8 0 49 13 92 29 90 33 212 74 298 101 33 10 112
37 175 60 63 24 149 55 190 70 41 15 95 36 120 48 25 11 59 24 75 28 17 4 57
19 90 34 33 15 78 34 100 43 65 25 187 76 230 96 22 11 76 36 120 56 114 52
223 103 275 130 104 52 319 164 365 190 28 15 74 41 104 57 30 16 67 38 82 49
15 10 31 19 36 19 5 0 32 16 62 35 29 19 56 35 61 35 4 0 18 8 31 18 13 11 44
30 69 44 68 38 512 329 620 407 25 17 68 47 95 66 28 19 77 55 110 80 61 45
109 80 155 112 14 9 45 33 70 53 25 20 56 44 70 53 64 44 293 220 312 239 17
17 22 15 110 -32 51 -27 122 -65 158 -83 36 -19 101 -53 145 -77 67 -36 153
-81 340 -178 89 -46 587 -292 650 -322 101 -47 236 -110 310 -146 39 -19 75
-34 81 -34 6 0 35 -13 65 -30 30 -16 60 -30 67 -30 7 0 40 -13 75 -30 71 -33
441 -180 455 -180 5 0 40 -13 78 -29 38 -16 92 -35 119 -44 28 -8 77 -24 110
-36 143 -50 448 -134 570 -157 39 -7 115 -24 170 -37 55 -14 125 -28 155 -32
30 -4 109 -18 175 -31 66 -14 176 -31 245 -39 69 -8 186 -22 260 -32 109 -14
209 -17 520 -17 373 0 392 1 623 32 254 33 445 71 647 128 150 42 431 133 445
144 6 4 23 10 38 14 16 4 62 22 104 41 41 19 78 35 82 35 5 0 37 15 73 32 35
18 75 38 89 44 13 6 60 30 104 54 44 24 98 53 120 64 23 12 55 31 72 43 17 13
34 23 38 23 4 0 32 18 64 40 31 22 62 40 68 40 6 0 17 7 24 17 8 9 37 31 64
50 78 52 118 81 147 106 14 12 48 38 74 57 151 112 407 360 537 520 43 52 87
106 98 119 11 13 27 37 35 52 8 16 18 29 23 29 5 0 15 12 22 28 8 15 30 52 50
82 90 137 125 198 180 310 114 234 199 498 249 767 5 28 14 130 20 225 21 326
-20 667 -109 913 -16 44 -34 96 -40 115 -36 115 -187 406 -242 466 -10 11 -19
25 -19 31 0 6 -15 29 -32 51 -18 23 -55 71 -83 108 -76 103 -325 350 -430 426
-5 5 -29 23 -52 40 -73 58 -252 177 -265 178 -4 0 -26 13 -50 28 -60 39 -332
172 -353 172 -6 0 -30 9 -54 21 -65 31 -290 102 -451 142 -140 35 -372 76
-550 96 -147 17 -487 23 -691 12 -220 -11 -644 -69 -784 -105 -49 -13 -110
-27 -135 -31 -25 -3 -88 -19 -140 -35 -52 -15 -111 -31 -130 -35 -35 -6 -213
-61 -300 -92 -25 -8 -72 -23 -105 -33 -33 -10 -89 -29 -125 -43 -36 -14 -83
-31 -105 -37 -22 -7 -44 -16 -50 -20 -5 -4 -41 -20 -80 -35 -38 -15 -90 -37
-115 -50 -25 -13 -65 -32 -90 -44 -66 -30 -239 -123 -286 -153 -23 -15 -57
-34 -75 -42 -19 -9 -68 -39 -109 -66 -41 -27 -85 -54 -97 -59 -11 -5 -74 -44
-140 -87 -65 -42 -134 -87 -153 -99 -82 -52 -259 -169 -290 -192 -22 -17 -89
-62 -174 -119 -81 -54 -112 -75 -276 -189 -36 -25 -101 -70 -145 -100 -44 -30
-125 -87 -180 -125 -55 -39 -130 -92 -167 -117 -38 -25 -68 -50 -68 -54 0 -5
-7 -9 -15 -9 -8 0 -29 -13 -47 -28 -18 -16 -40 -32 -48 -35 -8 -4 -49 -32 -90
-62 -41 -30 -118 -84 -170 -121 -52 -36 -111 -78 -131 -92 -42 -31 -145 -103
-205 -144 -23 -15 -47 -34 -54 -43 -7 -8 -17 -15 -22 -15 -6 0 -29 -15 -51
-32 -23 -18 -58 -44 -78 -58 -114 -76 -227 -155 -246 -173 -30 -27 -71 -26
-124 3 -39 21 -143 76 -349 183 -64 33 -161 85 -290 154 -36 19 -90 48 -120
64 -142 76 -180 96 -192 106 -7 6 -24 14 -38 18 -14 4 -32 13 -40 20 -8 7 -51
32 -95 55 -44 24 -101 55 -127 69 -26 14 -102 56 -170 93 -68 37 -134 74 -148
83 -72 42 -232 132 -279 158 -30 15 -66 36 -80 46 -14 10 -39 25 -56 33 -16 8
-74 40 -127 71 -54 32 -101 57 -105 57 -3 0 -14 6 -22 14 -9 8 -41 27 -71 44
-63 34 -153 87 -191 113 -15 10 -42 25 -60 33 -19 9 -63 33 -99 55 -36 22 -87
52 -115 67 -27 15 -64 37 -81 50 -17 13 -35 24 -40 24 -5 0 -33 16 -63 35 -29
19 -56 35 -60 35 -3 0 -30 16 -59 35 -29 20 -60 39 -70 43 -9 4 -53 29 -97 55
-44 26 -89 53 -100 60 -11 6 -47 28 -81 49 -33 21 -65 38 -70 38 -5 0 -31 16
-58 35 -27 19 -54 35 -59 35 -6 0 -25 11 -43 25 -18 14 -41 29 -51 33 -18 7
-110 59 -236 134 -90 53 -184 106 -247 140 -30 17 -59 33 -65 38 -5 4 -41 25
-80 45 -38 21 -95 52 -125 68 -212 115 -406 214 -650 330 -63 30 -154 73 -202
96 -47 22 -90 41 -94 41 -5 0 -35 13 -69 30 -81 40 -243 110 -254 110 -5 0
-38 13 -73 30 -34 16 -68 30 -75 30 -6 0 -40 13 -75 29 -35 16 -83 35 -108 41
-25 7 -49 16 -55 20 -10 8 -254 94 -315 111 -19 6 -84 25 -145 44 -145 44
-282 82 -325 90 -19 4 -84 19 -145 35 -460 118 -1120 182 -1540 149z m275
-839 c36 -5 115 -14 175 -20 136 -13 395 -61 535 -99 58 -16 121 -32 140 -36
19 -4 73 -20 119 -36 46 -16 90 -29 98 -29 8 0 55 -15 106 -33 51 -18 119 -42
152 -54 33 -11 105 -37 160 -58 55 -20 116 -43 136 -50 20 -8 66 -28 103 -44
36 -17 70 -31 74 -31 4 0 41 -16 82 -35 41 -20 107 -51 145 -69 274 -128 511
-248 770 -391 125 -68 159 -87 223 -124 35 -20 77 -44 94 -53 27 -15 127 -74
168 -98 8 -6 35 -22 60 -36 25 -15 63 -39 85 -53 22 -15 74 -47 115 -72 75
-44 148 -90 205 -129 17 -12 64 -41 105 -67 41 -25 94 -59 117 -74 23 -16 44
-29 46 -29 5 0 87 -51 142 -90 17 -11 64 -42 105 -68 41 -26 84 -53 95 -60 11
-7 40 -25 64 -40 24 -15 56 -36 70 -48 14 -11 69 -46 122 -77 53 -31 102 -64
109 -72 7 -8 17 -15 23 -15 6 0 37 -18 68 -40 32 -22 60 -40 64 -40 4 0 19 -9
33 -20 37 -28 163 -110 170 -110 3 0 28 -15 56 -34 28 -18 100 -64 159 -102
60 -38 131 -84 158 -101 27 -18 51 -33 54 -33 4 0 27 -15 53 -32 26 -18 59
-40 74 -48 15 -8 59 -35 99 -60 77 -49 104 -65 201 -123 35 -21 101 -63 148
-92 47 -30 89 -54 94 -55 6 0 19 -8 30 -19 12 -10 43 -31 71 -46 27 -15 60
-36 74 -46 13 -10 28 -19 34 -19 5 0 32 -16 58 -35 27 -19 52 -35 57 -35 4 0
34 -18 67 -40 33 -22 64 -40 69 -40 5 0 24 -11 42 -25 18 -14 36 -25 41 -25 5
0 35 -18 68 -40 33 -22 63 -40 67 -40 3 0 30 -16 59 -35 30 -19 58 -35 63 -35
5 0 23 -11 40 -24 28 -21 84 -53 167 -96 16 -8 29 -17 29 -20 0 -3 -26 -21
-57 -40 -32 -18 -67 -41 -78 -50 -18 -16 -96 -63 -164 -100 -15 -9 -47 -30
-71 -48 -24 -18 -49 -32 -55 -32 -6 0 -27 -12 -46 -26 -19 -14 -54 -37 -79
-50 -25 -14 -63 -35 -85 -47 -67 -39 -176 -98 -260 -142 -45 -23 -90 -47 -100
-53 -11 -6 -44 -22 -74 -37 -30 -15 -62 -31 -70 -36 -33 -18 -292 -139 -299
-139 -4 0 -38 -16 -77 -35 -38 -19 -74 -35 -78 -35 -4 0 -41 -16 -82 -35 -41
-19 -87 -37 -102 -41 -15 -4 -50 -18 -76 -31 -26 -13 -74 -32 -105 -43 -31
-10 -102 -35 -157 -55 -176 -64 -328 -116 -395 -136 -36 -10 -96 -30 -135 -43
-64 -22 -195 -62 -385 -117 -123 -35 -327 -88 -445 -113 -60 -13 -146 -33
-190 -44 -44 -11 -105 -23 -135 -27 -30 -4 -118 -18 -194 -31 -77 -14 -207
-32 -290 -40 -83 -8 -162 -17 -176 -19 -71 -14 -406 -36 -635 -42 -272 -6
-566 6 -793 33 -70 8 -174 19 -232 25 -58 6 -152 20 -210 30 -58 11 -143 27
-190 35 -109 19 -358 78 -480 115 -52 15 -113 32 -135 38 -69 17 -297 96 -320
112 -5 3 -23 9 -40 13 -16 4 -59 20 -95 36 -36 16 -108 47 -160 70 -52 23
-102 45 -111 50 -8 4 -40 20 -70 35 -246 122 -521 287 -681 409 -15 12 -46 35
-68 51 -23 17 -49 37 -59 45 -9 8 -37 31 -61 51 -25 20 -57 47 -71 60 -15 13
-62 56 -105 94 -74 66 -102 94 -192 190 -70 74 -177 200 -212 248 -19 27 -46
63 -60 80 -14 18 -48 66 -75 107 -27 41 -61 93 -75 115 -15 22 -35 56 -45 75
-78 153 -125 249 -125 254 0 3 -14 35 -30 71 -17 36 -34 82 -39 103 -5 20 -18
61 -30 90 -12 29 -37 133 -57 230 -31 150 -37 203 -42 347 -3 104 0 206 7 260
17 128 67 334 101 421 17 41 30 79 30 84 0 5 13 31 29 58 16 27 32 59 36 71 4
12 27 55 51 94 24 40 52 85 62 100 45 75 196 254 287 340 27 26 64 61 80 76
34 32 209 175 252 206 90 64 506 295 568 315 22 7 45 16 50 20 58 42 442 119
715 144 71 6 408 -3 480 -14z m13808 -1340 c169 -13 419 -53 496 -80 33 -11
65 -20 73 -20 24 0 225 -82 274 -112 25 -15 50 -28 54 -28 12 0 164 -99 186
-121 10 -10 23 -19 28 -19 4 0 54 -45 110 -101 88 -87 132 -144 219 -284 10
-17 37 -80 59 -140 34 -90 43 -131 52 -225 14 -149 0 -317 -36 -415 -13 -38
-29 -86 -35 -105 -12 -41 -91 -193 -119 -228 -10 -14 -19 -30 -19 -37 0 -7 -7
-19 -17 -26 -9 -8 -23 -26 -32 -40 -72 -116 -331 -366 -536 -516 -35 -26 -153
-101 -193 -123 -15 -9 -45 -27 -67 -41 -37 -23 -132 -71 -260 -130 -66 -31
-155 -63 -291 -104 -61 -19 -124 -39 -140 -45 -53 -21 -371 -79 -491 -91 -153
-15 -614 -15 -768 0 -160 15 -481 65 -630 97 -66 15 -142 31 -170 36 -131 24
-657 188 -783 244 -30 13 -59 24 -64 24 -10 0 -219 82 -283 110 -22 10 -67 30
-100 43 -126 51 -172 71 -220 93 -27 13 -97 44 -155 70 -136 60 -218 98 -308
142 l-74 36 35 31 c20 16 48 40 64 52 15 12 57 45 93 73 36 28 84 65 108 83
24 17 49 38 57 45 14 12 28 23 184 143 47 35 96 74 109 87 14 12 31 22 38 22
8 0 14 4 14 9 0 4 39 37 86 72 47 35 104 79 127 96 23 18 53 41 67 52 14 11
54 42 90 70 36 27 82 63 103 78 20 15 55 42 76 58 22 17 76 57 121 90 45 33
98 72 118 87 20 15 60 43 87 63 28 20 68 50 90 66 75 55 311 213 460 307 117
74 339 181 490 237 33 12 83 31 110 42 112 46 437 153 505 167 14 3 72 17 130
30 58 13 130 27 160 31 30 3 101 13 157 21 204 31 375 38 561 24z"
          />
          <path
            stroke={primaryStrokeColor}
            strokeWidth="150"
            d="M3565 7819 c-280 -21 -617 -84 -833 -155 -57 -19 -110 -34 -118 -34
-8 0 -22 -4 -32 -10 -24 -13 -83 -37 -110 -45 -72 -21 -402 -196 -559 -297
-76 -49 -143 -91 -173 -108 -14 -8 -61 -40 -104 -70 -43 -30 -88 -62 -100 -70
-11 -8 -35 -26 -52 -40 -17 -14 -48 -38 -70 -55 -137 -105 -420 -383 -554
-545 -34 -41 -71 -86 -83 -100 -13 -14 -30 -36 -39 -50 -10 -14 -40 -56 -67
-95 -28 -38 -58 -84 -66 -102 -9 -17 -25 -39 -36 -49 -10 -9 -19 -22 -19 -28
0 -6 -11 -27 -24 -47 -20 -30 -74 -125 -107 -189 -37 -74 -63 -130 -74 -160
-7 -19 -22 -55 -33 -80 -30 -65 -92 -245 -108 -315 -7 -33 -23 -91 -34 -130
-11 -38 -33 -149 -48 -245 -25 -161 -27 -198 -26 -460 0 -257 3 -302 27 -460
14 -96 35 -209 47 -250 11 -41 25 -97 31 -125 13 -58 78 -256 121 -365 63
-161 142 -328 223 -470 24 -41 51 -88 61 -105 26 -46 180 -274 219 -325 19
-25 43 -56 52 -70 10 -14 27 -36 39 -50 13 -14 60 -69 105 -124 157 -189 409
-432 633 -611 48 -38 102 -82 119 -96 18 -14 66 -49 107 -78 41 -28 86 -60
100 -70 64 -46 122 -84 209 -136 19 -11 57 -35 84 -53 28 -19 65 -40 82 -48
16 -7 66 -34 110 -58 85 -48 241 -127 365 -184 41 -19 98 -45 125 -58 28 -13
82 -35 120 -50 39 -14 99 -37 135 -51 36 -14 99 -36 140 -50 41 -14 109 -38
150 -53 41 -15 91 -31 110 -35 19 -4 68 -18 109 -31 41 -14 97 -30 125 -35 28
-6 96 -23 151 -39 55 -15 120 -31 145 -34 25 -4 97 -17 160 -31 208 -43 521
-86 785 -107 523 -42 1277 -22 1715 46 85 13 180 27 210 30 243 28 778 147
1084 242 60 19 114 34 121 34 8 0 49 13 92 29 90 33 212 74 298 101 33 10 112
37 175 60 63 24 149 55 190 70 41 15 95 36 120 48 25 11 59 24 75 28 17 4 57
19 90 34 33 15 78 34 100 43 65 25 187 76 230 96 22 11 76 36 120 56 114 52
223 103 275 130 104 52 319 164 365 190 28 15 74 41 104 57 30 16 67 38 82 49
15 10 31 19 36 19 5 0 32 16 62 35 29 19 56 35 61 35 4 0 18 8 31 18 13 11 44
30 69 44 68 38 512 329 620 407 25 17 68 47 95 66 28 19 77 55 110 80 61 45
109 80 155 112 14 9 45 33 70 53 25 20 56 44 70 53 64 44 293 220 312 239 17
17 22 15 110 -32 51 -27 122 -65 158 -83 36 -19 101 -53 145 -77 67 -36 153
-81 340 -178 89 -46 587 -292 650 -322 101 -47 236 -110 310 -146 39 -19 75
-34 81 -34 6 0 35 -13 65 -30 30 -16 60 -30 67 -30 7 0 40 -13 75 -30 71 -33
441 -180 455 -180 5 0 40 -13 78 -29 38 -16 92 -35 119 -44 28 -8 77 -24 110
-36 143 -50 448 -134 570 -157 39 -7 115 -24 170 -37 55 -14 125 -28 155 -32
30 -4 109 -18 175 -31 66 -14 176 -31 245 -39 69 -8 186 -22 260 -32 109 -14
209 -17 520 -17 373 0 392 1 623 32 254 33 445 71 647 128 150 42 431 133 445
144 6 4 23 10 38 14 16 4 62 22 104 41 41 19 78 35 82 35 5 0 37 15 73 32 35
18 75 38 89 44 13 6 60 30 104 54 44 24 98 53 120 64 23 12 55 31 72 43 17 13
34 23 38 23 4 0 32 18 64 40 31 22 62 40 68 40 6 0 17 7 24 17 8 9 37 31 64
50 78 52 118 81 147 106 14 12 48 38 74 57 151 112 407 360 537 520 43 52 87
106 98 119 11 13 27 37 35 52 8 16 18 29 23 29 5 0 15 12 22 28 8 15 30 52 50
82 90 137 125 198 180 310 114 234 199 498 249 767 5 28 14 130 20 225 21 326
-20 667 -109 913 -16 44 -34 96 -40 115 -36 115 -187 406 -242 466 -10 11 -19
25 -19 31 0 6 -15 29 -32 51 -18 23 -55 71 -83 108 -76 103 -325 350 -430 426
-5 5 -29 23 -52 40 -73 58 -252 177 -265 178 -4 0 -26 13 -50 28 -60 39 -332
172 -353 172 -6 0 -30 9 -54 21 -65 31 -290 102 -451 142 -140 35 -372 76
-550 96 -147 17 -487 23 -691 12 -220 -11 -644 -69 -784 -105 -49 -13 -110
-27 -135 -31 -25 -3 -88 -19 -140 -35 -52 -15 -111 -31 -130 -35 -35 -6 -213
-61 -300 -92 -25 -8 -72 -23 -105 -33 -33 -10 -89 -29 -125 -43 -36 -14 -83
-31 -105 -37 -22 -7 -44 -16 -50 -20 -5 -4 -41 -20 -80 -35 -38 -15 -90 -37
-115 -50 -25 -13 -65 -32 -90 -44 -66 -30 -239 -123 -286 -153 -23 -15 -57
-34 -75 -42 -19 -9 -68 -39 -109 -66 -41 -27 -85 -54 -97 -59 -11 -5 -74 -44
-140 -87 -65 -42 -134 -87 -153 -99 -82 -52 -259 -169 -290 -192 -22 -17 -89
-62 -174 -119 -81 -54 -112 -75 -276 -189 -36 -25 -101 -70 -145 -100 -44 -30
-125 -87 -180 -125 -55 -39 -130 -92 -167 -117 -38 -25 -68 -50 -68 -54 0 -5
-7 -9 -15 -9 -8 0 -29 -13 -47 -28 -18 -16 -40 -32 -48 -35 -8 -4 -49 -32 -90
-62 -41 -30 -118 -84 -170 -121 -52 -36 -111 -78 -131 -92 -42 -31 -145 -103
-205 -144 -23 -15 -47 -34 -54 -43 -7 -8 -17 -15 -22 -15 -6 0 -29 -15 -51
-32 -23 -18 -58 -44 -78 -58 -114 -76 -227 -155 -246 -173 -30 -27 -71 -26
-124 3 -39 21 -143 76 -349 183 -64 33 -161 85 -290 154 -36 19 -90 48 -120
64 -142 76 -180 96 -192 106 -7 6 -24 14 -38 18 -14 4 -32 13 -40 20 -8 7 -51
32 -95 55 -44 24 -101 55 -127 69 -26 14 -102 56 -170 93 -68 37 -134 74 -148
83 -72 42 -232 132 -279 158 -30 15 -66 36 -80 46 -14 10 -39 25 -56 33 -16 8
-74 40 -127 71 -54 32 -101 57 -105 57 -3 0 -14 6 -22 14 -9 8 -41 27 -71 44
-63 34 -153 87 -191 113 -15 10 -42 25 -60 33 -19 9 -63 33 -99 55 -36 22 -87
52 -115 67 -27 15 -64 37 -81 50 -17 13 -35 24 -40 24 -5 0 -33 16 -63 35 -29
19 -56 35 -60 35 -3 0 -30 16 -59 35 -29 20 -60 39 -70 43 -9 4 -53 29 -97 55
-44 26 -89 53 -100 60 -11 6 -47 28 -81 49 -33 21 -65 38 -70 38 -5 0 -31 16
-58 35 -27 19 -54 35 -59 35 -6 0 -25 11 -43 25 -18 14 -41 29 -51 33 -18 7
-110 59 -236 134 -90 53 -184 106 -247 140 -30 17 -59 33 -65 38 -5 4 -41 25
-80 45 -38 21 -95 52 -125 68 -212 115 -406 214 -650 330 -63 30 -154 73 -202
96 -47 22 -90 41 -94 41 -5 0 -35 13 -69 30 -81 40 -243 110 -254 110 -5 0
-38 13 -73 30 -34 16 -68 30 -75 30 -6 0 -40 13 -75 29 -35 16 -83 35 -108 41
-25 7 -49 16 -55 20 -10 8 -254 94 -315 111 -19 6 -84 25 -145 44 -145 44
-282 82 -325 90 -19 4 -84 19 -145 35 -460 118 -1120 182 -1540 149z m275
-839 c36 -5 115 -14 175 -20 136 -13 395 -61 535 -99 58 -16 121 -32 140 -36
19 -4 73 -20 119 -36 46 -16 90 -29 98 -29 8 0 55 -15 106 -33 51 -18 119 -42
152 -54 33 -11 105 -37 160 -58 55 -20 116 -43 136 -50 20 -8 66 -28 103 -44
36 -17 70 -31 74 -31 4 0 41 -16 82 -35 41 -20 107 -51 145 -69 274 -128 511
-248 770 -391 125 -68 159 -87 223 -124 35 -20 77 -44 94 -53 27 -15 127 -74
168 -98 8 -6 35 -22 60 -36 25 -15 63 -39 85 -53 22 -15 74 -47 115 -72 75
-44 148 -90 205 -129 17 -12 64 -41 105 -67 41 -25 94 -59 117 -74 23 -16 44
-29 46 -29 5 0 87 -51 142 -90 17 -11 64 -42 105 -68 41 -26 84 -53 95 -60 11
-7 40 -25 64 -40 24 -15 56 -36 70 -48 14 -11 69 -46 122 -77 53 -31 102 -64
109 -72 7 -8 17 -15 23 -15 6 0 37 -18 68 -40 32 -22 60 -40 64 -40 4 0 19 -9
33 -20 37 -28 163 -110 170 -110 3 0 28 -15 56 -34 28 -18 100 -64 159 -102
60 -38 131 -84 158 -101 27 -18 51 -33 54 -33 4 0 27 -15 53 -32 26 -18 59
-40 74 -48 15 -8 59 -35 99 -60 77 -49 104 -65 201 -123 35 -21 101 -63 148
-92 47 -30 89 -54 94 -55 6 0 19 -8 30 -19 12 -10 43 -31 71 -46 27 -15 60
-36 74 -46 13 -10 28 -19 34 -19 5 0 32 -16 58 -35 27 -19 52 -35 57 -35 4 0
34 -18 67 -40 33 -22 64 -40 69 -40 5 0 24 -11 42 -25 18 -14 36 -25 41 -25 5
0 35 -18 68 -40 33 -22 63 -40 67 -40 3 0 30 -16 59 -35 30 -19 58 -35 63 -35
5 0 23 -11 40 -24 28 -21 84 -53 167 -96 16 -8 29 -17 29 -20 0 -3 -26 -21
-57 -40 -32 -18 -67 -41 -78 -50 -18 -16 -96 -63 -164 -100 -15 -9 -47 -30
-71 -48 -24 -18 -49 -32 -55 -32 -6 0 -27 -12 -46 -26 -19 -14 -54 -37 -79
-50 -25 -14 -63 -35 -85 -47 -67 -39 -176 -98 -260 -142 -45 -23 -90 -47 -100
-53 -11 -6 -44 -22 -74 -37 -30 -15 -62 -31 -70 -36 -33 -18 -292 -139 -299
-139 -4 0 -38 -16 -77 -35 -38 -19 -74 -35 -78 -35 -4 0 -41 -16 -82 -35 -41
-19 -87 -37 -102 -41 -15 -4 -50 -18 -76 -31 -26 -13 -74 -32 -105 -43 -31
-10 -102 -35 -157 -55 -176 -64 -328 -116 -395 -136 -36 -10 -96 -30 -135 -43
-64 -22 -195 -62 -385 -117 -123 -35 -327 -88 -445 -113 -60 -13 -146 -33
-190 -44 -44 -11 -105 -23 -135 -27 -30 -4 -118 -18 -194 -31 -77 -14 -207
-32 -290 -40 -83 -8 -162 -17 -176 -19 -71 -14 -406 -36 -635 -42 -272 -6
-566 6 -793 33 -70 8 -174 19 -232 25 -58 6 -152 20 -210 30 -58 11 -143 27
-190 35 -109 19 -358 78 -480 115 -52 15 -113 32 -135 38 -69 17 -297 96 -320
112 -5 3 -23 9 -40 13 -16 4 -59 20 -95 36 -36 16 -108 47 -160 70 -52 23
-102 45 -111 50 -8 4 -40 20 -70 35 -246 122 -521 287 -681 409 -15 12 -46 35
-68 51 -23 17 -49 37 -59 45 -9 8 -37 31 -61 51 -25 20 -57 47 -71 60 -15 13
-62 56 -105 94 -74 66 -102 94 -192 190 -70 74 -177 200 -212 248 -19 27 -46
63 -60 80 -14 18 -48 66 -75 107 -27 41 -61 93 -75 115 -15 22 -35 56 -45 75
-78 153 -125 249 -125 254 0 3 -14 35 -30 71 -17 36 -34 82 -39 103 -5 20 -18
61 -30 90 -12 29 -37 133 -57 230 -31 150 -37 203 -42 347 -3 104 0 206 7 260
17 128 67 334 101 421 17 41 30 79 30 84 0 5 13 31 29 58 16 27 32 59 36 71 4
12 27 55 51 94 24 40 52 85 62 100 45 75 196 254 287 340 27 26 64 61 80 76
34 32 209 175 252 206 90 64 506 295 568 315 22 7 45 16 50 20 58 42 442 119
715 144 71 6 408 -3 480 -14z m13808 -1340 c169 -13 419 -53 496 -80 33 -11
65 -20 73 -20 24 0 225 -82 274 -112 25 -15 50 -28 54 -28 12 0 164 -99 186
-121 10 -10 23 -19 28 -19 4 0 54 -45 110 -101 88 -87 132 -144 219 -284 10
-17 37 -80 59 -140 34 -90 43 -131 52 -225 14 -149 0 -317 -36 -415 -13 -38
-29 -86 -35 -105 -12 -41 -91 -193 -119 -228 -10 -14 -19 -30 -19 -37 0 -7 -7
-19 -17 -26 -9 -8 -23 -26 -32 -40 -72 -116 -331 -366 -536 -516 -35 -26 -153
-101 -193 -123 -15 -9 -45 -27 -67 -41 -37 -23 -132 -71 -260 -130 -66 -31
-155 -63 -291 -104 -61 -19 -124 -39 -140 -45 -53 -21 -371 -79 -491 -91 -153
-15 -614 -15 -768 0 -160 15 -481 65 -630 97 -66 15 -142 31 -170 36 -131 24
-657 188 -783 244 -30 13 -59 24 -64 24 -10 0 -219 82 -283 110 -22 10 -67 30
-100 43 -126 51 -172 71 -220 93 -27 13 -97 44 -155 70 -136 60 -218 98 -308
142 l-74 36 35 31 c20 16 48 40 64 52 15 12 57 45 93 73 36 28 84 65 108 83
24 17 49 38 57 45 14 12 28 23 184 143 47 35 96 74 109 87 14 12 31 22 38 22
8 0 14 4 14 9 0 4 39 37 86 72 47 35 104 79 127 96 23 18 53 41 67 52 14 11
54 42 90 70 36 27 82 63 103 78 20 15 55 42 76 58 22 17 76 57 121 90 45 33
98 72 118 87 20 15 60 43 87 63 28 20 68 50 90 66 75 55 311 213 460 307 117
74 339 181 490 237 33 12 83 31 110 42 112 46 437 153 505 167 14 3 72 17 130
30 58 13 130 27 160 31 30 3 101 13 157 21 204 31 375 38 561 24z"
          />
        </g>
      </svg>
    </div>
  )
}

IFLoadingLogo.propTypes = {
  primaryStrokeColor: PropTypes.string,
  secondaryStrokeColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default IFLoadingLogo
