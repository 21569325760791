import PropTypes from 'prop-types'
import React, { useState, useImperativeHandle } from 'react'
import { Colors } from 'Theme'
import styles from './IFSelectPicker.module.css'
import { IFText } from 'Components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SelectPicker } from 'rsuite'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

const IFSelectPicker = React.forwardRef(
  ({ title, data, onValueChange = () => {}, initialValue }, ref) => {
    const [isOpen, setIsOpen] = useState(false)
    const [value, setValue] = useState(initialValue)
    const clearValue = () => {
      setValue('')
    }

    const options = []
    for (const index in data) {
      options.push({
        label: data[index],
        value: data[index],
      })
    }

    useImperativeHandle(ref, () => ({
      clearValue,
      isOpen,
    }))

    return (
      <div className={styles.container}>
        <SelectPicker
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          value={value}
          onSelect={(value) => {
            setValue(value)
            onValueChange(value)
          }}
          data={options}
          cleanable={false}
          size="lg"
          searchable={false}
          placeholder={title}
          renderValue={() => (
            <div>
              <IFText>{title}</IFText>
            </div>
          )}
        />
        <div
          className={styles.TextWrapper}
          style={{
            backgroundColor: Colors.filterBackground,
          }}
        >
          <IFText className={styles.Text}>
            {/* {Replacing all spaces with &nbsp; for styling} */}
            {title.replaceAll(' ', '\u00a0')}
          </IFText>
          <FontAwesomeIcon className={styles.Icon} icon={faAngleDown} />
        </div>
      </div>
    )
  },
)

IFSelectPicker.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  onValueChange: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
}

export default IFSelectPicker
