import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Styles from './HeaderBar.module.css'
import styled from 'styled-components'
import IFText from 'Components/IFText/IFText'
import PropTypes from 'prop-types'
import Colors from 'Theme/Colors'
import React from 'react'
import { useTranslation } from 'react-i18next'

const HeaderBarContainer = styled.div`
  background-color: ${Colors.white};
  box-shadow: 0px 2px 1px 0px ${Colors.HeaderBarBoxShadow};
`

const usernameStyle = {
  display: 'flex',
  justifyContent: 'center',
  height: '2.5em',
  width: '7em',
}

const HeaderBar = ({ avatar, title, username, onLogoutClick }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { t } = useTranslation()

  const menuClickHandler = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const menuCloseHandler = () => setAnchorEl(null)

  const logoutHandler = () => {
    setAnchorEl(null)
    onLogoutClick()
  }
  return (
    <HeaderBarContainer className={Styles.HeaderBarWrappingDiv}>
      <div className={Styles.TitleWrappingDiv}>
        <IFText className={Styles.Title} style={{ color: Colors.text }}>
          {title}
        </IFText>
      </div>

      <div className={Styles.AvatarWrappingDiv} onClick={menuClickHandler}>
        {avatar}
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={menuCloseHandler}
        className={styled.Menu}
        style={{
          top: '2.8em',
          left: '0.8em',
        }}
      >
        <MenuItem
          style={usernameStyle}
          disabled={true}
          onClick={menuCloseHandler}
        >
          <IFText>{username}</IFText>
        </MenuItem>
        <MenuItem style={usernameStyle} onClick={logoutHandler}>
          <IFText style={{ color: Colors.red }}>{t('HeaderBar.logout')}</IFText>
        </MenuItem>
      </Menu>
    </HeaderBarContainer>
  )
}

HeaderBar.propTypes = {
  avatar: PropTypes.object,
  title: PropTypes.string,
  username: PropTypes.string,
  onLogoutClick: PropTypes.func,
}

export default HeaderBar
