import { INITIAL_STATE } from './initialState'
import { UserTypes } from './Actions'
import { createReducer } from 'reduxsauce'
import RequestState from 'Enums/RequestState'
import IFFIlterType from 'Enums/IFFilterType'
import history from 'history/browser'
import InfinityEnums from 'Enums/InfinityEnums'

export const fetchUserListLoading = (state) => {
  return {
    ...state,
    fetchUsersListRequestState: RequestState.LOADING,
  }
}

export const fetchUserListSuccess = (
  state,
  { offset, users, nextOffset, newUser },
) => {
  let result = []
  for (let j = 0; j < users.length; j++) {
    if (!state.users.some((user) => user.id === users[j].id)) {
      result.push(users[j])
    }
  }
  if (offset === 0) {
    // const slashArray = history.location.pathname.split('/')
    // const userIdParam = slashArray[slashArray.length - 1]
    // const userIndex = users.findIndex((user) => user.id === userIdParam)

    // if (userIdParam.match(/^[0-9a-fA-F]{24}$/)) {
    //   if (userIndex == -1) {
    //     history.push({ pathname: `/user/${newUser.id}` })
    //     return {
    //       ...state,
    //       users: [newUser, ...users],
    //       paginationOffset: nextOffset,
    //       fetchUsersListRequestState: RequestState.SUCCEEDED,
    //       selectedUserIndex: users.length !== 0 ? 0 : null,
    //     }
    //   } else {
    //     history.push({ pathname: `/user/${users[userIndex].id}` })
    //     return {
    //       ...state,
    //       users: [...users],
    //       paginationOffset: nextOffset,
    //       fetchUsersListRequestState: RequestState.SUCCEEDED,
    //       selectedUserIndex: userIndex,
    //     }
    //   }
    // } else {
    //   history.push({ pathname: `/user/${users[0].id}` })
    //   return {
    //     ...state,
    //     users: [...users],
    //     paginationOffset: nextOffset,
    //     fetchUsersListRequestState: RequestState.SUCCEEDED,
    //     selectedUserIndex: users.length !== 0 ? 0 : null,
    //   }
    // }
    if (users.length !== 0) history.push({ pathname: `/user/${users[0].id}` })

    return {
      ...state,
      users: [...users],
      paginationOffset: nextOffset,
      fetchUsersListRequestState: RequestState.SUCCEEDED,
      selectedUserIndex: users.length !== 0 ? 0 : null,
    }
  } else {
    return {
      ...state,
      users: [...state.users, ...result],
      paginationOffset: nextOffset,
      fetchUsersListRequestState: RequestState.SUCCEEDED,
    }
  }
}

export const fetchUserListError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED

  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchUserListRequestState: requestState,
  }
}

export const clearUsers = (state) => {
  return {
    ...state,
    users: [],
    paginationOffset: 0,
  }
}

export const clearCurrentUser = (state) => {
  return {
    ...state,
    currentUser: {
      id: '',
      chargingTokens: [],
      balance: 0,
      isActive: true,
      name: '',
      phone: '',
      transactions: [],
    },
    fetchUserDetailsRequestState: RequestState.LOADING,
    selectedUserIndex: null,
  }
}

export const setUsersFilter = (state, { newFilter }) => {
  return {
    ...state,
    usersFilters: newFilter,
  }
}

export const fetchUserDetailsLoading = (state) => {
  return {
    ...state,
    fetchUserDetailsRequestState: RequestState.LOADING,
  }
}

export const fetchUserDetailsSuccess = (state, { user }) => {
  return {
    ...state,
    fetchUserDetailsRequestState: RequestState.SUCCEEDED,
    currentUser: {
      ...user,
      chargingTokens: user.chargingTokens.chargingTokens,
      transactions: [],
    },
  }
}

export const fetchUserDetailsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchUserDetailsRequestState: requestState,
  }
}

export const fetchUserTransactionListLoading = (state) => {
  return {
    ...state,
    fetchUserTransactionListRequestState: RequestState.LOADING,
  }
}

export const fetchUserTransactionListSuccess = (
  state,
  { offset, transactions, nextOffset },
) => {
  if (offset === 0) {
    return {
      ...state,
      fetchUserTransactionListRequestState: RequestState.SUCCEEDED,
      transactionListPaginationOffset: nextOffset,
      currentUser: {
        ...state.currentUser,
        transactions: [...transactions],
      },
    }
  } else {
    return {
      ...state,
      fetchUserTransactionListRequestState: RequestState.SUCCEEDED,
      transactionListPaginationOffset: nextOffset,
      currentUser: {
        ...state.currentUser,
        transactions: [...state.currentUser.transactions, ...transactions],
      },
    }
  }
}

export const fetchUserTransactionListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchUserTransactionListRequestState: requestState,
  }
}

export const clearTransactions = (state) => {
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      transactions: [],
    },
    transactionListPaginationOffset: 0,
  }
}

export const setUserTransactionsFilter = (state, { newFilter }) => {
  return {
    ...state,
    transactionsFilters: newFilter,
  }
}

export const clearUserTransactionsFilter = (state) => {
  return {
    ...state,
    transactionsFilters: [
      {
        type: IFFIlterType.KEYWORD,
        title: null,
        data: null,
        initialValue: [],
        value: [],
      },
      {
        type: IFFIlterType.CHECK,
        title: 'Type',
        data: ['addMoney', 'charge', 'refund'],
        initialValue: [],
        value: [],
      },
      {
        type: IFFIlterType.DATE_RANGE,
        title: 'Date',
        initialValue: [],
        value: [],
      },
    ],
  }
}

export const fetchUserPaymentSessionListLoading = (state) => {
  return {
    ...state,
    fetchPaymentSessionListRequestState: RequestState.LOADING,
  }
}

export const fetchUserPaymentSessionListSuccess = (
  state,
  { offset, paymentSessions, nextOffset },
) => {
  if (offset === 0)
    return {
      ...state,
      paymentSessionListPaginationOffset: nextOffset,
      fetchPaymentSessionListRequestState: RequestState.SUCCEEDED,
      currentUser: {
        ...state.currentUser,
        paymentSessions: [...paymentSessions],
      },
    }
  else
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        paymentSessions: [
          ...state.currentUser.paymentSessions,
          ...paymentSessions,
        ],
      },
      paymentSessionListPaginationOffset: nextOffset,
      fetchPaymentSessionListRequestState: RequestState.SUCCEEDED,
    }
}

export const fetchUserPaymentSessionListError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchPaymentSessionListRequestState: requestState,
  }
}

export const clearPaymentSessions = (state) => {
  return {
    ...state,
    paymentSessions: [],
    paginationOffset: 0,
  }
}

export const setCurrentUserChargingTokenDeactiveLoading = (
  state,
  { index },
) => {
  state.currentUser.chargingTokens[index].isLoading = true
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      chargingTokens: [...state.currentUser.chargingTokens],
    },
  }
}

export const setCurrentUserChargingTokenDeactiveSuccess = (
  state,
  { index },
) => {
  let updatedCurrentUser = state.currentUser
  updatedCurrentUser.chargingTokens[index].isLoading = false
  updatedCurrentUser.chargingTokens[index].status =
    InfinityEnums.AppChargingTokenStatus.DEACTIVATED
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      chargingTokens: [...updatedCurrentUser.chargingTokens],
    },
  }
}

export const setCurrentUserChargingTokenDeactiveFail = (state, { index }) => {
  let updatedCurrentUser = state.currentUser
  updatedCurrentUser.chargingTokens[index].isLoading = false
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      chargingTokens: [...state.currentUser.chargingTokens],
    },
  }
}

export const setUserSortingAsc = (state, { isAsc }) => {
  return {
    ...state,
    userSortingAsc: isAsc,
  }
}

export const setSelectedUserIndex = (state, { index }) => {
  return {
    ...state,
    selectedUserIndex: index,
  }
}

export const setCurrentUserTransactionRefundLoading = (state, { index }) => {
  state.currentUser.transactions[index].isLoading = true
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      transactions: [...state.currentUser.transactions],
    },
  }
}
export const setCurrentUserTransactionRefundSuccess = (state, { index }) => {
  let updatedCurrentUser = state.currentUser
  updatedCurrentUser.transactions[index].isLoading = false
  updatedCurrentUser.transactions[index].isRefunded = true
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      transactions: [...state.currentUser.transactions],
    },
  }
}

export const setCurrentUserTransactionRefundFail = (state, { index }) => {
  let updatedCurrentUser = state.currentUser
  updatedCurrentUser.transactions[index].isLoading = false
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      transactions: [...state.currentUser.transactions],
    },
  }
}

export const setCurrentUserNewBalanceSuccess = (state, { transaction }) => {
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      balance: transaction.isCredit
        ? state.currentUser.balance + transaction.amount
        : state.currentUser.balance - transaction.amount,
      isNewBalanceLoading: false,
    },
  }
}

export const setCurrentUserNewBalanceLoading = (state) => {
  return {
    ...state,
    currentUser: { ...state.currentUser, isNewBalanceLoading: true },
  }
}

export const setCurrentUserNewBalanceFail = (state) => {
  return {
    ...state,
    currentUser: { ...state.currentUser, isNewBalanceLoading: false },
  }
}

export const addCurrentUserNewCardSuccess = (state, { chargingToken }) => {
  let newChargingTokens = state.currentUser.chargingTokens
  newChargingTokens.push(chargingToken)
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      chargingTokens: [...state.currentUser.chargingTokens],
      isNewCardLoading: false,
    },
  }
}

export const addCurrentUserNewCardLoading = (state) => {
  return {
    ...state,
    currentUser: { ...state.currentUser, isNewCardLoading: true },
  }
}

export const addCurrentUserNewCardFail = (state) => {
  return {
    ...state,
    currentUser: { ...state.currentUser, isNewCardLoading: false },
  }
}

export const toggleCurrentUserIsActiveSuccess = (
  state,
  { id, setIsActive },
) => {
  for (let i = 0; i < state.users.length; i++) {
    if (state.users[i].id === id) {
      state.users[i].isActive = setIsActive
    }
  }

  return {
    ...state,
    users: [...state.users],
    currentUser: {
      ...state.currentUser,
      isActive:
        state.currentUser.id === id ? setIsActive : state.currentUser.isActive,
      isToggleIsActiveLoading: false,
    },
  }
}

export const toggleCurrentUserIsActiveLoading = (state) => {
  return {
    ...state,
    currentUser: { ...state.currentUser, isToggleIsActiveLoading: true },
  }
}

export const toggleCurrentUserIsActiveFail = (state) => {
  return {
    ...state,
    currentUser: { ...state.currentUser, isToggleIsActiveLoading: false },
  }
}

export const setCurrentUserNewPhoneLoading = (state) => {
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      changePhoneRequestState: RequestState.LOADING,
    },
  }
}

export const setCurrentUserNewPhoneFail = (state, { id }) => {
  for (let i = 0; i < state.users.length; i++) {
    if (state.users[i].id === id) {
      state.users[i].changePhoneRequestState = RequestState.ERROR_400_OCCURRED
    }
  }
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      changePhoneRequestState:
        state.currentUser.id === id
          ? RequestState.ERROR_400_OCCURRED
          : state.currentUser.changePhoneRequestState,
    },
  }
}

export const setCurrentUserNewPhoneSuccess = (state, { id, newPhone }) => {
  for (let i = 0; i < state.users.length; i++) {
    if (state.users[i].id === id) {
      state.users[i].phone = newPhone
      state.users[i].changePhoneRequestState = RequestState.SUCCEEDED
    }
  }
  return {
    ...state,
    users: [...state.users],
    currentUser: {
      ...state.currentUser,
      phone: state.currentUser.id === id ? newPhone : state.currentUser.phone,
      changePhoneRequestState:
        state.currentUser.id === id
          ? RequestState.SUCCEEDED
          : state.currentUser.changePhoneRequestState,
    },
  }
}

export const reverseUserChargeTransactionLoading = (state, { index }) => {
  state.currentUser.transactions[index].isReverseChargeTransactionLoading = true
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      transactions: [...state.currentUser.transactions],
    },
    reverseChargeTransactionRequestState: RequestState.LOADING,
  }
}

export const reverseUserChargeTransactionSuccess = (state, { index }) => {
  let updatedCurrentUser = state.currentUser
  updatedCurrentUser.transactions[
    index
  ].isReverseChargeTransactionLoading = false
  updatedCurrentUser.transactions[index].status =
    InfinityEnums.TransactionStatus.VOID
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      transactions: [...state.currentUser.transactions],
    },
    reverseChargeTransactionRequestState: RequestState.SUCCEEDED,
  }
}

export const reverseUserChargeTransactionError = (state, { index, error }) => {
  let updatedCurrentUser = state.currentUser
  updatedCurrentUser.transactions[
    index
  ].isReverseChargeTransactionLoading = false

  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      transactions: [...state.currentUser.transactions],
    },
    reverseChargeTransactionRequestState: requestState,
  }
}

export const fetchUserPointsLoading = (state) => {
  return {
    ...state,
    fetchUserPointsRequestState: RequestState.LOADING,
  }
}

export const fetchUserPointsError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED

  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchUserPointsRequestState: requestState,
  }
}

export const fetchUserPointsSuccess = (
  state,
  { offset, points, nextOffset },
) => {
  if (offset === 0) {
    return {
      ...state,
      fetchUserPointsListRequestState: RequestState.SUCCEEDED,
      pointsListPaginationOffset: nextOffset,
      points: [...points],
    }
  } else {
    return {
      ...state,
      fetchUserPointsListRequestState: RequestState.SUCCEEDED,
      pointsListPaginationOffset: nextOffset,
      points: [...state.points, ...points],
    }
  }
}

export const clearPoints = (state) => {
  return {
    ...state,
    points: [],
    pointsListPaginationOffset: 0,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [UserTypes.FETCH_USER_LIST_LOADING]: fetchUserListLoading,
  [UserTypes.FETCH_USER_LIST_SUCCESS]: fetchUserListSuccess,
  [UserTypes.FETCH_USER_LIST_ERROR]: fetchUserListError,
  [UserTypes.CLEAR_USERS]: clearUsers,
  [UserTypes.SET_USERS_FILTER]: setUsersFilter,
  [UserTypes.SET_CURRENT_USER_CHARGING_TOKEN_DEACTIVE_LOADING]:
    setCurrentUserChargingTokenDeactiveLoading,
  [UserTypes.SET_CURRENT_USER_CHARGING_TOKEN_DEACTIVE_SUCCESS]:
    setCurrentUserChargingTokenDeactiveSuccess,
  [UserTypes.SET_CURRENT_USER_CHARGING_TOKEN_DEACTIVE_FAIL]:
    setCurrentUserChargingTokenDeactiveFail,
  [UserTypes.SET_USER_SORTING_ASC]: setUserSortingAsc,
  [UserTypes.SET_SELECTED_USER_INDEX]: setSelectedUserIndex,
  [UserTypes.SET_CURRENT_USER_TRANSACTION_REFUND_LOADING]:
    setCurrentUserTransactionRefundLoading,
  [UserTypes.SET_CURRENT_USER_TRANSACTION_REFUND_SUCCESS]:
    setCurrentUserTransactionRefundSuccess,
  [UserTypes.SET_CURRENT_USER_TRANSACTION_REFUND_FAIL]:
    setCurrentUserTransactionRefundFail,
  [UserTypes.SET_CURRENT_USER_NEW_BALANCE_SUCCESS]:
    setCurrentUserNewBalanceSuccess,
  [UserTypes.SET_CURRENT_USER_NEW_BALANCE_LOADING]:
    setCurrentUserNewBalanceLoading,
  [UserTypes.SET_CURRENT_USER_NEW_BALANCE_FAIL]: setCurrentUserNewBalanceFail,
  [UserTypes.ADD_CURRENT_USER_NEW_CARD_LOADING]: addCurrentUserNewCardLoading,
  [UserTypes.ADD_CURRENT_USER_NEW_CARD_SUCCESS]: addCurrentUserNewCardSuccess,
  [UserTypes.ADD_CURRENT_USER_NEW_CARD_FAIL]: addCurrentUserNewCardFail,
  [UserTypes.TOGGLE_CURRENT_USER_IS_ACTIVE_LOADING]:
    toggleCurrentUserIsActiveLoading,
  [UserTypes.TOGGLE_CURRENT_USER_IS_ACTIVE_FAIL]: toggleCurrentUserIsActiveFail,
  [UserTypes.TOGGLE_CURRENT_USER_IS_ACTIVE_SUCCESS]:
    toggleCurrentUserIsActiveSuccess,
  [UserTypes.FETCH_USER_DETAILS_LOADING]: fetchUserDetailsLoading,
  [UserTypes.FETCH_USER_DETAILS_SUCCESS]: fetchUserDetailsSuccess,
  [UserTypes.FETCH_USER_DETAILS_FAIL]: fetchUserDetailsFail,
  [UserTypes.FETCH_USER_TRANSACTION_LIST_LOADING]:
    fetchUserTransactionListLoading,
  [UserTypes.FETCH_USER_TRANSACTION_LIST_SUCCESS]:
    fetchUserTransactionListSuccess,
  [UserTypes.FETCH_USER_TRANSACTION_LIST_FAIL]: fetchUserTransactionListFail,
  [UserTypes.CLEAR_TRANSACTIONS]: clearTransactions,
  [UserTypes.SET_USER_TRANSACTIONS_FILTER]: setUserTransactionsFilter,
  [UserTypes.FETCH_USER_PAYMENT_SESSION_LIST_LOADING]:
    fetchUserPaymentSessionListLoading,
  [UserTypes.FETCH_USER_PAYMENT_SESSION_LIST_SUCCESS]:
    fetchUserPaymentSessionListSuccess,
  [UserTypes.FETCH_USER_PAYMENT_SESSION_LIST_ERROR]:
    fetchUserPaymentSessionListError,
  [UserTypes.CLEAR_PAYMENT_SESSIONS]: clearPaymentSessions,
  [UserTypes.CLEAR_CURRENT_USER]: clearCurrentUser,
  [UserTypes.CLEAR_USER_TRANSACTIONS_FILTER]: clearUserTransactionsFilter,
  [UserTypes.SET_CURRENT_USER_NEW_PHONE_LOADING]: setCurrentUserNewPhoneLoading,
  [UserTypes.SET_CURRENT_USER_NEW_PHONE_SUCCESS]: setCurrentUserNewPhoneSuccess,
  [UserTypes.SET_CURRENT_USER_NEW_PHONE_FAIL]: setCurrentUserNewPhoneFail,
  [UserTypes.REVERSE_USER_CHARGE_TRANSACTION_LOADING]:
    reverseUserChargeTransactionLoading,
  [UserTypes.REVERSE_USER_CHARGE_TRANSACTION_SUCCESS]:
    reverseUserChargeTransactionSuccess,
  [UserTypes.REVERSE_USER_CHARGE_TRANSACTION_ERROR]:
    reverseUserChargeTransactionError,
  [UserTypes.FETCH_USER_POINTS_ERROR]: fetchUserPointsError,
  [UserTypes.FETCH_USER_POINTS_LOADING]: fetchUserPointsLoading,
  [UserTypes.FETCH_USER_POINTS_SUCCESS]: fetchUserPointsSuccess,
  [UserTypes.CLEAR_POINTS]: clearPoints,
})
