const getNotificationGroups = (state) => {
  return state.main.notificationState.notificationGroups
}

const getFetchNotificationGroupRequestState = (state) => {
  return state.main.notificationState.fetchNotificationGroupRequestState
}

const getSendNotificationRequestState = (state) => {
  return state.main.notificationState.sendNotificationRequestState
}

const NotificationSelectors = {
  getNotificationGroups,
  getFetchNotificationGroupRequestState,
  getSendNotificationRequestState,
}

export default NotificationSelectors
