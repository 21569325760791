import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt } from '@fortawesome/free-solid-svg-icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styles from './ChargingTransactionListItem.module.css'
import PropTypes from 'prop-types'
import { IFText, IFButton } from 'Components'
import Colors from 'Theme/Colors'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfinityEnums from 'Enums/InfinityEnums'

const useStyles = makeStyles((theme) => ({
  Container: {
    color: Colors.ChargingTransactionListItemShadow,
    width: '100%',
    marginTop: '0.5em',
    borderRadius: '5px',
  },
  Accordion: {
    boxShadow: '0px 0px 0px 0px' + Colors.ChargingTransactionListItemShadow,
    '&:hover': {
      boxShadow: '0px 0px 2px 3px' + Colors.ChargingTransactionListItemShadow,
    },
  },
  AccordionDetails: {
    alignItems: 'center !important',
  },
}))

const formatDate = (dateTimeString) => {
  return moment(dateTimeString).format('MMM DD, YYYY HH:mm')
}
const getTimeBetweenDates = (firstDate, secondDate) => {
  var firstMomentDate = moment(firstDate)
  var secondMomentDate = moment(secondDate)
  var chargingTimeInMinutes = secondMomentDate.diff(firstMomentDate, 'minutes')
  return (
    Math.floor(chargingTimeInMinutes / 60) +
    ' Hours' +
    ' ' +
    (chargingTimeInMinutes % 60) +
    ' Minutes'
  )
}

const ChargingTransactionListItem = ({
  chargingSession,
  onReverseClick,
  canViewReverseTransaction,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  let amountExists = chargingSession.amount === '0' ? false : true
  let pointsExists =
    chargingSession.pointsAmount === '0' ||
    chargingSession.pointsAmount === undefined
      ? false
      : true
  let amountText =
    (chargingSession.isCredit ? '+EGP ' : '-EGP ') +
    Number(chargingSession.amount).toFixed(3)
  let pointsText =
    (chargingSession.isCredit ? '+PTS ' : '-PTS ') +
    +Intl.NumberFormat('en-US', 2).format(chargingSession.pointsAmount)

  return (
    <div className={classes.Container}>
      <Accordion className={classes.Accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={styles.Icon}>
            <FontAwesomeIcon
              icon={faBolt}
              style={{
                color: Colors.ChargingTokenListItemIcon,
                fontSize: '1.25em',
              }}
            />
          </div>
          <IFText
            className={styles.Station}
            style={{ color: Colors.ChargingTokenListItemId }}
          >
            {chargingSession.details.station.address.streetName}
          </IFText>
          <IFText
            className={styles.Amount}
            style={{ color: Colors.ChargingTokenListItemDate }}
          >
            {amountExists && !pointsExists ? amountText : ''}
            {pointsExists && amountExists
              ? amountText + ' | ' + pointsText
              : ''}
            {pointsExists && !amountExists ? pointsText : ''}
          </IFText>
          <IFText className={styles.Date}>
            {formatDate(chargingSession.details.startedAt)}
          </IFText>
        </AccordionSummary>
        <AccordionDetails className={classes.AccordionDetails}>
          <div className={styles.AccordionWrapper}>
            <div className={styles.TopDetailsContainer}>
              <div className={styles.ChargingDetails}>
                <IFText className={styles.ChargingTimeText}>
                  {t('ChargingTransactionListItem.duration')}&nbsp;
                </IFText>
                <IFText className={styles.ChargingTime}>
                  {getTimeBetweenDates(
                    chargingSession.details.startedAt,
                    chargingSession.details.endedAt,
                  )}
                </IFText>
              </div>

              <div className={styles.UidContainer}>
                <IFText className={styles.ChargingTimeText}>
                  {t('ChargingTransactionListItem.uid')}&nbsp;
                </IFText>
                <IFText className={styles.ChargingTime}>
                  {chargingSession.details.station.code}
                </IFText>
              </div>
            </div>
            <div className={styles.BottomDetailsContainer}>
              {canViewReverseTransaction && (
                <IFButton
                  color={Colors.primary}
                  text={
                    chargingSession.status ===
                      InfinityEnums.TransactionStatus.NORMAL ||
                    chargingSession.status ===
                      InfinityEnums.TransactionStatus.CORRECTED
                      ? t('ReverseTransactionModal.ReverseButtonText')
                      : t('ReverseTransactionModal.ReversedButtonText')
                  }
                  isLoading={false}
                  isDead={
                    chargingSession.status ===
                      InfinityEnums.TransactionStatus.VOID ||
                    chargingSession.status ===
                      InfinityEnums.TransactionStatus.REVERSED
                  }
                  isFill={false}
                  onClick={() => {
                    onReverseClick(chargingSession.id)
                  }}
                  className={styles.ReverseButton}
                />
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

ChargingTransactionListItem.propTypes = {
  chargingSession: PropTypes.object,
}

export default ChargingTransactionListItem
