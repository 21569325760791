import PropTypes from 'prop-types'
import Styles from './IFTextInput.module.css'
import React, { useState } from 'react'
import colors from 'Theme/Colors'
import IFText from 'Components/IFText/IFText'

const IFTextInput = ({
  children,
  className,
  style,
  isFixed,
  errorText,
  value = '',
  maxWidth,
  fontSize = 14,
  onBlur = () => {},
  ...props
}) => {
  const getTextWidth = (txt, font) => {
    let element = document.createElement('canvas')
    let context = element.getContext('2d')
    context.font = font
    return context.measureText(txt).width + 16
  }
  const [isFocused, setIsFocused] = useState(false)

  return (
    <div className={Styles.inputContainer}>
      <input
        {...props}
        value={value}
        onFocus={() => setIsFocused(true)}
        onBlur={(e) => {
          setIsFocused(false)
          onBlur(e)
        }}
        style={
          isFixed
            ? {
                ...style,
                borderColor: errorText
                  ? colors.red
                  : isFocused
                  ? colors.primary
                  : colors.transparent,
                fontSize: fontSize,
                lineHeight: 1,
              }
            : {
                ...style,
                width: getTextWidth(value, `${fontSize}px ProximaNova`),
                maxWidth: maxWidth,
                borderColor: errorText
                  ? colors.red
                  : isFocused
                  ? colors.primary
                  : colors.transparent,
                fontSize: fontSize,
                lineHeight: 1,
              }
        }
        className={`${errorText ? Styles.error : Styles.input} ${className}`}
      />
      <div className={Styles.errorContainer}>
        {errorText ? (
          <IFText className={Styles.errorText} style={{ color: colors.red }}>
            {errorText}
          </IFText>
        ) : null}
      </div>
    </div>
  )
}

IFTextInput.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  isFixed: PropTypes.bool,
  value: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  fontSize: PropTypes.number,
}

export default IFTextInput
