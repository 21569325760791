/**
 * This file contains the application's colors.
 *
 * Define color here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

const colors = {
  //common
  text: '#000000',
  primary: '#27a3ac',
  primaryLight: '#CEDADB',
  success: '#28a745',
  error: '#dc3545',
  white: '#FFFFFF',
  black: '#000000',
  red: '#FF0000',
  green: '#008000',
  shadow: '#00287A',
  grey: '#e9e9e9',
  darkGrey: '#5A5A5A',

  //User List Item
  UserListItemSelectedBackground: '#d0f0f0',
  UserListItemNotSelectedBackground: '#ffffff',
  UserListItemActive: '#ffffff',
  UserListItemInactive: '#f74338',
  UserListItemHoverBackground: '#27a3ac1A',

  UserListItemPhoneAndLastSeen: '#00000099',

  //User List
  UserListTopBarBackground: '#f4fafb',
  ifIndicatorSecondary: '#F0F0F0',
  UserListBackground: '#ffffff',
  UserListHeaderText: '#000000',

  //Charging Tokens List Item
  ChargingTokenListItemIcon: '#000000',
  ChargingTokenListItemId: '#000000',
  ChargingTokenListItemDate: '#000000',
  ChargingTokenListItemBackgroundColor: '#FFFFF',
  ChargingTokenListItemShadow: '#0000001a',

  //Admin List Item
  AdminListItemEmail: '#00000099',
  AdminListPenIcon: '#818181',
  AdminListDeleteIcon: '#ff0000',

  //Admin List
  AdminListDeleteModalCancel: '#27a3ac',
  AdminListDeleteModalDelete: '#FF0000',

  //Fund Transactions List Item
  FundTransactionListItemIcon: '#000000',
  FundTransactionListItemId: '#000000',
  FundTransactionListItemDate: '#000000',
  FundTransactionListItemAmount: '#000000',
  FundTransactionListItemBackgroundColor: '#FFFFF',
  FundTransactionListItemShadow: '#0000001a',

  //Charging Transaction List Item
  ChargingTransactionListItemShadow: '#0000001a',

  //filter
  FilterShadow: '#0000002f',
  InputsFilterBackground: '#27a3ac7a',
  RedCircle: '#ff0000Af',
  BlueCircle: '#0000ffAf',
  FilterClearButton: '#E1E1E1ff',
  FuilterSaveButton: '#158073ff',
  textFieldBoxShadow: '#ffffff00',
  verticalDivider: '#ffffff91',
  filterBackground: '#e8f6f7',
  filterIconEmpty: '#808080',
  filterIconFilled: '#000000',
  // Admin List Item
  AdminListItemShadow: '#0000001a',

  // Side Navigation Bar
  LogoBackground: '#3dacb4',
  NavigationBarBackground: '#27a3ac',
  IconHover: '#52b5bd',

  // Header Bar
  HeaderBarBoxShadow: '#00000020',

  // Login page
  ForgotPassword: '#1672EC',
  LoginBackground: '#dce5e6',

  //UserPage
  ActionsBorder: '#27a3ac55',
  UserPageCancel: '#27a3ac',
  UserPageDeactivateButton: '#ff0000',
  UserPageActivateButton: '#27a3ac',
  UserPageTextColor: '#000000',
  UserPageBackground: '#ffffff',
  UserPageBackgroundContainer: '#e9e9e9',
  UserPagePrimary: '#27a3ac',
  PhoneEditIcon: 'b3b3b3',
  ProgressBar: '#e0e0de',

  //Payment Session List Item
  PaymentSessionListItemShadow: '#0000001a',

  // ContactUsFormListItem
  GreyBackground: '#80808033',

  // Transactions Page
  DividerColor: '#0000001a',

  //IFToastMessage
  ToastInfo: '#2196f3',
  ToastSuccess: '#4caf50',
  ToastError: '#f44336',
  ToastWarning: '#ffb300',
  HexTransparent: {
    T100: 'FF',
    T95: 'F2',
    T90: 'E6',
    T85: 'D9',
    T80: 'CC',
    T75: 'BF',
    T70: 'B3',
    T65: 'A6',
    T60: '99',
    T55: '8C',
    T50: '80',
    T45: '73',
    T40: '66',
    T35: '59',
    T30: '4D',
    T25: '40',
    T20: '33',
    T15: '26',
    T10: '1A',
    T5: '0D',
    T0: '00',
  },
}

export default colors
