const getContactUsFormList = (state) => {
  return state.main.contactUsFormState.contactUsForms
}

const getfetchContactUsFormListRequestState = (state) => {
  return state.main.contactUsFormState.fetchContactUsFormListRequestState
}

const getfetchUpdateContactUsFormStatusRequestState = (state) => {
  return state.main.contactUsFormState
    .fetchUpdateContactUsFormStatusRequestState
}

const getpaginationOffset = (state) => {
  return state.main.contactUsFormState.paginationOffset
}

const getContactUsFormsFilter = (state) => {
  return state.main.contactUsFormState.contactUsFormsFilter
}

const ContactUsFormSelectors = {
  getContactUsFormList,
  getfetchContactUsFormListRequestState,
  getfetchUpdateContactUsFormStatusRequestState,
  getpaginationOffset,
  getContactUsFormsFilter,
}

export default ContactUsFormSelectors
