import React, { useState, useRef, createRef } from 'react'
import { connect } from 'react-redux'
import AdminSelectors from 'Stores/Admin/Selectors'
import AdminActions from 'Stores/Admin/Actions'
import { AdminListItem, IFAvatar, IFText } from 'Components'
import { Colors, Icons } from 'Theme'
import Styles from './AdminList.module.css'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import IFDialog from 'Components/IFDialog/IFDialog'

const AdminList = ({ admins, roles, updateAdminRole, deleteAdmin }) => {
  const { t } = useTranslation()

  const [deleteAdminId, setDeleteAdminId] = useState()
  const confirmDeleteDialog = useRef()

  const adminsArrayLength = admins.length
  const adminListItemsRefs = React.useRef([])

  if (adminListItemsRefs.current.length !== adminsArrayLength) {
    adminListItemsRefs.current = Array(adminsArrayLength)
      .fill()
      .map((_, i) => adminListItemsRefs.current[i] || createRef())
  }

  const handleConfirmDelete = () => {
    deleteAdmin(deleteAdminId)
  }

  const onDeleteClickHandler = (adminId) => {
    setDeleteAdminId(adminId)
    confirmDeleteDialog.current.show()
  }

  return (
    <div>
      {admins.length === 0 ? (
        <div className={Styles.EmptySet}>
          <img src={Icons.emptySet.default} alt="" />
          <IFText className={Styles.ModalText}>{t('AdminList.empty')}</IFText>
        </div>
      ) : (
        admins.map((admin, index) => {
          return (
            <AdminListItem
              ref={adminListItemsRefs.current[index]}
              key={`adminListItem ${index}`}
              avatar={
                <IFAvatar
                  imageURL={admin.avatarURL}
                  name={admin.name}
                  size={48}
                ></IFAvatar>
              }
              admin={admin}
              onDeleteClick={() => {
                onDeleteClickHandler(admin.id)
              }}
              onUpdateClick={(newRoleValue) => {
                updateAdminRole(
                  admin.id,
                  newRoleValue,
                  adminListItemsRefs.current[index],
                )
              }}
              isUpdateLoading={admin.isBeingUpdated}
              isDeleteLoading={admin.isBeingDeleted}
              roles={roles}
            />
          )
        })
      )}

      <IFDialog
        ref={confirmDeleteDialog}
        style={{ margin: '15em' }}
        open={false}
        title={t('AdminList.deleteModalTitle')}
        bodyText={t('AdminList.deleteModalText')}
        buttonAcceptonClick={handleConfirmDelete}
        buttonCancelColor={Colors.AdminListDeleteModalCancel}
        buttonAcceptColor={Colors.AdminListDeleteModalDelete}
        buttonAcceptText={t('AdminList.deleteModalConfirm')}
        buttonClassName={Styles.Button}
      />
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    updateAdminRole: (index, newRoleValue, adminListItemRef) =>
      dispatch(
        AdminActions.updateAdminRole(index, newRoleValue, adminListItemRef),
      ),

    deleteAdmin: (index) => dispatch(AdminActions.deleteAdmin(index)),
  }
}

const mapStateToProps = (state) => ({
  roles: AdminSelectors.getAdminRoles(state),
  admins: AdminSelectors.getAdminList(state),
})

AdminList.propTypes = {
  admins: PropTypes.arrayOf(PropTypes.object),
  roles: PropTypes.arrayOf(PropTypes.object),
  updateAdminRole: PropTypes.func,
  deleteAdmin: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminList)
