import styled from 'styled-components'
import Colors from 'Theme/Colors'
import React from 'react'
import Styles from './UserPageTabsSkeleton.module.css'
import { IFSkeleton } from 'Components'
const Tab = styled.div`
  border-bottom: 1px solid ${Colors.skeleton};
`

const UserPageTabsSkeleton = ({ tabsCount = 2 }) => {
  return (
    <div className={Styles.container}>
      {[...Array(tabsCount)].map(() => (
        <Tab className={Styles.tab}>
          <IFSkeleton className={Styles.text} />
        </Tab>
      ))}
    </div>
  )
}

export default UserPageTabsSkeleton
