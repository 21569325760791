const isEqual = require('react-fast-compare')

export function isPropsMatch(prevProps, nextProps, props) {
  for (let prop of props) {
    if (!isEqual(prevProps[prop], nextProps[prop])) {
      return false
    }
  }
  return true
}
