import * as React from 'react'
import Skeleton from '@mui/material/Skeleton'
import { isPropsMatch } from 'Utils/PropsMatch'

const IFSkeleton = ({ animation = 'wave', ...props }) => {
  return <Skeleton animation={false} {...props} />
}

function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, [
    'variant',
    'width',
    'height',
    'className',
    'style',
  ])
}

export default React.memo(IFSkeleton, shouldSkipRender)
