import { INITIAL_STATE } from './initialState'
import { ContactUsFormTypes } from './Actions'
import { createReducer } from 'reduxsauce'
import RequestState from 'Enums/RequestState'

export const fetchContactUsFormListLoading = (state) => {
  return {
    ...state,
    fetchContactUsFormListRequestState: RequestState.LOADING,
  }
}

export const fetchContactUsFormListSuccess = (
  state,
  { offset, contactUsForms, nextOffset },
) => {
  let result = []
  for (let j = 0; j < contactUsForms.length; j++) {
    if (
      !state.contactUsForms.some(
        (contactUsForm) => contactUsForm.id === contactUsForms[j].id,
      )
    ) {
      result.push(contactUsForms[j])
    }
  }
  if (offset === 0)
    return {
      ...state,
      contactUsForms: [...contactUsForms],
      paginationOffset: nextOffset,
      fetchContactUsFormListRequestState: RequestState.SUCCEEDED,
    }
  else
    return {
      ...state,
      contactUsForms: [...state.contactUsForms, ...result],
      paginationOffset: nextOffset,
      fetchContactUsFormListRequestState: RequestState.SUCCEEDED,
    }
}

export const fetchContactUsFormListError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchContactUsFormListRequestState: requestState,
  }
}

export const clearContactUsForms = (state) => {
  return {
    ...state,
    contactUsForms: [],
    paginationOffset: 0,
  }
}

export const setContactUsFormsFilter = (state, { newFilter }) => {
  return {
    ...state,
    contactUsFormsFilter: newFilter,
  }
}

export const updateContactUsFormStatusLoading = (state) => {
  return {
    ...state,
    fetchUpdateContactUsFormStatusRequestState: RequestState.LOADING,
  }
}

export const updateContactUsFormStatusSuccess = (state, { contactUsForm }) => {
  state.contactUsForms.forEach((oldContactUsForms) => {
    if (oldContactUsForms.id === contactUsForm.id) {
      oldContactUsForms.status = contactUsForm.status
    }
  })

  return {
    ...state,
    fetchUpdateContactUsFormStatusRequestState: RequestState.SUCCEEDED,
    contactUsForms: [...state.contactUsForms],
  }
}

export const updateContactUsFormStatusFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchUpdateContactUsFormStatusRequestState: requestState,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [ContactUsFormTypes.FETCH_CONTACT_US_FORM_LIST_LOADING]:
    fetchContactUsFormListLoading,
  [ContactUsFormTypes.FETCH_CONTACT_US_FORM_LIST_SUCCESS]:
    fetchContactUsFormListSuccess,
  [ContactUsFormTypes.FETCH_CONTACT_US_FORM_LIST_ERROR]:
    fetchContactUsFormListError,
  [ContactUsFormTypes.UPDATE_CONTACT_US_FORM_STATUS_LOADING]:
    updateContactUsFormStatusLoading,
  [ContactUsFormTypes.UPDATE_CONTACT_US_FORM_STATUS_SUCCESS]:
    updateContactUsFormStatusSuccess,
  [ContactUsFormTypes.UPDATE_CONTACT_US_FORM_STATUS_FAIL]:
    updateContactUsFormStatusFail,
  [ContactUsFormTypes.CLEAR_CONTACT_US_FORMS]: clearContactUsForms,
  [ContactUsFormTypes.SET_CONTACT_US_FORMS_FILTER]: setContactUsFormsFilter,
})
