const en = {
  UserList: {
    empty: 'No users found',
  },
  AdminListItem: {
    cancelButton: 'Cancel',
    updateButton: 'Update',
    roleSelectorHeader: 'Roles',
  },

  FundTransactionListItem: {
    positiveCredit: '+EGP',
    negativeCredit: '-EGP',
    refunded: 'Refunded',
    notRefunded: 'Refund',
    AdminTransaction: 'Admin Transaction',
  },

  ChargingTokenList: {
    empty: 'No tokens found',
    AppToken: 'App Token',
    Active: 'Active',
  },
  UserListHeader: {
    sort: 'Recent',
    users: 'Users',
  },
  ChargingTransactionListItem: {
    duration: 'Charging duration: ',
    uid: 'Charger uid: ',
    confirmRefund: 'Are you sure you want to refund transaction',
    confirmRefundTitle: 'Refund transaction',
    refundButton: 'Refund',
  },
  IFFilter: {
    saveButton: 'Apply',
    clearButton: 'Clear',
    activeOption: 'Active',
    inactiveOption: 'Inactive',
    dateRangeButton: 'Date',
    filterBy: 'Filter by',
    statusSelect: 'Status',
    or: ' or ',
    selectRange: 'Select Date Range',
  },

  AdminList: {
    empty: 'No admins found',
    deleteModalCancel: 'Cancel',
    deleteModalConfirm: 'Delete',
    deleteModalTitle: 'Confirm Delete',
    deleteModalText: 'Are you sure you want to delete this Administrator?',
  },

  HeaderBar: {
    logout: 'Logout',
  },

  DialogModal: {
    defaultCancelButtonText: 'Cancel',
  },

  LoginPage: {
    ForgotPassword: 'Forgot Password?',
    Login: 'Login',
    Required: 'Required*',
    ShortPassword: 'Password must be at least 8 characters',
  },

  AdminPageContainer: {
    AddNewAdmin: 'Add New Admin',
    Name: 'Name',
    Email: 'Email',
    Role: 'Role',
    Password: 'Password',
    ConfirmPassword: 'Confirm Password',
    MismatchedPasswords: 'Passwords must match',
    ShortPassword: 'Password must be at least 8 characters',
    Add: 'Add',
    Admins: 'Admins',
    Roles: 'Roles',
    Required: 'Required*',
    Submit: 'Submit',
    ValidEmail: 'Must be a valid email',
  },

  UserPage: {
    ChargingTokensTitle: 'Charging Tokens',
    TransactionsTitle: 'Transactions',
    ActionsTitle: 'Actions',
    AdjustButton: 'Adjust',
    AddCardButton: 'Add card',
    EditButton: 'Edit',
    DeactivateButton: 'Deactivate',
    ActivateButton: 'Activate',
    AddButton: 'Add',
    SubtractButton: 'Subtract',
    Amount: 'Amount',
    Currency: 'EGP',
    DeactivateTitle: 'Deactivate',
    ActivateTitle: 'Activate',
    DeactivateBodyText: 'Are you sure you want to deactivate this user?',
    ActivateBodyText: 'Are you sure you want to activate this user?',
    DeactivateConfirm: 'Deactivate',
    ActivateConfirm: 'Activate',
    AddCardTextFieldLabel: 'Contract ID',
    AddCardButtonText: 'Add',
    AdjustAmountButtonTextAdd: 'Add',
    AdjustAmountButtonTextSubtract: 'Subtract',
    UsersFilterPlaceholder: 'Filter users by',
    TransactionsFilterPlaceholder: 'Filter transactions by',
    EditPhoneNumberModalTitle: 'Edit Phone Number',
    EditPhoneNumberModalAcceptText: 'Done',
    EditPhoneNumberTextFieldTitle: 'Phone',
    DeactivateTokenText: 'Are you sure you want to deactivate this token?',
    Deactivated: 'Deactivated',
    PointsInfoModalTitle: 'Points',
    NoSessions: 'There are no sessions',
  },

  PointsItem: {
    ExpiredText: 'Expired',
    ExpiresOnText: 'Expires on ',
    CreatedOnText: 'Created on ',
  },

  PointsList: {
    NoPointsText: 'No points',
    NoSessions: 'There are no sessions',
    PaymentSessions: 'Payment Sessions',
  },

  ContactUsFormListItem: {
    CloseButton: 'Mark as closed',
    ReOpen: 'Re-open',
  },

  ContactUsFormList: {
    EmptyList: 'No forms found',
  },

  ContactUsPage: {
    SubmittedForms: 'Submitted Forms',
    FilterText: 'Filter submitted forms by',
  },

  PushNotificationsPanel: {
    PanelTitle: 'Send Push Notification',
    EnglishTitle: 'Title (en)',
    EnglishBody: 'Body (en)',
    ArabicTitle: 'Title (ar)',
    ArabicBody: 'Body (ar)',
    Group: 'Group',
    StationId: 'Station ID / URl',
    DevicePreviewTitle: 'Device View',
    Required: 'Required*',
    sendButton: 'Send',
    SuccessMessage: 'Notification Sent Successfully',
  },

  TransactionsPage: {
    TransactionsFilterPlaceholder: 'Filter transactions by',
    TotalMoneyCollected: 'Total Money Collected:',
    TotalChargingBalance: 'Total Charging Balance:',
    TotalEnergyConsumption: 'Total Energy Consumption:',
    PhoneNumber: 'Phone Number',
    CustomerName: 'Name',
    TransactionId: 'Transaction ID',
    TransactionType: 'Transaction Type',
    Amount: 'Amount',
    EnergyConsumption: 'Energy Consumption',
    TransactionDate: 'Transaction Date',
    NoTransactions: 'No transactions found',
    NoTransactionsList: 'There are no transactions',
  },

  VouchersPage: {
    NoVouchers: 'No vouchers found',
    VouchersFilterPlaceholder: 'Filter vouchers by',
    VouchersTitle: 'Vouchers',
    VouchersName: 'Name',
    VouchersCode: 'Code',
    VouchersPoints: 'Points',
    VouchersRedeemed: 'Redeemed',
    VouchersValidity: 'Validity (days)',
    VouchersExpiry: 'Expiry',
    ExpiredText: 'Expired',
    ExpiresOnText: 'Expires on ',
    Create: 'Create',
    AddNewVoucher: 'Add Voucher',
    Required: 'Required*',
    Submit: 'Submit',
    VoucherNameForm: 'Voucher name',
    VoucherCodeForm: 'Voucher code',
    VoucherPointsForm: 'Number of points',
    VoucherMaxRedeemedForm: 'Number of redeems',
    ValidityForm: 'Validity (days)',
    ExpiryForm: 'Expiry date',
    Done: 'Done',
    Cancel: 'Cancel',
    NoExpiryDate: 'No Expiry Date',
  },

  ReverseTransactionModal: {
    EnergyConsumptionText: 'Energy Consumption (kWh)',
    ChargingLevelText: 'Charging Level',
    SubmitText: 'Submit',
    Title: 'Reverse Charging Transaction',
    ReverseButtonText: 'Reverse',
    ReversedButtonText: 'Reversed',
    SuccessMessage:
      'Transaction reversed successfully. It may take a while for the corrected transaction to appear.',
    ReverseTransactionType: 'Reverse Type',
    ConfirmDialogTitleReverse: 'Reverse Transaction',
    ConfirmDialogTitleCorrect: 'Correct Transaction',
    ConfirmDialogSubtitleReverse:
      'Are you sure you want to reverse this transaction?',
    ConfirmDialogSubtitleCorrect:
      'Are you sure you want to correct this transaction?',
    ConfirmDialogButton: 'Confirm',
  },

  PaymentSession: {
    Currency: 'EGP',
    Reason: 'Reason:',
  },
}

export default en
