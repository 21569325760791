import { IFText } from 'Components'
import Styles from './TransactionListItem.module.css'
import Colors from 'Theme/Colors'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

const BorderDiv = styled.div`
  border-right: 1px solid ${Colors.DividerColor};
`

const BothBordersDiv = styled.div`
  border-right: 1px solid ${Colors.DividerColor};
  border-left: 1px solid ${Colors.DividerColor};
`

const TransactionListItem = ({ transaction }) => {
  return (
    <div className={Styles.WrapperContainer}>
      <BothBordersDiv className={Styles.FieldContainer}>
        <IFText className={Styles.FieldText} style={{ color: Colors.text }}>
          {transaction.user[0]?.phone}
        </IFText>
      </BothBordersDiv>
      <BorderDiv className={Styles.FieldContainer}>
        <IFText className={Styles.FieldText} style={{ color: Colors.text }}>
          {transaction.user[0]?.name}
        </IFText>
      </BorderDiv>
      <BorderDiv className={Styles.FieldContainer}>
        <IFText className={Styles.FieldText} style={{ color: Colors.text }}>
          {transaction._id}
        </IFText>
      </BorderDiv>
      <BorderDiv className={Styles.FieldContainer}>
        <IFText className={Styles.FieldText} style={{ color: Colors.text }}>
          {transaction.type}
        </IFText>
      </BorderDiv>
      <BorderDiv className={Styles.FieldContainer}>
        <IFText className={Styles.FieldText} style={{ color: Colors.text }}>
          {parseFloat(transaction.amount?.toFixed(2)).toLocaleString()}
        </IFText>
      </BorderDiv>
      <BorderDiv className={Styles.FieldContainer}>
        <IFText className={Styles.FieldText} style={{ color: Colors.text }}>
          {transaction.details?.energyConsumption
            ? parseFloat(
                parseFloat(transaction.details.energyConsumption).toFixed(2),
              ).toLocaleString()
            : null}
        </IFText>
      </BorderDiv>
      <BorderDiv className={Styles.FieldContainer}>
        <IFText className={Styles.FieldText} style={{ color: Colors.text }}>
          {moment(transaction.createdAt).format('YYYY-MM-DD HH:mm:ss')}
        </IFText>
      </BorderDiv>
    </div>
  )
}

TransactionListItem.propTypes = {
  transaction: PropTypes.object,
}

export default TransactionListItem
