import { createReducer } from 'reduxsauce'
import { INITIAL_STATE } from './initialState'
import { AuthTypes } from './Actions'
import RequestState from 'Enums/RequestState'

export const loginLoading = (state) => {
  return {
    ...state,
    loginLoading: true,
  }
}

export const loginResetLoading = (state) => {
  return {
    ...state,
    loginLoading: false,
  }
}

export const loginSuccess = (state, { token, refreshToken, user }) => {
  return {
    ...state,
    refreshToken: refreshToken,
    token: token,
    user: user,
  }
}

export const loginError = (state) => {
  return {
    ...state,
    token: null,
    refreshToken: null,
    user: null,
    loginLoading: false,
  }
}

export const getUserSuccess = (state, { user }) => {
  return {
    ...state,
    user: user,
  }
}

export const getUserFail = (state) => {
  return state
}

export const setAuthToken = (state, { token }) => {
  return {
    ...state,
    token: token,
  }
}

export const fetchEditingAdminRoleLoading = (state) => {
  return {
    ...state,
    fetchEditingAdminRoleRequestState: RequestState.LOADING,
  }
}

export const fetchEditingAdminRoleSuccess = (state, { role }) => {
  return {
    ...state,
    fetchEditingAdminRoleRequestState: RequestState.SUCCEEDED,
    editingAdminRole: role,
  }
}

export const fetchEditingAdminRoleError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchEditingAdminRoleRequestState: requestState,
  }
}

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [AuthTypes.LOGIN_LOADING]: loginLoading,
  [AuthTypes.LOGIN_RESET_LOADING]: loginResetLoading,
  [AuthTypes.LOGIN_SUCCESS]: loginSuccess,
  [AuthTypes.LOGIN_ERROR]: loginError,

  [AuthTypes.SET_AUTH_TOKEN]: setAuthToken,

  [AuthTypes.GET_USER_SUCCESS]: getUserSuccess,
  [AuthTypes.GET_USER_FAIL]: getUserFail,
  [AuthTypes.FETCH_EDITING_ADMIN_ROLE_LOADING]: fetchEditingAdminRoleLoading,
  [AuthTypes.FETCH_EDITING_ADMIN_ROLE_SUCCESS]: fetchEditingAdminRoleSuccess,
  [AuthTypes.FETCH_EDITING_ADMIN_ROLE_ERROR]: fetchEditingAdminRoleError,
})
