import React from 'react'
import Styles from './ProgressBar.module.css'
import Colors from 'Theme/Colors'

const ProgressBar = (props) => {
  const { fillColor, completed, height, width, backgroundColor } = props


  const fillerStyles = {
    width: `${completed}%`,
    backgroundColor: fillColor,
  }

  const containerStyles = {
    height: height,
    width: width,
    backgroundColor: backgroundColor,
  }

  const labelColor = {
    color: Colors.white,
  }
  return (
    <div className={Styles.Container} style={containerStyles}>
      <div className={Styles.FillerStyle} style={fillerStyles}>
        <span className={Styles.LabelStyle} style={labelColor}/>
      </div>
    </div>
  )
}

export default ProgressBar
