import UserSelectors from 'Stores/User/Selectors'
import { connect } from 'react-redux'
import UserActions from 'Stores/User/Actions'
import styles from './PaymentSessionList.module.css'
import RequestState from 'Enums/RequestState'
import { Waypoint } from 'react-waypoint'
import { PaymentSessionListItem } from 'Components'
import { useTranslation } from 'react-i18next'
import { IFText, IFLoadingIndicator } from 'Components'
import { Icons } from 'Theme'

const PaymentSessionList = ({
  paymentSessions,
  fetchPaymentSessionListRequestState,
  paymentSessionListPaginationOffset,
  userId,
  fetchUserPaymentSessionList,
}) => {
  const { t } = useTranslation()

  const loadMoreData = () => {
    if (fetchPaymentSessionListRequestState === RequestState.LOADING) return

    fetchUserPaymentSessionList(userId, paymentSessionListPaginationOffset)
  }

  return (
    <div className={styles.PaymentSessionList}>
      {paymentSessions?.length === 0 ? (
        <div className={styles.EmptySet}>
          <img src={Icons.emptySet.default} alt="" />
          <IFText className={styles.NoSessions}>
            {t('UserPage.NoSessions')}
          </IFText>
        </div>
      ) : (
        paymentSessions.map((session, index) => {
          return (
            <PaymentSessionListItem
              key={`paymentSession ${index}`}
              paymentSession={session}
            />
          )
        })
      )}
      {paymentSessionListPaginationOffset &&
      paymentSessions &&
      paymentSessions.length !== 0 ? (
        <div className={styles.WaypointContainer}>
          <Waypoint onEnter={loadMoreData} />
          <IFLoadingIndicator
            style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
            size={'2em'}
            thickness={200}
          />
        </div>
      ) : null}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUserPaymentSessionList: (userId, offset) =>
      dispatch(UserActions.fetchUserPaymentSessionList(userId, offset)),
  }
}

const mapStateToProps = (state) => ({
  paymentSessions: UserSelectors.getPaymentSessionList(state),
  paymentSessionListPaginationOffset:
    UserSelectors.getPaymentSessionListPaginationOffset(state),
  fetchPaymentSessionListRequestState:
    UserSelectors.getFetchUserPaymentSessionListRequestState(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSessionList)
