import React from 'react'
import { Divider } from '@material-ui/core'
import { UserListItem, IFAvatar, IFText, IFLoadingIndicator } from 'Components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import UserSelectors from 'Stores/User/Selectors'
import UserActions from 'Stores/User/Actions'
import styles from './UserList.module.css'
import { Colors, Icons } from 'Theme'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import RequestState from 'Enums/RequestState'
import { Waypoint } from 'react-waypoint'
import history from 'history/browser'

const Container = styled.div`
  color: ${Colors.text};
  background-color: ${Colors.UserListBackground};
`
const UserList = ({
  users,
  selectedUserIndex,
  fetchUserListRequestState,
  paginationOffset,
  fetchUserList,
  usersFilter,
  setSelectedUserIndex,
}) => {
  const loadMoreData = () => {
    if (fetchUserListRequestState === RequestState.LOADING) return
    fetchUserList(usersFilter, paginationOffset)
  }
  const { t } = useTranslation()
  return (
    <Container className={styles.Container}>
      {users.length === 0 ? (
        <div className={styles.NoUsersFoundContainer}>
          <div className={styles.NoUsersFoundImage}>
            <img src={Icons.emptySet.default} alt="" />
          </div>

          <IFText className={styles.NoUsersFoundText}>
            {t('UserList.empty')}
          </IFText>
        </div>
      ) : (
        users.map((user, index) => {
          return (
            <div key={`userlistitem ${index}`}>
              <UserListItem
                onClick={() => {
                  setSelectedUserIndex(index)
                  history.push({ pathname: `/user/${user.id}` })
                }}
                user={user}
                avatar={
                  <IFAvatar imageURL={user.avatar} size={50} name={user.name} />
                }
                isSelected={index === selectedUserIndex}
              />
              <Divider />
            </div>
          )
        })
      )}
      {paginationOffset && users && users.length !== 0 ? (
        <div className={styles.WaypointContainer}>
          <Waypoint onEnter={loadMoreData} />
          <IFLoadingIndicator
            style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
            size={'2em'}
            thickness={200}
          />
        </div>
      ) : null}
    </Container>
  )
}

UserList.propTypes = {
  userList: PropTypes.array,
  selectedIndex: PropTypes.number,
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUserDetails: (userId) =>
      dispatch(UserActions.fetchUserDetails(userId)),
    fetchUserList: (usersFilter, paginationOffset) =>
      dispatch(UserActions.fetchUserList(usersFilter, paginationOffset)),
    setUsersFilter: (newFilter) =>
      dispatch(UserActions.setUsersFilter(newFilter)),
    setSelectedUserIndex: (index) =>
      dispatch(UserActions.setSelectedUserIndex(index)),
  }
}

const mapStateToProps = (state) => ({
  users: UserSelectors.getUserList(state),
  selectedUserIndex: UserSelectors.getSelectedUserIndex(state),
  fetchUserListRequestState: UserSelectors.getFetchUserListRequestState(state),
  paginationOffset: UserSelectors.getUserListOffset(state),
  usersFilter: UserSelectors.getUsersFilters(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserList)
