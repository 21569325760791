import { combineReducers } from 'redux'
import configureStore from './CreateStore'
import rootSaga from '../Sagas'
import { reducer as AdminReducer } from './Admin/Reducers'
import { reducer as AuthReducer } from './Auth/Reducers'
import { reducer as UserReducer } from './User/Reducers'
import { reducer as ContactUsFormReducer } from './ContactUsForm/Reducers'
import { reducer as NotificationReducer } from './Notification/Reducers'
import { reducer as TransactionReducer } from './Transaction/Reducers'
import { reducer as VoucherReducer } from './Voucher/Reducers'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import { createMigrate, persistReducer } from 'redux-persist'
// import createSensitiveStorage from 'redux-persist-sensitive-storage'
import { RootTypes } from './RootActions'

import {
  MAIN_STORE_VERSION,
  SECURE_STORE_VERSION,
  mainStoreMigrations,
  secureStoreMigrations,
} from './Migrate'

// const secureStorage = createSensitiveStorage({
//   keychainService: 'infinityAppKeychain',
//   sharedPreferencesName: 'infinityAppSharedPrefs',
// })

const mainPersistConfig = {
  key: 'main',
  storage: storage,
  whitelist: [], // Whitelist state that we need/want to persist
  version: MAIN_STORE_VERSION,
  migrate: createMigrate(mainStoreMigrations, { debug: false }),
  timeout: 0, // Fixes a debugging issue https://github.com/rt2zz/redux-persist/issues/717#issuecomment-437589374
}

//TODO Investigate a secure store for the web
const securePersistConfig = {
  key: 'secure',
  storage: storage,
  whitelist: ['authState'], // Whitelist state that we need/want to persist
  version: SECURE_STORE_VERSION,
  migrate: createMigrate(secureStoreMigrations, { debug: false }),
  timeout: 0, // Fixes a debugging issue https://github.com/rt2zz/redux-persist/issues/717#issuecomment-437589374
}

const reducer = () => {
  const mainReducer = combineReducers({
    /**
     * Register your reducers here.
     * @see https://redux.js.org/api-reference/combinereducers
     */
    adminState: AdminReducer,
    userState: UserReducer,
    contactUsFormState: ContactUsFormReducer,
    notificationState: NotificationReducer,
    transactionState: TransactionReducer,
    voucherState: VoucherReducer,
  })

  const secureReducer = combineReducers({
    authState: AuthReducer,
  })

  const persistedReducer = combineReducers({
    main: persistReducer(mainPersistConfig, mainReducer),
    secure: persistReducer(securePersistConfig, secureReducer),
  })

  const rootReducer = (state, action) => {
    if (action.type === RootTypes.RESET_ALL_STORES) {
      setTimeout(async () => {
        await storage.removeItem('persist:main')
        await storage.removeItem('persist:secure')
      })

      state = undefined
    }
    if (action.type === RootTypes.RESET_SECURE_STORE) {
      setTimeout(async () => {
        await storage.removeItem('persist:secure')
      })
      state = {
        ...state,
        secure: {
          ...state.secure,
          authState: undefined,
        },
      }
    }
    return persistedReducer(state, action)
  }

  return configureStore(rootReducer, rootSaga)
}

export default reducer
