import React from 'react'
import styles from './UserListHeader.module.css'
import Colors from 'Theme/Colors'
import { IFText } from 'Components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import UserSelectors from 'Stores/User/Selectors'
import UserActions from 'Stores/User/Actions'
import { useTranslation } from 'react-i18next'

const UserListHeader = ({
  toggleSortingOrder,
  isUserSortAsc,
  userListLength,
}) => {
  const { t } = useTranslation()
  return (
    <div
      style={{ backgroundColor: Colors.UserListTopBarBackground }}
      className={styles.Container}
    >
      <div>
        <IFText
          className={styles.NumberOfUsersText}
          style={{ color: Colors.UserListHeaderText }}
        >
          {userListLength} {t('UserListHeader.users')}
        </IFText>
      </div>
      <div
        onClick={() => {
          toggleSortingOrder(!isUserSortAsc)
        }}
      ></div>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    toggleSortingOrder: (isAscending) =>
      dispatch(UserActions.setUserSortingAsc(isAscending)),
  }
}

const mapStateToProps = (state) => ({
  userListLength: UserSelectors.getUserListLength(state),
  isUserSortAsc: UserSelectors.getUserSortingAsc(state),
})

UserListHeader.propTypes = {
  userListLength: PropTypes.number.isRequired,
  isUserSortAsc: PropTypes.bool,
  toggleSortingOrder: PropTypes.func,
}
export default connect(mapStateToProps, mapDispatchToProps)(UserListHeader)
