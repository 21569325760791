import { INITIAL_STATE } from './initialState'
import { AdminTypes } from './Actions'
import { createReducer } from 'reduxsauce'
import RequestState from 'Enums/RequestState'

export const setFilterText = (state, { text }) => {
  return {
    ...state,
    filterText: text,
  }
}

export const fetchAdminListLoading = (state) => {
  return {
    ...state,
    fetchAdminListRequestState: RequestState.LOADING,
  }
}

export const fetchAdminListSuccess = (state, { admins }) => {
  return {
    ...state,
    admins: admins,
    fetchAdminListRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchAdminListError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchAdminListRequestState: requestState,
  }
}

export const updateAdminRoleLoading = (state, { adminId }) => {
  state.admins.filter((admin) => admin.id === adminId)[0].isBeingUpdated = true
  state.fetchUpdateAdminRoleRequestState = RequestState.LOADING
  return {
    ...state,
    admins: [...state.admins],
  }
}

export const updateAdminRoleSuccess = (state, { admin }) => {
  state.admins.forEach((oldAdmin) => {
    if (oldAdmin.id === admin.id) {
      oldAdmin.role = admin.role
      oldAdmin.isBeingUpdated = false
    }
  })
  state.fetchUpdateAdminRoleRequestState = RequestState.SUCCEEDED
  return {
    ...state,
    admins: [...state.admins],
  }
}

export const updateAdminRoleFail = (state, { adminId }) => {
  state.admins.filter((admin) => admin.id === adminId)[0].isBeingUpdated = false
  state.fetchUpdateAdminRoleRequestState = RequestState.ERROR_UNKNOWN_OCCURRED
  return {
    ...state,
    admins: [...state.admins],
  }
}

export const deleteAdminLoading = (state, { adminId }) => {
  state.admins.filter((admin) => admin.id === adminId)[0].isBeingDeleted = true

  return {
    ...state,
    admins: [...state.admins],
  }
}

export const deleteAdminSuccess = (state, { adminId }) => {
  const updatedAdminList = state.admins.filter((admin) => admin.id !== adminId)

  return {
    ...state,
    admins: [...updatedAdminList],
  }
}

export const deleteAdminFail = (state, { adminId }) => {
  state.admins.filter((admin) => admin.id === adminId)[0].isBeingDeleted = false
  return {
    ...state,
    admins: [...state.admins],
  }
}

export const addAdminLoading = (state) => {
  return {
    ...state,
    isAddAdminLoading: true,
  }
}

export const addAdminSuccess = (state, { admin }) => {
  return {
    ...state,
    admins: [...state.admins, admin],
    isAddAdminLoading: false,
  }
}

export const addAdminFail = (state) => {
  return {
    ...state,
    isAddAdminLoading: false,
  }
}

export const fetchAdminRolesLoading = (state) => {
  return {
    ...state,
    fetchAdminRolesRequestState: RequestState.LOADING,
  }
}

export const fetchAdminRolesSuccess = (state, { roles }) => {
  return {
    ...state,
    fetchAdminRolesRequestState: RequestState.SUCCEEDED,
    roles: roles,
  }
}

export const fetchAdminRolesError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchAdminRolesRequestState: requestState,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [AdminTypes.SET_FILTER_TEXT]: setFilterText,

  [AdminTypes.FETCH_ADMIN_LIST_LOADING]: fetchAdminListLoading,
  [AdminTypes.FETCH_ADMIN_LIST_SUCCESS]: fetchAdminListSuccess,
  [AdminTypes.FETCH_ADMIN_LIST_ERROR]: fetchAdminListError,
  [AdminTypes.UPDATE_ADMIN_ROLE_LOADING]: updateAdminRoleLoading,
  [AdminTypes.UPDATE_ADMIN_ROLE_SUCCESS]: updateAdminRoleSuccess,
  [AdminTypes.UPDATE_ADMIN_ROLE_FAIL]: updateAdminRoleFail,
  [AdminTypes.DELETE_ADMIN_LOADING]: deleteAdminLoading,
  [AdminTypes.DELETE_ADMIN_SUCCESS]: deleteAdminSuccess,
  [AdminTypes.DELETE_ADMIN_FAIL]: deleteAdminFail,
  [AdminTypes.ADD_ADMIN_LOADING]: addAdminLoading,
  [AdminTypes.ADD_ADMIN_SUCCESS]: addAdminSuccess,
  [AdminTypes.ADD_ADMIN_FAIL]: addAdminFail,
  [AdminTypes.FETCH_ADMIN_ROLES_LOADING]: fetchAdminRolesLoading,
  [AdminTypes.FETCH_ADMIN_ROLES_SUCCESS]: fetchAdminRolesSuccess,
  [AdminTypes.FETCH_ADMIN_ROLES_ERROR]: fetchAdminRolesError,
})
