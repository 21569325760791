import { IFButton } from 'Components'
import TextField from '@material-ui/core/TextField'
import Styles from './AddCardForm.module.css'
import { Colors } from 'Theme'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
  },
}))
const AddCardForm = ({ onSubmit, className }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [serialNumber, setSerialNumber] = React.useState('')
  return (
    <div>
      <form
        onSubmit={() => {
          onSubmit(serialNumber)
        }}
      >
        <div className={Styles.InputContainer}>
          <TextField
            label={t('UserPage.AddCardTextFieldLabel')}
            variant="outlined"
            onChange={(event) => setSerialNumber(event.target.value)}
            className={classes.textField}
          />
        </div>
        <IFButton
          className={className}
          isFill={true}
          color={Colors.primary}
          text={t('UserPage.AddCardButtonText')}
        />
      </form>
    </div>
  )
}
export default AddCardForm
