import Styles from './ContactUsPage.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import IFText from 'Components/IFText/IFText'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ContactUsFormList from '../ContactUsFormList/ContactUsFormList'
import ContactUsFormActions from 'Stores/ContactUsForm/Actions'
import ContactUsFormSelectors from 'Stores/ContactUsForm/Selectors'
import { connect } from 'react-redux'
import RequestState from 'Enums/RequestState'
import { IFLoadingLogo, IFFilter } from 'Components'
import { Scrollbars } from 'react-custom-scrollbars'

const ContactUsContainer = styled.div`
  background-color: ${Colors.white};
`

const ContactUsPage = ({
  fetchContactUsFormList,
  fetchContactUsFormListRequestState,
  paginationOffset,
  contactUsFormsFilter,
  clearContactUsForms,
  setContactUsFormsFilter,
}) => {
  useEffect(() => {
    fetchContactUsFormList(contactUsFormsFilter, 0)
  }, [fetchContactUsFormList])

  useEffect(() => {
    fetchContactUsFormList(contactUsFormsFilter, 0)
  }, [contactUsFormsFilter])

  const shouldShowLoadingState = () => {
    return (
      fetchContactUsFormListRequestState === RequestState.LOADING &&
      paginationOffset === 0
    )
  }

  const { t } = useTranslation()
  return (
    <div className={Styles.Wrapper}>
      <ContactUsContainer className={Styles.ContactUsContainer}>
        <div className={Styles.ContactUsTextContainer}>
          <IFText
            style={{ color: Colors.black }}
            className={Styles.HeaderTitles}
          >
            {t('ContactUsPage.SubmittedForms')}
          </IFText>
        </div>
        <div className={Styles.Filter}>
          <IFFilter
            onFilterChange={(newFilter) => {
              setContactUsFormsFilter(newFilter)
              clearContactUsForms()
            }}
            filters={contactUsFormsFilter}
            textFieldPlaceholder={t('ContactUsPage.FilterText')}
          />
        </div>
        <Scrollbars autoHide style={{ width: '100%', height: '100%' }}>
          <div className={Styles.ContactUsFormsList}>
            {shouldShowLoadingState() ? (
              <div className={Styles.LoadingContainer}>
                <IFLoadingLogo
                  style={{
                    width: '10em',
                    display: 'flex',
                    marginBottom: '10em',
                  }}
                />
              </div>
            ) : (
              <ContactUsFormList />
            )}
          </div>
        </Scrollbars>
      </ContactUsContainer>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchContactUsFormList: (filter, offset) =>
      dispatch(ContactUsFormActions.fetchContactUsFormList(filter, offset)),
    clearContactUsForms: () =>
      dispatch(ContactUsFormActions.clearContactUsForms()),
    setContactUsFormsFilter: (newFilter) =>
      dispatch(ContactUsFormActions.setContactUsFormsFilter(newFilter)),
  }
}

const mapStateToProps = (state) => ({
  fetchContactUsFormListRequestState:
    ContactUsFormSelectors.getfetchContactUsFormListRequestState(state),
  paginationOffset: ContactUsFormSelectors.getpaginationOffset(state),
  contactUsFormsFilter: ContactUsFormSelectors.getContactUsFormsFilter(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage)
