import PropTypes from 'prop-types'
import styles from './IFAvatar.module.css'
import IFText from 'Components/IFText/IFText'
import styled from 'styled-components'
import { Colors } from 'Theme'

function strToRGB(str) {
  if (!str) return '000000'
  var int = 0
  for (var i = 0; i < str.length; i++) {
    int = str.charCodeAt(i) + ((int << 5) - int)
  }
  var c = (int & 0x00ffffff).toString(16).toUpperCase()
  return '00000'.substring(0, 6 - c.length) + c
}

var getInitials = function (string) {
  var names = string.split(' '),
    initials = names[0].substring(0, 1).toUpperCase()

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }
  return initials
}

const Container = styled.div`
  width: ${(props) => (props.size ? props.size + 'px' : '50px')};
  height: ${(props) => (props.size ? props.size + 'px' : '50px')};
  background-color: #${(props) => strToRGB(props.name)};
`

const IFAvatar = ({ name, imageURL, size, isRound = true }) => {
  return (
    <Container size={size} name={name} className={styles.Container}>
      <IFText
        style={{
          color: Colors.white,
          fontSize: size ? size * 0.025 + 'rem' : '1.5rem',
        }}
      >
        {getInitials(name)}
      </IFText>
    </Container>
  )
}

IFAvatar.propTypes = {
  name: PropTypes.string,
  imageURL: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isRound: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
}

export default IFAvatar
