import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUsers,
  faUserLock,
  faInfoCircle,
  faBell,
  faFileInvoiceDollar,
  faGift,
} from '@fortawesome/free-solid-svg-icons'
import Styles from './SideNavBar.module.css'
import logo from 'Assets/Images/WhiteLogo.png'
import { Colors } from 'Theme'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import InfinityEnums from 'Enums/InfinityEnums'
import { connect } from 'react-redux'
import AuthSelectors from 'Stores/Auth/Selectors'
import { Card } from '@material-ui/core'

const displayedPages = [
  {
    icon: faUsers,
    endpoint: '/user',
    role: InfinityEnums.AdminPermissions.CAN_VIEW_USERS,
  },
  {
    icon: faUserLock,
    endpoint: '/admin',
    role: InfinityEnums.AdminPermissions.CAN_VIEW_ADMINISTRATION,
  },
  {
    icon: faInfoCircle,
    endpoint: '/contactUs',
    role: InfinityEnums.AdminPermissions.CAN_VIEW_CONTACTUS,
  },
  {
    icon: faBell,
    endpoint: '/pushNotifications',
    role: InfinityEnums.AdminPermissions.CAN_VIEW_NOTIFICATIONS,
  },
  {
    icon: faFileInvoiceDollar,
    endpoint: '/transactions',
    role: InfinityEnums.AdminPermissions.CAN_VIEW_TRANSACTIONS,
  },
  {
    icon: faGift,
    endpoint: '/vouchers',
    role: InfinityEnums.AdminPermissions.CAN_VIEW_VOUCHERS,
  },
]

const SideNavContainer = styled.div`
  background-color: ${Colors.NavigationBarBackground};
`

const LogoContainer = styled.div`
  background-color: ${Colors.LogoBackground};
`
const IconContainer = styled.button`
  color: ${Colors.white};
  &:hover {
    background-color: ${(props) =>
      props.shouldHover ? ` ${Colors.IconHover} !important` : 'none'};
  }
`

const getSelectedIndex = (pathname, newPages) => {
  const slashArray = pathname.split('/')
  const result = newPages.findIndex(
    (page) => page.endpoint === '/' + slashArray[1],
  )
  return result
}

const SideNavBar = ({ editingAdminRole }) => {
  const [newPages, setNewPages] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(
    getSelectedIndex(window.location.pathname, newPages),
  )

  useEffect(() => {
    setSelectedIndex(getSelectedIndex(window.location.pathname, newPages))
  }, [window.location.pathname])

  useEffect(() => {
    if (!editingAdminRole || editingAdminRole.length === 0) return
    else {
      checkPermissions()
    }
  }, [editingAdminRole])

  const checkPermissions = () => {
    setNewPages([])
    let tempPages = []
    for (let page of displayedPages) {
      if (page.role && editingAdminRole.some((role) => role === page.role))
        tempPages.push(page)
    }
    setNewPages(tempPages)
    setSelectedIndex(getSelectedIndex(window.location.pathname, tempPages))
  }

  return (
    <SideNavContainer className={Styles.SideNavBarWrappingDiv}>
      <LogoContainer className={Styles.logoWrappingDiv}>
        <img className={Styles.IconImg} src={logo} alt="logo" />
      </LogoContainer>
      <div className={Styles.iconsWrappingDiv}>
        {newPages.map((page, index) => (
          <Link
            className={Styles.Link}
            key={`link ${index}`}
            to={page['endpoint']}
          >
            <IconContainer
              shouldHover={selectedIndex !== index}
              key={index}
              className={Styles.icon}
              style={{
                backgroundColor:
                  index === selectedIndex ? Colors.white : Colors.primary,
              }}
              onClick={() => setSelectedIndex(index)}
            >
              <FontAwesomeIcon
                icon={page['icon']}
                style={{
                  color:
                    selectedIndex === index ? Colors.primary : Colors.white,
                }}
              />
            </IconContainer>
          </Link>
        ))}
      </div>
    </SideNavContainer>
  )
}

const mapStateToProps = (state) => ({
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
})

export default connect(mapStateToProps)(SideNavBar)
