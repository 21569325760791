import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import PropTypes from 'prop-types'
import { IFButton } from 'Components'
import React, { useState, useImperativeHandle } from 'react'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import Styles from './IFDialog.module.css'

const IFDialog = React.forwardRef(
  (
    {
      style,
      open,
      title,
      bodyText,
      buttonAcceptonClick,
      buttonCancelColor = Colors.primary,
      buttonAcceptColor,
      buttonCancelText,
      buttonAcceptText,
      buttonClassName,
      children,
      fullWidth,
      maxWidth,
      dismissOnAccept = true,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    if (buttonCancelText == null)
      buttonCancelText = t('DialogModal.defaultCancelButtonText')

    const [isOpen, setIsOpen] = useState(open)

    const show = () => {
      setIsOpen(true)
    }

    const dismiss = () => {
      setIsOpen(false)
    }

    useImperativeHandle(ref, () => ({
      show,
      dismiss,
    }))

    return (
      <div>
        <Dialog
          style={style}
          open={isOpen}
          onClose={dismiss}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{bodyText}</DialogContentText>
            {children}
          </DialogContent>
          <DialogActions className={Styles.Buttons}>
            <IFButton
              onClick={dismiss}
              color={buttonCancelColor}
              text={buttonCancelText}
              className={buttonClassName}
              isFill={false}
            />

            <IFButton
              onClick={() => {
                buttonAcceptonClick()
                if (dismissOnAccept) dismiss()
              }}
              color={buttonAcceptColor}
              isFill={true}
              autoFocus
              text={buttonAcceptText}
              className={buttonClassName}
            />
          </DialogActions>
        </Dialog>
      </div>
    )
  },
)

IFDialog.propTypes = {
  style: PropTypes.object,
  open: PropTypes.bool,
  title: PropTypes.string,
  bodyText: PropTypes.string,
  buttonAcceptonClick: PropTypes.func,
  buttonCancelColor: PropTypes.string,
  buttonAcceptColor: PropTypes.string,
  buttonCancelText: PropTypes.string,
  buttonAcceptText: PropTypes.string,
  buttonClassName: PropTypes.string,
}

export default IFDialog
