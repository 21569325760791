import { UNAUTHORIZED, FORBIDDEN } from 'Constants/HttpStatus'

const RequestState = {
  UNINITIALIZED: 0,
  LOADING: 1,
  SUCCEEDED: 2,
  ERROR_0_NETWORK: 3,
  ERROR_400_OCCURRED: 4,
  ERROR_401_OCCURRED: 5,
  ERROR_403_OCCURRED: 6,
  ERROR_409_OCCURRED: 7,
  ERROR_UNKNOWN_OCCURRED: 8,
}

export default RequestState

export const evaluateErrorRequestState = (error) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === UNAUTHORIZED) {
    requestState = RequestState.ERROR_401_OCCURRED
  } else if (error && error.status === FORBIDDEN) {
    requestState = RequestState.ERROR_403_OCCURRED
  }
  return requestState
}
