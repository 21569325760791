import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  fetchVouchers: ['filter', 'offset'],
  fetchVouchersListLoading: null,
  fetchVouchersListSuccess: ['offset', 'vouchers', 'nextOffset', 'newVoucher'],
  fetchVouchersListError: ['error'],
  setVouchersFilter: ['newFilter'],
  clearVouchers: null,

  addVoucher: ['voucher'],
  addVoucherLoading: null,
  addVoucherSuccess: ['voucher'],
  addVoucherFail: null,

  updateVoucher: ['voucherId', 'newVoucher', 'index', 'onResponse'],
  updateVoucherLoading: null,
  updateVoucherSuccess: ['index', 'newVoucher'],
  updateVoucherFail: ['error'],
})

export const VoucherTypes = Types
export default Creators
