
const IFFIlterType = {
  KEYWORD: 0,
  SELECT: 1,
  CHECK: 2,
  DATE_RANGE: 3,
  DATE: 4,
}

export default IFFIlterType

