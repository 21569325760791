import { put, call } from 'redux-saga/effects'
import { transactionsApiService, handleError } from 'Services/ApiService.js'
import transactionActions from 'Stores/Transaction/Actions'
var base64 = require('base-64')

export function* fetchTransactionsList({ filter, offset }) {
  yield put(transactionActions.fetchTransactionsListLoading())
  try {
    if (filter !== null) {
      filter = base64.encode(JSON.stringify(filter))
    }

    const { data } = yield call(transactionsApiService.get, '/', {
      params: {
        filter: filter,
        offset: offset,
      },
    })

    yield put(
      transactionActions.fetchTransactionsListSuccess(
        offset,
        data.transactions,
        data.nextOffset,
        data.statistics,
      ),
    )
  } catch (e) {
    yield put(transactionActions.fetchTransactionsListError(e))
    handleError(e)
  }
}
