import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  setFilterText: ['text'],

  fetchAdminList: null,
  fetchAdminListLoading: null,
  fetchAdminListSuccess: ['admins'],
  fetchAdminListError: ['error'],

  fetchAdminRoles: null,
  fetchAdminRolesLoading: null,
  fetchAdminRolesSuccess: ['roles'],
  fetchAdminRolesError: ['error'],

  updateAdminRole: ['adminId', 'newRoleValue', 'adminItemRef'],
  updateAdminRoleLoading: ['adminId'],
  updateAdminRoleSuccess: ['admin'],
  updateAdminRoleFail: ['adminId'],

  deleteAdmin: ['adminId'],
  deleteAdminLoading: ['adminId'],
  deleteAdminSuccess: ['adminId'],
  deleteAdminFail: ['adminId'],

  addAdmin: ['admin'],
  addAdminLoading: ['value'],
  addAdminSuccess: ['admin'],
  addAdminFail: null,
})

export const AdminTypes = Types
export default Creators
