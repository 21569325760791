import PropTypes from 'prop-types'
import React, { useState, useImperativeHandle } from 'react'
import { Colors } from 'Theme'
import styles from './IFDatePicker.module.css'
import { IFText } from 'Components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DatePicker } from 'rsuite'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

const IFDatePicker = React.forwardRef(
  ({ title, onDateSelected = () => {} }, ref) => {
    const [value, setValue] = useState(new Date())
    const [isOpen, setIsOpen] = useState(false)
    const clearValue = () => {
      setValue(new Date())
    }

    useImperativeHandle(ref, () => ({
      clearValue,
      isOpen,
    }))

    return (
      <div className={styles.container}>
        <DatePicker
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          value={value}
          onOk={(value) => {
            setValue(value)
            onDateSelected(value)
          }}
          cleanable={false}
          size="lg"
          placeholder={title}
          renderValue={() => (
            <div>
              <IFText style={{ color: 'white' }}>{title}</IFText>
            </div>
          )}
        />

        <div
          className={styles.TextWrapper}
          style={{
            backgroundColor: Colors.filterBackground,
          }}
        >
          <IFText className={styles.Text}>
            {/* {Replacing all spaces with &nbsp; for styling} */}
            {title.replaceAll(' ', '\u00a0')}
          </IFText>
          <FontAwesomeIcon className={styles.Icon} icon={faAngleDown} />
        </div>
      </div>
    )
  },
)

IFDatePicker.propTypes = {
  title: PropTypes.string.isRequired,
  onDateSelected: PropTypes.func.isRequired,
  initialValue: PropTypes.instanceOf(Date),
}

export default IFDatePicker
