import { IFText, IFTextInput, IFsvg, IFLoadingIndicator } from 'Components'
import Styles from './VoucherItem.module.css'
import Colors from 'Theme/Colors'
import PropTypes from 'prop-types'
import React, { useState, useImperativeHandle, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Accordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import { makeStyles } from '@material-ui/core/styles'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { withStyles } from '@material-ui/core/styles'
import RequestState from 'Enums/RequestState'
import VoucherSelectors from 'Stores/Voucher/Selectors'
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles'

const AccordionSummary = withStyles({
  root: {
    '&$expanded': {
      minHeight: 56,
    },
    borderRadius: '5px',
    backgroundColor: Colors.white + '!important',
  },
  content: {
    '&$expanded': {
      margin: '0px',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const useStyles = makeStyles({
  root: {
    backgroundColor: Colors.white,
    boxShadow: '0px 0px 0px 0px',
    borderRadius: '5px !important',
  },
  AccordionSummary: {
    padding: '0px',
  },
  content: {
    margin: 0,
    height: '4rem',
  },
  AccordionDetails: {
    padding: '0px',
  },
})
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
}))

const VoucherItem = React.forwardRef(
  ({ voucher, onUpdateClick, isUpdateLoading, handleLoadingState }, ref) => {
    const { t } = useTranslation()
    let expiryDateMoment, expired, expiryText
    if (voucher.expiry) {
      expiryDateMoment = moment(voucher.expiry).format('YYYY-MM-DD')
      expired = moment().isSameOrAfter(moment(voucher.expiry)) ? true : false
      expiryText = expired
        ? t('VouchersPage.ExpiredText')
        : t('VouchersPage.ExpiresOnText') + expiryDateMoment
    } else {
      expiryText = t('VouchersPage.NoExpiryDate')
    }

    const [accordExpanded, setAccordExpanded] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [loading, setLoading] = useState(false)

    const [nameEdit, setNameEdit] = useState(voucher.name)
    const [maxRedeemCountEdit, setMaxRedeemCountEdit] = useState(
      voucher.maxRedeemCount,
    )
    const [expiryEdit, setExpiryEdit] = useState(expiryDateMoment)

    useEffect(() => {
      if (isUpdateLoading) {
        setLoading(true)
      } else {
        setLoading(false)
      }
    }, [isUpdateLoading])

    const openAccordion = () => {
      setIsEdit(true)
      setAccordExpanded(true)
    }

    const closeAccordion = () => {
      setAccordExpanded(false)
      setIsEdit(false)
    }

    useImperativeHandle(ref, () => ({
      closeAccordion,
    }))

    const handleCancel = () => {
      setIsEdit(false)
      setAccordExpanded(false)
      setNameEdit(voucher.name)
      setMaxRedeemCountEdit(voucher.maxRedeemCount)
      setExpiryEdit(expiryDateMoment)
    }

    const handleDone = async () => {
      let newVoucher = {}

      if (nameEdit !== voucher.name) {
        newVoucher.name = nameEdit
      }
      if (maxRedeemCountEdit !== voucher.maxRedeemCount) {
        newVoucher.maxRedeemCount = maxRedeemCountEdit
      }
      if (expiryEdit !== expiryDateMoment) {
        newVoucher.expiry = expiryEdit
      }

      if (Object.keys(newVoucher).length !== 0) {
        await onUpdateClick(newVoucher)
      }
      closeAccordion()
    }
    const classes = useStyles()

    return (
      <Accordion expanded={accordExpanded} className={classes.root}>
        <AccordionSummary
          classes={{ content: classes.content }}
          className={classes.AccordionSummary}
        >
          <div className={Styles.WrapperContainer}>
            <div className={Styles.EditableContainer}>
              {isEdit ? (
                <IFTextInput
                  className={Styles.FieldText}
                  style={{ marginLeft: '1rem' }}
                  value={nameEdit}
                  onChange={(e) => setNameEdit(e.target.value)}
                />
              ) : (
                <div className={Styles.FieldContainer}>
                  <IFText
                    className={Styles.FieldText}
                    style={{ color: Colors.text }}
                  >
                    {voucher.name}
                  </IFText>
                </div>
              )}
            </div>
            <div className={Styles.FieldContainer}>
              <IFText
                className={Styles.FieldText}
                style={{ color: Colors.text }}
              >
                {voucher.code}
              </IFText>
            </div>
            <div className={Styles.FieldContainer}>
              <IFText
                className={Styles.FieldText}
                style={{ color: Colors.text }}
              >
                {voucher.points}
              </IFText>
            </div>
            <div className={Styles.EditableContainer}>
              {isEdit ? (
                <div className={Styles.redeemContainer}>
                  <IFText
                    className={Styles.FieldText}
                    style={{ color: Colors.text }}
                  >
                    {voucher.redeemCount}/
                  </IFText>
                  <IFTextInput
                    className={Styles.FieldText}
                    style={{ marginLeft: '0.2rem' }}
                    value={maxRedeemCountEdit}
                    onChange={(e) => setMaxRedeemCountEdit(e.target.value)}
                  />
                </div>
              ) : (
                <div className={Styles.FieldContainer}>
                  <IFText
                    className={Styles.FieldText}
                    style={{ color: Colors.text }}
                  >
                    {voucher.redeemCount}/{voucher.maxRedeemCount}
                  </IFText>
                </div>
              )}
            </div>
            <div className={Styles.FieldContainer}>
              <IFText
                className={Styles.FieldText}
                style={{ color: Colors.text }}
              >
                {voucher.pointsExpireAfterDays}
              </IFText>
            </div>
            <div className={Styles.EditableContainer}>
              {isEdit ? (
                <IFTextInput
                  className={Styles.FieldText}
                  style={{ marginLeft: '1rem' }}
                  value={expiryEdit}
                  onChange={(e) => setExpiryEdit(e.target.value)}
                />
              ) : (
                <div className={Styles.FieldContainer}>
                  <IFText
                    className={Styles.FieldText}
                    style={{ color: Colors.text }}
                  >
                    {expiryText}
                  </IFText>
                </div>
              )}
            </div>
            <div className={Styles.PenContainer}>
              {!accordExpanded && !isUpdateLoading ? (
                <FontAwesomeIcon
                  onClick={() => {
                    openAccordion()
                  }}
                  className={Styles.PenIcon}
                  icon={faPen}
                  color={Colors.darkGrey}
                />
              ) : (
                <AccordionDetails className={Styles.ButtonContainer}>
                  <div className={Styles.AccordionDetailsContainer}>
                    {loading && (
                      <div>
                        <IFLoadingIndicator
                          size={'50%'}
                          color={Colors.primary}
                        />
                      </div>
                    )}
                    {!loading && (
                      <>
                        <IFsvg.DoneBtn
                          height={24}
                          width={24}
                          fill={Colors.primary}
                          onClick={handleDone}
                        />
                        <IFsvg.CloseBtn
                          height={24}
                          width={24}
                          fill={Colors.red}
                          onClick={handleCancel}
                        />
                      </>
                    )}
                  </div>
                </AccordionDetails>
              )}
            </div>
          </div>
        </AccordionSummary>
      </Accordion>
    )
  },
)

VoucherItem.propTypes = {
  voucher: PropTypes.object,
  onUpdateClick: PropTypes.func,
}

const mapStateToProps = (state) => ({
  updateVoucherRequestState:
    VoucherSelectors.getUpdateVoucherRequestState(state),
})

export default connect(mapStateToProps)(VoucherItem)
