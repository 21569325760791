const getTransactionsList = (state) => {
  return state.main.transactionState.transactions
}

const getFetchTransactionsListRequestState = (state) => {
  return state.main.transactionState.fetchTransactionsListRequestState
}

const getPaginationOffset = (state) => {
  return state.main.transactionState.paginationOffset
}

const getTransactionsFilter = (state) => {
  return state.main.transactionState.transactionsFilter
}

const getStatistics = (state) => {
  return state.main.transactionState.statistics
}

const TransactionSelectors = {
  getTransactionsList,
  getFetchTransactionsListRequestState,
  getPaginationOffset,
  getTransactionsFilter,
  getStatistics,
}

export default TransactionSelectors
