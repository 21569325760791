import { INITIAL_STATE } from './initialState'
import { NotificationTypes } from './Actions'
import { createReducer } from 'reduxsauce'
import RequestState from 'Enums/RequestState'

export const fetchNotificationGroupsLoading = (state) => {
  return {
    ...state,
    fetchNotificationGroupRequestState: RequestState.LOADING,
  }
}

export const fetchNotificationGroupsSuccess = (
  state,
  { notificationGroups },
) => {
  let result = []
  for (let j = 0; j < notificationGroups.length; j++) {
    if (
      !state.notificationGroups.some(
        (notifGroup) => notifGroup.id === notificationGroups[j].id,
      )
    ) {
      result.push(notificationGroups[j])
    }
  }
  return {
    ...state,
    notificationGroups: [...state.notificationGroups, ...result],
    fetchNotificationGroupRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchNotificationGroupsError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchNotificationGroupRequestState: requestState,
  }
}

export const sendNotificationLoading = (state) => {
  return {
    ...state,
    sendNotificationRequestState: RequestState.LOADING,
  }
}

export const sendNotificationSuccess = (state) => {
  return {
    ...state,
    sendNotificationRequestState: RequestState.SUCCEEDED,
  }
}

export const sendNotificationError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    sendNotificationRequestState: requestState,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [NotificationTypes.FETCH_NOTIFICATION_GROUPS_LOADING]:
    fetchNotificationGroupsLoading,
  [NotificationTypes.FETCH_NOTIFICATION_GROUPS_SUCCESS]:
    fetchNotificationGroupsSuccess,
  [NotificationTypes.FETCH_NOTIFICATION_GROUPS_ERROR]:
    fetchNotificationGroupsError,
  [NotificationTypes.SEND_NOTIFICATION_LOADING]: sendNotificationLoading,
  [NotificationTypes.SEND_NOTIFICATION_SUCCESS]: sendNotificationSuccess,
  [NotificationTypes.SEND_NOTIFICATION_ERROR]: sendNotificationError,
})
