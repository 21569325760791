import { Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import IFText from 'Components/IFText/IFText'
import Styles from './IFModal.module.css'
import React, { useState, useImperativeHandle } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from 'Theme'

const IFModal = React.forwardRef(
  (
    { title, children, open, maxWidth, fullWidth = false, canDismiss = true },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(open)

    const useStyles = makeStyles((theme) => ({
      root: {
        padding: '16px',
      },
      content: {
        padding: '4px',
      },
      title: {
        paddingLeft: '4px',
      },
    }))

    const classes = useStyles()

    const show = () => {
      setIsOpen(true)
    }

    const dismiss = () => {
      if (canDismiss) setIsOpen(false)
    }

    useImperativeHandle(ref, () => ({
      show,
      dismiss,
    }))

    return (
      <Dialog
        open={isOpen}
        onClose={dismiss}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        classes={{ paper: classes.root }}
      >
        <div className={Styles.TitleContainer}>
          <DialogTitle classes={{ root: classes.title }}>
            <IFText className={Styles.Title} style={{ color: Colors.text }}>
              {title}
            </IFText>
          </DialogTitle>
          <div>
            <ClearIcon onClick={dismiss} style={{ cursor: 'pointer' }} />
          </div>
        </div>
        <DialogContent dividers classes={{ dividers: classes.content }}>
          {children}
        </DialogContent>
      </Dialog>
    )
  },
)

export default IFModal
