import { React, useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import UserSelectors from 'Stores/User/Selectors'
import AuthSelectors from 'Stores/Auth/Selectors'
import UserActions from 'Stores/User/Actions'
import styles from './TransactionList.module.css'
import RequestState from 'Enums/RequestState'
import { Waypoint } from 'react-waypoint'
import {
  ChargingTransactionListItem,
  FundTransactionListItem,
  IFLoadingIndicator,
  IFModal,
  IFText,
} from 'Components'
import moment from 'moment'
import InfinityEnums from 'Enums/InfinityEnums'
import { ReverseTransactionModal } from 'Containers'
import { useTranslation } from 'react-i18next'
import { Icons } from 'Theme'

const TransactionList = ({
  userId,
  setCurrentUserTransactionRefund,
  transactions,
  transactionsFilters,
  fetchUserTransactionList,
  transactionListPaginationOffset,
  fetchUserTransactionListRequestState,
  editingAdminRole,
  reverseUserChargeTransaction,
  reverseChargeTransactionRequestState,
}) => {
  const reverseChargingTransactionRef = useRef()
  const [transactionData, setTransactionData] = useState({})
  const { t } = useTranslation()
  const loadMoreData = () => {
    if (fetchUserTransactionListRequestState === RequestState.LOADING) return

    fetchUserTransactionList(
      userId,
      transactionsFilters,
      transactionListPaginationOffset,
    )
  }

  useEffect(() => {
    if (reverseChargeTransactionRequestState === RequestState.SUCCEEDED)
      fetchUserTransactionList(userId, transactionsFilters, 0)
  }, [reverseChargeTransactionRequestState])

  const handleReverseChargeTransaction = (values) => {
    reverseUserChargeTransaction(values, transactionData, () => {
      reverseChargingTransactionRef.current?.dismiss()
    })
  }
  return (
    <div>
      {fetchUserTransactionListRequestState !== RequestState.SUCCEEDED &&
      !transactionListPaginationOffset ? (
        <div className={styles.LoadingIndicatorWrapper}>
          <IFLoadingIndicator size={'4em'} thickness={200} />
        </div>
      ) : transactions?.length !== 0 ? (
        transactions.map((transaction, index) =>
          transaction.type === 'charge' ? (
            <div
              className={styles.Transaction}
              key={`ChargingTransactionListItem ${index}`}
            >
              <ChargingTransactionListItem
                chargingSession={transaction}
                onReverseClick={(id) => {
                  setTransactionData({
                    id: id,
                    index: index,
                  })
                  reverseChargingTransactionRef.current.show()
                }}
                canViewReverseTransaction={
                  editingAdminRole?.includes(
                    InfinityEnums.AdminPermissions.CAN_REVERSE_TRANSACTION,
                  ) ||
                  transaction.status ===
                    InfinityEnums.TransactionStatus.REVERSED ||
                  transaction.status === InfinityEnums.TransactionStatus.VOID
                }
              />
            </div>
          ) : (
            <div
              className={styles.Transaction}
              key={`FundTransactionListItem ${index}`}
            >
              <FundTransactionListItem
                fundTransaction={transaction}
                onRefundClick={() => {
                  setCurrentUserTransactionRefund(transaction.id, index)
                }}
                canViewRefund={editingAdminRole?.includes(
                  InfinityEnums.AdminPermissions.CAN_REFUND,
                )}
                isRefundDisabled={
                  transaction.isRefunded ||
                  moment(new Date().setHours(0, 0, 0, 0)).diff(
                    transaction.createdAt,
                  ) /
                    86400000 >
                    14
                }
              />
            </div>
          ),
        )
      ) : (
        <div className={styles.EmptySet}>
          <img src={Icons.emptySet.default} alt="" />
          <IFText className={styles.NoSessions}>
            {t('TransactionsPage.NoTransactionsList')}
          </IFText>
        </div>
      )}
      {transactionListPaginationOffset &&
      transactions &&
      transactions.length !== 0 ? (
        <div className={styles.WaypointContainer}>
          <Waypoint onEnter={loadMoreData} />
          <IFLoadingIndicator
            style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
            size={'2em'}
            thickness={200}
          />
        </div>
      ) : null}
      <IFModal
        title={t('ReverseTransactionModal.Title')}
        open={false}
        ref={reverseChargingTransactionRef}
        canDismiss={
          !transactions[transactionData.index]
            ?.isReverseChargeTransactionLoading
        }
      >
        <ReverseTransactionModal
          onSubmit={(values) => handleReverseChargeTransaction(values)}
          isReverseTransactionLoading={
            transactions[transactionData.index]
              ?.isReverseChargeTransactionLoading
          }
          energyConsumption={
            transactions[transactionData.index]?.details.energyConsumption
          }
          chargingLevel={
            transactions[transactionData.index]?.details.chargerType
          }
        />
      </IFModal>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentUserTransactionRefund: (id, index) =>
      dispatch(UserActions.setCurrentUserTransactionRefund(id, index)),
    fetchUserTransactionList: (userId, filter, offset) =>
      dispatch(UserActions.fetchUserTransactionList(userId, filter, offset)),
    reverseUserChargeTransaction: (values, data, onResponse) =>
      dispatch(
        UserActions.reverseUserChargeTransaction(values, data, onResponse),
      ),
  }
}

const mapStateToProps = (state) => ({
  transactions: UserSelectors.getTransactionList(state),
  transactionListPaginationOffset:
    UserSelectors.getTransactionListPaginationOffset(state),
  fetchUserTransactionListRequestState:
    UserSelectors.getFetchUserTransactionListRequestState(state),
  transactionsFilters: UserSelectors.getTransactionsFilters(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  reverseChargeTransactionRequestState:
    UserSelectors.getReverseChargeTransactionRequestState(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(TransactionList)
