import React from 'react'
import styled from 'styled-components'
import styles from './UserListItem.module.css'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import ReactTimeAgo from 'react-time-ago'
import { IFText } from 'Components'
import PropTypes from 'prop-types'
import Colors from 'Theme/Colors'

TimeAgo.addDefaultLocale(en)

const Container = styled.div`
  background-color: ${(props) =>
    props.isSelected
      ? Colors.UserListItemSelectedBackground
      : Colors.UserListItemNotSelectedBackground};
  ${(props) =>
    props.user.isActive
      ? 'padding-left: 1.25em'
      : 'border-left: 4px solid ' + Colors.UserListItemInactive};
  &:hover {
    background-color: ${Colors.UserListItemHoverBackground};
  }
`

const UserListItem = ({ avatar, isSelected, user, onClick }) => {
  return (
    <Container
      user={user}
      isSelected={isSelected}
      onClick={onClick}
      className={styles.Container}
    >
      <div className={styles.Avatar}>{avatar}</div>
      <div className={styles.UserDetailsContainer}>
        <div>
          <IFText className={styles.UserNameText}>{user.name}</IFText>
          <IFText
            style={{ color: Colors.UserListItemPhoneAndLastSeen }}
            className={styles.Phonetext}
          >
            {user.phone}
          </IFText>
        </div>
      </div>
      <div className={styles.LastSeenItem}>
        <IFText
          style={{ color: Colors.UserListItemPhoneAndLastSeen }}
          className={styles.LastSeenText}
        >
          <ReactTimeAgo
            date={Date.parse(user.updatedAt)}
            locale="en-US"
            timeStyle="mini"
          />
        </IFText>
      </div>
    </Container>
  )
}

UserListItem.propTypes = {
  avatar: PropTypes.object,
  isSelected: PropTypes.bool,
  user: PropTypes.object,
  onClick: PropTypes.func,
}

export default UserListItem
