import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Colors from 'Theme/Colors'
import React, { useState, useImperativeHandle } from 'react'
import { UserPageTab } from 'Components'
import UserPageTabsSkeleton from './UserPageTabsSkeleton'

const tabTitles = {
  Transactions: 'Transactions',
  PaymentSessions: 'Payment Sessions',
}

const UserPageTabs = React.forwardRef(
  ({ onValueChange, isLoading, initialIndex }, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(initialIndex)

    const handleChange = (event, newValue) => {
      setSelectedIndex(newValue)
      onValueChange(newValue)
    }

    const resetIndex = () => {
      setSelectedIndex(0)
    }

    useImperativeHandle(ref, () => ({
      resetIndex,
    }))

    return (
      <div>
        {isLoading ? (
          <UserPageTabsSkeleton tabsCount={Object.keys(tabTitles).length} />
        ) : (
          <Tabs
            value={selectedIndex}
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: Colors.primary,
              },
            }}
          >
            {Object.values(tabTitles).map((title, index) => (
              <Tab
                sx={{
                  padding: '0px',
                  minWidth: 'fit-content',
                  maxWidth: 'fit-content',
                }}
                key={index}
                label={
                  <UserPageTab
                    title={title}
                    isSelected={selectedIndex === index}
                  />
                }
              />
            ))}
          </Tabs>
        )}
      </div>
    )
  },
)

UserPageTabs.propTypes = {
  onValueChange: PropTypes.func,
  isLoading: PropTypes.bool,
  initialIndex: PropTypes.number,
}

export default UserPageTabs
